export const checkIfAllValuesArePresent = (formData: any, isDraft: boolean) => {
  if (isDraft && (!formData.boq_item || formData.boq_item == "")) {
    return false;
  }
  if (
    !isDraft &&
    (!formData.boq_item ||
      formData.boq_item == "" ||
      formData.quantity == "" ||
      !formData.quantity ||
      formData.unit == "" ||
      !formData.unit ||
      formData.brand == "" ||
      !formData.brand ||
      formData.tax_value == "" ||
      !formData.tax_value ||
      formData.unit_selling_price == "" ||
      !formData.unit_selling_price)
  ) {
    return false;
  }
  return true;
};

export const getMissingFields = (fields: any) => {
  const missingFields = [];
  console.log("debug>>> fields,", fields);
  if (!fields.boq_item) {
    missingFields.push("boq_item");
  }
  if (!fields.quantity) {
    missingFields.push("quantity");
  }
  if (!fields.unit) {
    missingFields.push("unit");
  }
  if (!fields.brand) {
    missingFields.push("brand");
  }
  if (!fields.category_name || fields?.category_name == "") {
    missingFields.push("category");
  }
  if (!fields.tax_value) {
    missingFields.push("tax_value");
  }
  if (!fields.unit_selling_price) {
    missingFields.push("unit_selling_price");
  }

  return missingFields;
};

export const checkIfAllValuesArePresentPO = (
  formData: any,
  isDraft: boolean
) => {
  if (isDraft && (!formData.boq_item || formData.boq_item == "")) {
    return false;
  }
  if (
    !isDraft &&
    (!formData.estimate_item ||
      formData.estimate_item == "" ||
      formData.quantity == "" ||
      !formData.quantity ||
      formData.total_price == "" ||
      !formData.total_price ||
      formData.tax_value == "" ||
      !formData.tax_value ||
      formData.unit_po_price == "" ||
      !formData.unit_po_price)
  ) {
    return false;
  }
  return true;
};
export const getMissingFieldsPO = (fields: any) => {
  const missingFields = [];
  console.log("debug>>> fields,", fields);
  if (!fields.boq_item) {
    missingFields.push("estimate_item");
  }
  if (!fields.quantity) {
    missingFields.push("quantity");
  }
  if (!fields.total_price) {
    missingFields.push("total_price");
  }
  if (!fields.tax_value) {
    missingFields.push("tax_value");
  }
  if (!fields.unit_po_price) {
    missingFields.push("unit_po_price");
  }

  return missingFields;
};
