import { SearchOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";
import { Alert, Button, DatePicker, Input, Radio, Space } from "antd";
import Table, { ColumnType, ColumnsType } from "antd/es/table";
import type { FilterConfirmProps } from "antd/es/table/interface";
import { useCallback, useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { userPoReport } from "../../apis/dasboad.api";
import {
  IVendorDashboardData,
  getVendorDashboardData,
} from "../../apis/vendor.api";

const { RangePicker } = DatePicker;

export default function Reports() {
  const [data, setData] = useState<IVendorDashboardData[]>([]);
  const [po_data, setPOData] = useState<Array<any>>([]);
  const [isError, setIsError] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [filter, setFilter] = useState<any>({});

  const [loading, setLoading] = useState(true);
  const [report_type, setReportType] = useState("vendor");
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: keyof IVendorDashboardData
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: keyof IVendorDashboardData
  ): ColumnType<IVendorDashboardData> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      return (record[dataIndex] ?? "")
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const vendorColumns: ColumnsType<IVendorDashboardData> = [
    {
      key: "id",
      dataIndex: "vendor",
      title: "ID",
    },
    {
      key: "name",
      dataIndex: "name",
      title: "Name",
      ...getColumnSearchProps("name"),
    },
    {
      key: "code",
      dataIndex: "vendor_code",
      title: "Code",
    },
    {
      key: "total_amt",
      dataIndex: "total_amount",
      title: "Total Amount",
    },
  ];

  const poColumns: ColumnsType<IVendorDashboardData> = [
    {
      title: "S.no",
      dataIndex: "index",
      render: (value: any, data: any, index: number) => <>{index + 1}</>,
    },
    {
      key: "name",
      dataIndex: "f_name",
      title: "Name",
    },
    {
      title: "Invoice value",
      key: "invoice_value",
      render: (value: any, data: any, index: number) => (
        <>{data?.data?.invoice_value ?? 0}</>
      ),
    },
    {
      title: "PO Total Sum",
      key: "po_total_sum",
      render: (value: any, data: any, index: number) => (
        <>{data?.data?.po_total_sum ?? 0}</>
      ),
    },
    {
      title: "Total invoice",
      key: "total_invoice",
      render: (value: any, data: any, index: number) => (
        <>{data?.data?.total_invoice}</>
      ),
    },
    {
      title: "Total PO",
      key: "total_po",
      render: (value: any, data: any, index: number) => (
        <>{data?.data?.total_po}</>
      ),
    },
    {
      title: "% Completion",
      key: "completion",
      render: (value: any, data: any, index: number) => (
        <>
          {data?.data?.total_invoice
            ? (
                (data?.data?.total_invoice * 100) /
                data?.data?.total_po
              )?.toFixed(1)
            : "0"}
        </>
      ),
    },
  ];

  const fetchData = useCallback(async () => {
    setLoading(true);
    const data = await getVendorDashboardData(filter);
    setLoading(false);
    if (data) {
      setData(data);
    } else {
      setIsError(true);
    }
  }, [filter]);

  const fetchPOData = () => {
    userPoReport(filter?.date_gte ?? "", filter?.date_lte ?? "").then(
      (data) => {
        setPOData(
          data.sort((a: any, b: any) => b.data?.total_po - a.data?.total_po)
        );
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (report_type !== "vendor") {
      fetchPOData();
    } else {
      fetchData();
    }
  }, [report_type, filter]);

  if (isError) {
    return <Alert type="error" message="Could not fetch vendor data" />;
  }

  return (
    <main className="mx-3 my-3 px-3 py-2 white-color-bg">
      <div className="pb-3">
        <Radio.Group
          value={report_type}
          defaultValue={"vendor"}
          onChange={(e) => {
            setReportType(e.target.value);
          }}
          buttonStyle="solid"
        >
          <Radio.Button value="vendor">Vendor Report</Radio.Button>
          <Radio.Button value="po">PO Report</Radio.Button>
        </Radio.Group>
      </div>

      <RangePicker
        style={{ width: "40%" }}
        onChange={(event) => {
          if (event) {
            setFilter({
              date_lte: event[1]?.add(1, "day")?.format("YYYY-MM-DD"),
              date_gte: event[0]?.format("YYYY-MM-DD"),
            });
          } else {
            setFilter({
              date_gte: undefined,
              date_lte: undefined,
            });
          }
        }}
      />
      {report_type === "vendor" ? (
        <Table
          columns={vendorColumns}
          loading={loading}
          dataSource={data}
          pagination={{
            defaultPageSize: 10,
          }}
        />
      ) : (
        <>
          <Table
            columns={poColumns}
            loading={loading}
            dataSource={po_data}
            pagination={{
              defaultPageSize: 20,
            }}
          />
        </>
      )}
    </main>
  );
}
