import { PermissionName, permissions } from "./role_container";

export const usePermissions = () => {
  const userRole = localStorage.getItem("user_role");

  const hasPermission = (permission_name: PermissionName) => {
    return permissions[permission_name].includes(userRole);
  };

  return { hasPermission };
};
