import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, InputNumber, Space } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React from "react";

interface POPaymentTermsProps {
  setData: Function;
  data: any;
}
const POPaymentTerms = ({ setData, data }: POPaymentTermsProps) => {
  const [form] = Form.useForm();
  const onAdd = () => {
    var arr = [...(data?.po_payment_terms ?? [])];
    arr.push({
      term: "",
      percentage: 0,
      deadline: moment().format("YYYY-MM-DD"),
    });
    setData("po_payment_terms", arr);
  };
  const onRemove = (index: number) => {
    var arr = [...(data?.po_payment_terms ?? [])];
    var newArr = [];
    for (var i = 0; i < arr.length; i++) {
      if (i !== index) {
        newArr.push(arr[i]);
      }
    }
    setData("po_payment_terms", arr);
  };
  const onChangeInput = (value: any, index: number, name: string) => {
    var arr = [...(data?.po_payment_terms ?? [])];
    if (name == "term") {
      arr[index].term = value;
    }
    if (name == "percentage") {
      arr[index].percentage = value;
    }
    if (name == "deadline") {
      arr[index].deadline = moment(value).format("YYYY-MM-DD");
    }

    setData("po_payment_terms", arr);
  };

  const getFullPercentage = () => {
    let amount = 0;
    data?.po_payment_terms?.map((item: any) => {
      amount += item.percentage;
    });

    return amount;
  };
  return (
    <React.Fragment>
      <Form form={form}>
        {(data?.po_payment_terms ?? [])?.map((each: any, index: number) => (
          <Space align="baseline" key={index}>
            <Form.Item>
              <Input
                value={each?.term}
                placeholder="Term"
                onChange={(e) => {
                  onChangeInput(e.target.value, index, "term");
                }}
              />
            </Form.Item>
            <Form.Item>
              <InputNumber
                min={0}
                // max={100}
                value={each?.percentage}
                formatter={(value) => `${value}%`}
                parser={(value) => value?.replace("%", "")}
                onChange={(e) => {
                  onChangeInput(e, index, "percentage");
                }}
              />
            </Form.Item>
            <Form.Item>
              <DatePicker
                placeholder="Select Deadline"
                value={dayjs(each?.deadline?.toString())}
                onChange={(e: any, dateString) => {
                  onChangeInput(dateString, index, "deadline");
                }}
              />
            </Form.Item>
            <MinusCircleOutlined onClick={() => onRemove(index)} />
          </Space>
        ))}
        <Form.Item>
          <Button
            type="dashed"
            onClick={() => onAdd()}
            block
            icon={<PlusOutlined />}
          >
            Add Condition
          </Button>
        </Form.Item>
      </Form>
      <div className="my-2">Total : {getFullPercentage()}%</div>
      {getFullPercentage() !== 100 ? (
        <span style={{ color: "red" }}>The total should add upto 100%.</span>
      ) : null}
    </React.Fragment>
  );
};

export default POPaymentTerms;
