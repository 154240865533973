import {
  Button,
  Form,
  FormInstance,
  Input,
  Select,
  Space,
  message,
} from "antd";
import React, { useEffect } from "react";
import { addClientAlternateContact } from "../../../apis/client.api";
import { getAddressFromPincode } from "../../../apis/misc.api";
interface AddClientAlternateContactFormProps {
  user_id: any;
  onClientAlternateContactDrawerClose: Function;
}
const { Option } = Select;
const { TextArea } = Input;

const AddClientAlternateContactForm = ({
  onClientAlternateContactDrawerClose,
  user_id,
}: AddClientAlternateContactFormProps) => {
  const [form] = Form.useForm();

  const onFinish = (updatedValues: any) => {
    console.log("updatedValues", updatedValues);
    addClientAlternateContact(
      {
        ...updatedValues,
      },
      user_id
    ).then((res) => {
      onClientAlternateContactDrawerClose();
    });
  };
  const SubmitButton = ({ form }: { form: FormInstance }) => {
    const [submittable, setSubmittable] = React.useState(false);

    // Watch all values
    const values = Form.useWatch([], form);

    React.useEffect(() => {
      form.validateFields({ validateOnly: true }).then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
    }, [values]);

    useEffect(() => {
      form.resetFields();
    }, []);

    return (
      <Button type="primary" htmlType="submit" disabled={!submittable}>
        Submit
      </Button>
    );
  };

  const setAddressFromPincode = (pincode: any) => {
    if (pincode?.length == 6) {
      getAddressFromPincode(pincode).then((res) => {
        if (res) {
          form.setFieldValue("state", res?.state);
          form.setFieldValue("country", res?.country);
          form.setFieldValue("city", res?.city);
        } else {
          message.error("Pincode doesn't exist");
        }
      });
    }
  };
  return (
    <Form
      form={form}
      name="validateOnly"
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
    >
      <Form.Item
        name="contact_person_name"
        rules={[
          // {
          //   required: true,
          //   pattern: /^[a-zA-Z\s]*$/gi,
          // },
        ]}
        label="Contact person name"
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="contact_person_contact"
        rules={[
          {
            required: true,
            pattern: /^[1-9]\d{9}$/gi,
            message: "Enter a valid Phone",
          },
        ]}
        label="Contact person phone"
      >
        <Input />
      </Form.Item>
      <Form.Item name="contact_person_email" label="Contact person email" rules={[
        {
          type: "email",
          message: "Enter a valid email",
        }
      ]}>
        <Input />
      </Form.Item>

      <Form.Item>
        <Space>
          <SubmitButton form={form} />
          {/* <Button htmlType="reset">Reset</Button> */}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default AddClientAlternateContactForm;
