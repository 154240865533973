import axios from "axios";
import { IVendor } from "../interfaces/vendor.interface";
// export const fetchVendors = async (params = {}) => {
//   try {
//     return axios
//       .get(`https://api-dev2.materialdepot.in/apiV1/vendor/`, {
//         params: { ...params },
//       })
//       .then((res) => {
//         return res?.data;
//       });
//   } catch (error) {
//     return [];
//   }
// };

export const fetchVendors = async (params: any) => {
  try {
    const response = await fetch(
      `https://api-dev2.materialdepot.in/apiV1/vendor/?` +
        new URLSearchParams({
          ...params,
        }),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    const data = await response.json();
    console.log("data", data);
    const vendors = data;
    return vendors;
  } catch (error) {}
};

export const addVendor = async (formData: Partial<IVendor>) => {
  try {
    await axios.post(
      "https://api-dev2.materialdepot.in/apiV1/vendor/",
      formData
    );
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const editVendor = async (id: number, data: Partial<IVendor>) => {
  try {
    await axios.put("apiV1/vendor/" + id, data);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getVendor = async (id: number) => {
  try {
    const response = await axios.get<IVendor>("apiV1/vendor/" + id);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export interface IRawVendorDashboardData {
  vendor: number;
  total_amount: number;
  vendor_details: {
    id: number;
    vendor_code: string;
    name: string;
    contact_person: string | null;
  };
}

export interface IVendorDashboardData {
  vendor: number;
  total_amount: number;
  id: number;
  vendor_code: string;
  name: string;
  contact_person: string | null;
}

export const getVendorDashboardData = async ({
  date_gte,
  date_lte,
}: {
  date_gte: string;
  date_lte: string;
}) => {
  try {
    const response = await axios.get<IRawVendorDashboardData[]>(
      "apiV1/vendor-dashboard/",
      {
        params: {
          date_gte,
          date_lte,
        },
      }
    );

    // Flatten the data
    return [
      ...response.data?.map((item) => ({ ...item, ...item.vendor_details })),
    ] as IVendorDashboardData[];
  } catch (e) {
    console.error(e);
  }
};

export const putVendorWarehouse = async (data: any) => {
  try {
    const response = await axios.put("apiV1/vendor-warehouse/", data);

    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const postVendorWarehouse = async (data: any) => {
  try {
    const response = await axios.post(
      "https://api-dev2.materialdepot.in/apiV1/vendor-warehouse/",
      data
    );
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const putVendorOptionalContact = async (data: any) => {
  try {
    const response = await axios.put("apiV1/vendor-optional-contacts/", data);

    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const postVendorOptionalContact = async (data: any) => {
  try {
    const response = await axios.post(
      "https://api-dev2.materialdepot.in/apiV1/vendor-optional-contacts/",
      data
    );
    return response.data;
  } catch (e) {
    console.error(e);
  }
};
