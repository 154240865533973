import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import type { FormInstance } from "antd/es/form";
import dayjs from "dayjs";
import React, { useState } from "react";
import { mdCategories } from "../../../../apis/misc.api";
import styles from "./index.module.scss";

interface Item {
  key: string;
  name: string;
  age: number;
  address: string;
}
interface EditableRowProps {
  index: number;
}

interface PoItemTableProps {
  data: any;
  setData: Function;
}
const { Option } = Select;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: Item;
  index: number;
  children: React.ReactNode;
}
const taxTypes = [
  {
    id: 1,
    label: "None",
    value: "none",
  },
  {
    id: 2,
    label: "CGST/SGST",
    value: "cgst_sgst",
  },
  {
    id: 4,
    label: "IGST",
    value: "igst",
  },
];

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const PoItemTable = ({ data, setData }: PoItemTableProps) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [categories, setCategories] = useState([]);
  const [missingFields, setMissingFields] = useState<Array<any>>([]);

  const categoryOptions = React.useMemo(
    () =>
      categories.map((category: any) => ({
        value: category?.category_name,
      })),
    [categories]
  );
  const getMissingFields = (fields: {
    boq_item: any;
    quantity: any;
    unit: any;
    brand: any;
    category_name: string;
    tax_value: any;
    unit_po_price: any;
  }) => {
    const missingFields = [];
    if (!fields.boq_item) {
      missingFields.push("boq_item");
    }
    if (!fields.quantity) {
      missingFields.push("quantity");
    }
    if (!fields.unit) {
      missingFields.push("unit");
    }
    if (!fields.brand) {
      missingFields.push("brand");
    }
    if (!fields.category_name || fields?.category_name == "") {
      missingFields.push("category");
    }
    if (!fields.tax_value) {
      missingFields.push("tax_value");
    }
    if (!fields.unit_po_price) {
      missingFields.push("unit_po_price");
    }

    return missingFields;
  };

  const handleDelete = (id: string) => {
    const newData = data?.po_items.filter((item: any) => item.id !== id);
    setData("po_items", newData);
  };

  const defaultColumns = [
    {
      title: "S.no",
      dataIndex: "index",
      render: (value: any, data: any, index: number) => <>{index + 1}</>,
    },
    {
      title: "BOQ Item",
      dataIndex: "private_label_product_name",
      render: (
        value: any,
        data: { boq_item: any; private_label_product_name: any },
        index: any
      ) => (
        <div style={{ minWidth: 180 }}>
          {data?.private_label_product_name ?? data?.boq_item}
        </div>
      ),
    },
    {
      title: "Category",
      dataIndex: "category_name",
      render: (value: any, data: { category_name: any }, index: any) => (
        <>
          <div style={{ minWidth: 100 }}>{value}</div>
        </>
      ),
    },
    {
      title: "Brand",
      dataIndex: "private_label_brand_name",
      render: (
        value: any,
        data: {
          brand: string | number | readonly string[] | undefined;
          private_label_brand_name: any;
        },
        index: any
      ) => (
        <>
          <div style={{ minWidth: 80 }}>
            {data?.private_label_brand_name ?? data?.brand}
          </div>
        </>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      render: (value: any, data: { quantity: string | number }, index: any) => (
        <>
          <InputNumber
            className="item-input-table"
            style={{ minWidth: 100 }}
            onWheel={(event) => event.currentTarget.blur()}
            value={data?.quantity}
            onChange={(e) => {
              if (Number(e) || e == "") {
                handleSave({
                  ...data,
                  quantity: e == "" ? 0 : Number(e)?.toFixed(2),
                });
              } else {
                handleSave({
                  ...data,
                  quantity: 0,
                });
              }
            }}
            status={
              missingFields
                ?.find((item) => index === item?.index)
                ?.missingFields?.includes("quantity")
                ? "error"
                : ""
            }
          />
        </>
      ),
    },
    {
      title: "Unit",
      dataIndex: "unit",
      render: (
        value: any,
        data: { unit: string | number | readonly string[] | undefined },
        index: any
      ) => (
        <>
          <div style={{ minWidth: 60 }}>{value}</div>
        </>
      ),
    },
    {
      title: "Price(w/o gst)",
      dataIndex: "unit_po_price",
      render: (
        value: any,
        data: {
          unit_po_price: string | number;
          disabled: boolean | undefined;
        },
        index: any
      ) => (
        <>
          <InputNumber
            className="item-input-table"
            style={{ minWidth: 100 }}
            onWheel={(event) => event.currentTarget.blur()}
            value={data?.unit_po_price}
            onChange={(e) => {
              if (Number(e) || e == "") {
                handleSave({
                  ...data,
                  unit_po_price: e == "" ? 0 : Number(e)?.toFixed(2),
                });
              } else {
                handleSave({
                  ...data,
                  unit_po_price: 0,
                });
              }
            }}
            status={
              missingFields
                ?.find((item) => index === item?.index)
                ?.missingFields?.includes("unit_po_price")
                ? "error"
                : ""
            }
          />
          {missingFields
            ?.find((item) => index === item?.index)
            ?.missingFields?.includes("unit_po_price") && (
            <div style={{ color: "red" }}>Invalid value</div>
          )}
        </>
      ),
    },
    {
      title: "Tax",
      key: "tax",
      render: (value: any, data: any, index: any) => (
        <Space>
          <Select
            className="item-input-table"
            onChange={(e: any) => {
              // data.unit_po_price = parseFloat(
              //   data?.md_price_gst / (1 + e / 100)
              // ).toFixed(2);

              handleSave({
                ...data,
                tax_value: e,
                // unit_po_price: data.unit_po_price,
              });
              // onChange(e, data, "tax_value");
            }}
            value={data?.tax_value}
            status={
              missingFields
                ?.find((item) => index === item?.index)
                ?.missingFields?.includes("tax_value")
                ? "error"
                : ""
            }
          >
            {[0, 5, 12, 18, 28]?.map((each, index) => (
              <Option key={index} value={each}>
                {each}
              </Option>
            ))}
          </Select>
          <Select
            className="item-input-table"
            onChange={(e) => {
              // onChange(e, data, "tax_type");
              handleSave({ ...data, tax_type: e });
            }}
            value={data?.tax_type ?? "cgst_sgst"}
          >
            {taxTypes?.map((each) => (
              <Option key={each.id} value={each.value}>
                {each.label}
              </Option>
            ))}
          </Select>
          {/* <span>Budget : {data?.budget_price}</span> */}
        </Space>
      ),
    },
    {
      title: "Amount (w/ gst)",
      // dataIndex: "total",
      render: (data: {
        quantity: string;
        unit_po_price: string;
        margin: string;
        tax_value: string;
      }) => {
        var total =
          parseFloat(data?.quantity) *
          ((parseFloat(data?.unit_po_price) *
            (parseFloat(data?.tax_value) + 100)) /
            100);
        return (
          <span className="item-input-table" style={{ minWidth: 80 }}>
            {total?.toFixed(2)}
          </span>
        );
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (value: any, data: { remarks: string }, index: any) => (
        <div>{data?.remarks}</div>
      ),
    },
    {
      title: "Image",
      // dataIndex: "total",
      render: (
        value: any,
        data: { item_image: string | undefined },
        index: any
      ) => (
        <>
          {data?.item_image && data?.item_image != "" && (
            <img
              src={data?.item_image}
              alt="item"
              className={styles.pi_item_img}
            />
          )}
        </>
      ),
    },
    {
      title: "Action",
      // dataIndex: "action",
      render: (_: any, record: { id: any }, index: any) =>
        data?.po_items.length >= 1 ? (
          <div style={{ minWidth: 150 }}>
            <Tag
              color="red"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                handleDelete(record.id);
              }}
            >
              Delete
            </Tag>
          </div>
        ) : null,
    },
  ];

  React.useEffect(() => {
    mdCategories().then((res) => {
      setCategories(res);
    });
  }, []);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const handleSave = (row: any) => {
    const newData = [...data.po_items];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setData("po_items", newData);
  };

  const columns = defaultColumns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <Table
      components={components}
      bordered
      rowKey={(record) => record.id}
      scroll={{ x: "100%" }}
      dataSource={data?.po_items}
      columns={columns}
      pagination={false}
    />
  );
};

export default PoItemTable;
