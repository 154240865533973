import { Button, Drawer } from "antd";
import styles from "./index.module.scss";
import {
  addGstToBranch,
  deleteBranchGst,
  fetchBranchGst,
} from "../../../../apis/branch.api";
import { useState } from "react";
import BranchGstEditForm from "../branch_gst_edit_form";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

export interface BranchGSTCardProps {
  gst: any;
  letUpdate: Function;
}

const BranchGSTCard = ({ gst, letUpdate }: BranchGSTCardProps) => {
  const [branch_gst_edit_form, setBranchGstEditForm] = useState(false);
  const showBranchGstEditDrawer = () => {
    setBranchGstEditForm(true);
  };

  const onGstEditDrawerClose = () => {
    setBranchGstEditForm(false);
  };

  return (
    <div className="col-5">
      <div className="row border rounded-2  p-4">
        <div className="col-10">
          {gst?.label && (
            <div className="row">
              <div className="col-4 th-font-weight-500">Label </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{gst?.label}</div>
                </div>
              </div>
            </div>
          )}
          {gst?.city && (
            <div className="row">
              <div className="col-4 th-font-weight-500 ">City </div>
              <div className="col-7 overflow-hidden">
                <div className="d-flex ">
                  <div className={"pe-1 "}>:</div>
                  <div className={styles.line_clamp}>{gst?.city}</div>
                </div>
              </div>
            </div>
          )}
          {gst?.pincode && (
            <div className="row">
              <div className="col-4 th-font-weight-500 ">Pincode </div>
              <div className="col-7 overflow-hidden">
                <div className="d-flex ">
                  <div className={"pe-1 "}>:</div>
                  <div className={styles.line_clamp}>{gst?.pincode}</div>
                </div>
              </div>
            </div>
          )}
          {gst?.contact && (
            <div className="row">
              <div className="col-4 th-font-weight-500 ">Contact </div>
              <div className="col-7 overflow-hidden">
                <div className="d-flex ">
                  <div className={"pe-1 "}>:</div>
                  <div className={styles.line_clamp}>{gst?.contact}</div>
                </div>
              </div>
            </div>
          )}
          {gst?.email && (
            <div className="row">
              <div className="col-4 th-font-weight-500 ">Email </div>
              <div className="col-7 overflow-hidden">
                <div className="d-flex ">
                  <div className={"pe-1 "}>:</div>
                  <div className={styles.line_clamp}>{gst?.email}</div>
                </div>
              </div>
            </div>
          )}
          {gst?.address && (
            <div className="row">
              <div className="col-4 th-font-weight-500 ">Address </div>
              <div className="col-7 overflow-hidden">
                <div className="d-flex ">
                  <div className={"pe-1 "}>:</div>
                  <div className={styles.line_clamp}>{gst?.address}</div>
                </div>
              </div>
            </div>
          )}
          {gst?.gst && (
            <div className="row">
              <div className="col-4 th-font-weight-500 ">Gst </div>
              <div className="col-7 overflow-hidden">
                <div className="d-flex ">
                  <div className={"pe-1 "}>:</div>
                  <div className={styles.line_clamp}>{gst?.gst_no}</div>
                </div>
              </div>
            </div>
          )}
          {gst?.pan && (
            <div className="row">
              <div className="col-4 th-font-weight-500 ">Pan </div>
              <div className="col-7 overflow-hidden">
                <div className="d-flex ">
                  <div className={"pe-1 "}>:</div>
                  <div className={styles.line_clamp}>{gst?.pan_no}</div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-2 pl-2">
          <Button
            onClick={() => {
              showBranchGstEditDrawer();
            }}
          >
            <EditOutlined />
          </Button>
          <div className="mt-1">
            <Button
              onClick={() => {
                deleteBranchGst(gst?.id).then(() => {
                  letUpdate();
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        </div>
      </div>
      <Drawer
        title="Edit Gst"
        placement="right"
        closable={false}
        onClose={onGstEditDrawerClose}
        open={branch_gst_edit_form}
      >
        <BranchGstEditForm
          gst={gst}
          closeDrawer={onGstEditDrawerClose}
          letUpdate={letUpdate}
        />
      </Drawer>
    </div>
  );
};

export default BranchGSTCard;
