import { Button, Divider, Form, Input, Spin, Tabs, message } from "antd";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { getVendor, postVendorWarehouse, putVendorWarehouse } from "../../../apis/vendor.api";
import { IVendor } from "../../../interfaces/vendor.interface";

export function VendorWarehouseForm({
  vendor,
  onClose,
}: {
  vendor: IVendor;
  onClose: () => unknown;
}) {
  const [vendorData, setVendorData] = useState<IVendor | null>(null);
  const [activeTab, setActiveTab] = useState<"add" | "edit">("edit");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!vendor?.id) return;
    setLoading(true);
    getVendor(vendor?.id).then(
      (vendorDetails) => vendorDetails && setVendorData(vendorDetails)
    ).finally(() => setLoading(false));
  }, [vendor?.id]);

  const onEditSubmit = useCallback((warehouseId: number) => {
    return async (values: any) => {
      await putVendorWarehouse({
        id: warehouseId,
        ...values,
      });
      message.success("Updated vendor warehouse");
    };
  }, []);

  const onAddWarehouse = useCallback(async (values: any) => {
    await postVendorWarehouse({
      ...values,
      vendor_id: vendorData?.id,
    });
    message.success("Added vendor warehouse");
  }, [vendorData?.id]);

  const view = useMemo(() => {
    if (!vendorData) return null;

    if (loading) return (
      <Spin />
    );

    if (activeTab === "edit") {
      return (
        <Fragment>
          {vendorData.warehouses.map((warehouse) => (
            <Form key={warehouse.id} initialValues={warehouse} onFinish={onEditSubmit(warehouse.id)} layout="vertical">
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: "Address is required",
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item
                name="pincode"
                label="Pincode"
                rules={[
                  {
                    required: true,
                    message: "Pincode is required",
                  },
                  {
                    pattern: /^[1-9]\d{5}$/gi,
                    message: "Enter a valid pincode",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="city"
                label="City"
                rules={[
                  {
                    required: true,
                    message: "City is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="state"
                label="State"
                rules={[
                  {
                    required: true,
                    message: "State is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="country"
                label="Country"
                rules={[
                  {
                    required: true,
                    message: "Country is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="google_map"
                label="Google Map"
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                  {
                    type: "url",
                    message: "Must be a valid URL",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Button className="mt-4" type="primary" htmlType="submit">
                Submit
              </Button>
              <Divider />
            </Form>
          ))}
        </Fragment>
      );
    } else {
      return (
        <Form onFinish={onAddWarehouse} layout="vertical">
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: "Address is required",
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item
                name="pincode"
                label="Pincode"
                rules={[
                  {
                    required: true,
                    message: "Pincode is required",
                  },
                  {
                    pattern: /^[1-9]\d{5}$/gi,
                    message: "Enter a valid pincode",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="city"
                label="City"
                rules={[
                  {
                    required: true,
                    message: "City is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="state"
                label="State"
                rules={[
                  {
                    required: true,
                    message: "State is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="country"
                label="Country"
                rules={[
                  {
                    required: true,
                    message: "Country is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="google_map"
                label="Google Map"
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                  {
                    type: "url",
                    message: "Must be a valid URL",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Button className="mt-4" type="primary" htmlType="submit">
                Submit
              </Button>
              <Divider />
            </Form>
      )
    }
  }, [vendorData, loading, activeTab, onEditSubmit, onAddWarehouse]);

  return (
    <div>
      <Tabs
        items={[
          {
            key: "edit",
            label: "Edit",
          },
          {
            key: "add",
            label: "Add",
          },
        ]}
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key as "add" | "edit")}
      />
      {view}
    </div>
  );
}
