import { Button, Drawer } from "antd";
import TermsAndConditionsCard from "./terms_and_conditions_card";
import { useState, useEffect } from "react";
import { fetchBranchTermAandConditions } from "../../../apis/branch.api";
import BranchTermsCreateForm from "./branch_terms_create_form";
import { usePermissions } from "../../../custom_hooks/permission/permission_hook";
export interface TermsAndConditionsListProps {
  terms_and_conditions: Array<any>;
}

const TermsAndConditionsList = () => {
  const [terms, setTerms] = useState<Array<any>>([]);
  const [branch_terms_add_form, setBranchTermsAddForm] = useState(false);
  const [update, setUpdate] = useState(true);
  const { hasPermission } = usePermissions();

  const letUpdate = () => {
    setUpdate(!update);
  };

  const showBranchTermsAddDrawer = () => {
    setBranchTermsAddForm(true);
  };

  const onTermsAddDrawerClose = () => {
    setBranchTermsAddForm(false);
  };

  useEffect(() => {
    fetchBranchTermAandConditions().then((res) => {
      setTerms(res);
    });
  }, [update]);
  return (
    <div className=" p-3 py-2">
      <div className="d-flex justify-content-between align-items-center pb-3">
        <div className="th-font-size-18 th-font-weight-600 primary-color-text align-middle">
          <div>Terms and Conditions</div>
        </div>
        <div>
          <Button
            size="middle"
            className="fw-bold"
            type="primary"
            disabled={!hasPermission("branch_details.create")}
            onClick={() => {
              showBranchTermsAddDrawer();
            }}
          >
            Add Terms
          </Button>
        </div>
      </div>
      <div className="row gap-3">
        {terms?.map((term, index) => {
          return (
            <>
              <TermsAndConditionsCard
                key={index}
                term={term}
                letUpdate={letUpdate}
              />
            </>
          );
        })}
      </div>
      <Drawer
        title="Add Terms"
        placement="right"
        closable={false}
        onClose={onTermsAddDrawerClose}
        open={branch_terms_add_form}
      >
        <BranchTermsCreateForm
          closeDrawer={onTermsAddDrawerClose}
          letUpdate={letUpdate}
        />
      </Drawer>
    </div>
  );
};

export default TermsAndConditionsList;
