import {
  Button,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
  message,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import {
  getAddressFromPincode,
  getLatLongFromGooGlLink,
} from "../../../apis/misc.api";
import { addVendor } from "../../../apis/vendor.api";

export interface VendorAddFormProps {
  refresh: () => unknown;
  onClose: () => unknown;
}

export default function VendorAddForm({
  refresh,
  onClose,
}: VendorAddFormProps) {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [addressLoading, setAddressLoading] = useState(false);
  const [optionalWarehouse, setOptionalWarehouse] = useState<string[]>([]);
  const [optionalContacts, setOptionalContacts] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState<
    "basic" | "gst" | "banking" | "warehouse" | "contacts"
  >("basic");
  const [mapParseLatLongDebounce, setMapParseLatLongDebounce] = useState<any>();
  const [validGoogleMapLink, setValidGoogleMapLink] = useState(false);
  const [latLongLoading, setLatLongLoading] = useState(false);

  const SubmitButton = ({ form }: { form: FormInstance }) => {
    const [submittable, setSubmittable] = useState(false);
    const [validationOutput, setValidationOutput] = useState<any>(null);

    const values = Form.useWatch([], form);

    useEffect(() => {
      form.validateFields({ validateOnly: true }).then(
        (output) => {
          setSubmittable(true);
          setValidationOutput(output);
        },
        (output) => {
          setSubmittable(false);
          setValidationOutput(output);
        }
      );
    }, [values]);

    return (
      <Tooltip
        title={
          (validationOutput?.errorFields ?? []).length > 0 && (
            <div style={{ whiteSpace: "pre" }}>
              {JSON.stringify(validationOutput?.errorFields, null, 2)}
            </div>
          )
        }
      >
        <Button
          type="primary"
          htmlType="submit"
          disabled={!submittable || !validGoogleMapLink}
          loading={latLongLoading}
        >
          Submit
        </Button>
      </Tooltip>
    );
  };

  const onSubmit = useCallback(
    async (values: Record<string, string>) => {
      const keysToRemove = [];

      // Group Warehouses depending on the index
      const warehouseKeys = Object.keys(values).filter((key) =>
        key.startsWith("warehouse_name_")
      );
      const warehouses = warehouseKeys.map((key) => {
        const index = key.split("_")[2];
        keysToRemove.push(
          ...[
            `warehouse_name_${index}`,
            `warehouse_address_${index}`,
            `warehouse_pincode_${index}`,
            `warehouse_city_${index}`,
            `warehouse_state_${index}`,
            `warehouse_country_${index}`,
            `warehouse_google_map_${index}`,
          ]
        );
        return {
          name: values[`warehouse_name_${index}`],
          address: values[`warehouse_address_${index}`],
          pincode: values[`warehouse_pincode_${index}`],
          city: values[`warehouse_city_${index}`],
          state: values[`warehouse_state_${index}`],
          country: values[`warehouse_country_${index}`],
          google_map: values[`warehouse_google_map_${index}`],
        };
      });
      const warehouse = {
        name: values.warehouse_name,
        address: values.warehouse_address,
        pincode: values.warehouse_pincode,
        city: values.warehouse_city,
        state: values.warehouse_state,
        country: values.warehouse_country,
        google_map: values.warehouse_google_map,
      };
      warehouses.push(warehouse);

      keysToRemove.push(
        ...[
          "warehouse_name",
          "warehouse_address",
          "warehouse_pincode",
          "warehouse_city",
          "warehouse_state",
          "warehouse_country",
          "warehouse_google_map",
        ]
      );

      // Group Optional Contacts depending on the index
      const contactKeys = Object.keys(values).filter((key) =>
        key.startsWith("optional_contact_name_")
      );
      const contacts = contactKeys.map((key) => {
        const index = key.split("_")[3];
        keysToRemove.push(
          ...[
            `optional_contact_name_${index}`,
            `optional_contact_${index}`,
            `optional_contact_email_${index}`,
            `optional_contact_designation_${index}`,
          ]
        );
        return {
          contact_name: values[`optional_contact_name_${index}`],
          contact: values[`optional_contact_${index}`],
          email: values[`optional_contact_email_${index}`],
          designation: values[`optional_contact_designation_${index}`],
        };
      });

      // Remove all the keys that are not required
      keysToRemove.forEach((key) => {
        delete values[key];
      });

      const payload = {
        ...values,
        contacts,
        warehouses,
      };

      console.log("debug>>>", payload);

      const result = await addVendor(payload);
      if (result) {
        messageApi.success("Successfully added vendor");
        form.resetFields();
      } else {
        messageApi.error("Could not add vendor. Try again");
      }
      refresh();
      onClose();
      form.resetFields();
      setCurrentStep("basic");
    },
    [onClose, messageApi, refresh, form]
  );

  const setAddressFromPincode = (pincode: any, prefix = "", suffix = "") => {
    if (pincode?.length >= 6) {
      setAddressLoading(true);
      getAddressFromPincode(pincode)
        .then((res) => {
          if (res) {
            form.setFieldValue(prefix + "city" + suffix, res?.city);
            form.setFieldValue(prefix + "state" + suffix, res?.state);
            form.setFieldValue(prefix + "country" + suffix, res?.country);
          } else {
            message.error("Pincode doesn't exist");
          }
        })
        .finally(() => {
          setAddressLoading(false);
        });
    }
  };

  const setPanFromGST = (gst: any) => {
    if (gst?.length >= 15) {
      const pan = gst.slice(2, 12);
      form.setFieldValue("pan_number", pan);
    }
  };

  return (
    <div className="">
      {contextHolder}
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onSubmit}
      >
        <div style={{ display: currentStep === "basic" ? "block" : "none" }}>
          <h6>Business Details</h6>
          <Form.Item
            name="name"
            label="Business Name"
            rules={[
              {
                required: true,
                message: "Name is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            required
            name="vendor_type"
            label="Vendor Type"
            rules={[
              {
                required: true,
                message: "Vendor Type is required",
              },
            ]}
          >
            <Select>
              <Select.Option value="supplier">Supplier</Select.Option>
              <Select.Option value="contractor">Contractor</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="contact"
            label="Contact Number"
            rules={[
              {
                pattern: /^[0-9]{10}$/gi, // {10} is better than *
                message: "Only numbers are allowed",
              },
              {
                required: true,
                message: "Contact Number is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="contact_person"
            label="Contact Person"
            rules={[
              {
                required: true,
                message: "Contact Person is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email ID"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email",
              },
              {
                required: true,
                message: "Email is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="designation"
            label="Designation"
            rules={[
              {
                required: true,
                message: "Designation is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Whatsapp group link"
            name="whatsapp_group_link"
            rules={[
              {
                type: "url",
                message: "Please enter a valid URL",
              },
              {
                required: true,
                message: "Whatsapp group link is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div style={{ display: currentStep === "gst" ? "block" : "none" }}>
          <h6>GST Details</h6>
          <Form.Item
            name="gst"
            label="GST"
            rules={[
              {
                pattern:
                  /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/g,
                message: "Enter a valid GST Number",
              },
              {
                required: true,
                message: "GST Number is required",
              },
            ]}
          >
            <Input
              onChange={(e) => {
                setPanFromGST(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="pan_number"
            label="PAN Number"
            rules={[
              {
                pattern: /[A-Z]{5}\d{4}[A-Z]{1}/g,
                message: "Enter a valid PAN Number",
              },
              {
                required: true,
                message: "PAN Number is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="billing_address"
            label="Billing Address"
            rules={[
              {
                required: true,
                message: "Billing Address is required",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            required
            name="pincode"
            label="Pincode"
            rules={[
              {
                pattern: /^[1-9]\d{5}$/gi,
                message: "Enter a valid pincode",
              },
              {
                required: true,
                message: "Pincode is required",
              },
            ]}
          >
            <Input
              onChange={(e) => {
                setAddressFromPincode(e.target.value);
              }}
            />
          </Form.Item>
          {addressLoading && <p>Loading address...</p>}
          <Form.Item
            name="city"
            label="City"
            rules={[
              {
                required: true,
                message: "City is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="state"
            label="State"
            rules={[
              {
                required: true,
                message: "State is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="country"
            label="Country"
            rules={[
              {
                required: true,
                message: "Country is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="google_map"
            label="Google Map"
            rules={[
              {
                required: true,
                message: "Google Map is required",
              },
            ]}
          >
            <Input
              onChange={(e) => {
                clearTimeout(mapParseLatLongDebounce);
                setMapParseLatLongDebounce(
                  setTimeout(async () => {
                    setLatLongLoading(true);
                    const response = await getLatLongFromGooGlLink(
                      e.target.value
                    );
                    setLatLongLoading(false);
                    if (!response) {
                      message.error("Enter a valid google map link");
                      setValidGoogleMapLink(false);
                      return;
                    }

                    form.setFieldValue("lat", response?.lat);
                    form.setFieldValue("lng", response?.long);
                    setValidGoogleMapLink(true);
                  }, 500)
                );
              }}
            />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="lat"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Latitude"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="lng"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Longitude"
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div style={{ display: currentStep === "banking" ? "block" : "none" }}>
          <h6>Banking Details</h6>
          <Form.Item
            name="bank_account_number"
            label="Bank Account Number"
            rules={[
              {
                required: true,
                message: "Bank Account Number is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="bank_account_holder"
            label="Name on Account"
            rules={[
              {
                required: true,
                message: "Name on Account is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="bank_ifsc"
            label="IFSC Code"
            rules={[
              {
                required: true,
                message: "IFSC Code is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="bank_account_type"
            label="Account Type"
            rules={[
              {
                required: true,
                message: "Account Type is required",
              },
            ]}
          >
            <Select>
              <Select.Option value="savings">Savings</Select.Option>
              <Select.Option value="current">Current</Select.Option>
              <Select.Option value="others">Others</Select.Option>
            </Select>
          </Form.Item>
        </div>
        <div
          style={{ display: currentStep === "warehouse" ? "block" : "none" }}
        >
          <h6>Warehouse</h6>
          <Form.Item
            name="warehouse_name"
            label="Warehouse Name"
            rules={[
              {
                required: true,
                message: "Warehouse Name is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="warehouse_address"
            label="Warehouse Address"
            rules={[
              {
                required: true,
                message: "Warehouse Address is required",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="warehouse_pincode"
            label="Warehouse Pincode"
            rules={[
              {
                required: true,
                message: "Warehouse Pincode is required",
              },
            ]}
          >
            <Input
              onChange={(e) => {
                setAddressFromPincode(e.target.value, "warehouse_");
              }}
            />
          </Form.Item>
          <Form.Item
            name="warehouse_city"
            label="Warehouse City"
            rules={[
              {
                required: true,
                message: "Warehouse City is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="warehouse_state"
            label="Warehouse State"
            rules={[
              {
                required: true,
                message: "Warehouse State is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="warehouse_country"
            label="Warehouse Country"
            rules={[
              {
                required: true,
                message: "Warehouse Country is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="warehouse_google_map"
            label="Google Map"
            rules={[
              {
                required: true,
                message: "Google Map is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          {optionalWarehouse.map((_, index) => (
            <div key={index}>
              <h6>Warehouse {index + 2}</h6>
              <Form.Item
                name={`warehouse_name_${index}`}
                label="Warehouse Name"
                rules={[
                  {
                    required: true,
                    message: "Warehouse Name is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={`warehouse_address_${index}`}
                label="Warehouse Address"
                rules={[
                  {
                    required: true,
                    message: "Warehouse Address is required",
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item
                name={`warehouse_pincode_${index}`}
                label="Warehouse Pincode"
                rules={[
                  {
                    required: true,
                    message: "Warehouse Pincode is required",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    setAddressFromPincode(
                      e.target.value,
                      "warehouse_",
                      `_${index}`
                    );
                  }}
                />
              </Form.Item>
              <Form.Item
                name={`warehouse_city_${index}`}
                label="Warehouse City"
                rules={[
                  {
                    required: true,
                    message: "Warehouse City is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={`warehouse_state_${index}`}
                label="Warehouse State"
                rules={[
                  {
                    required: true,
                    message: "Warehouse State is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={`warehouse_country_${index}`}
                label="Warehouse Country"
                rules={[
                  {
                    required: true,
                    message: "Warehouse Country is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={`warehouse_google_map_${index}`}
                label="Google Map"
                rules={[
                  {
                    required: true,
                    message: "Google Map is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Button
                danger
                onClick={() =>
                  setOptionalWarehouse((prev) =>
                    prev.filter((_, i) => i !== index)
                  )
                }
                className="w-100"
              >
                Remove
              </Button>
            </div>
          ))}

          <Button
            className="w-100"
            onClick={() => {
              setOptionalWarehouse([...optionalWarehouse, ""]);
            }}
          >
            Add Warehouse
          </Button>
        </div>
        <div style={{ display: currentStep === "contacts" ? "block" : "none" }}>
          <h6>Optional Contacts</h6>
          {optionalContacts.map((_, index) => (
            <div key={index}>
              <Form.Item
                name={`optional_contact_name_${index}`}
                label={`Optional Contact Name ${index + 1}`}
                rules={[
                  {
                    required: true,
                    message: "Optional Contact Name is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={`optional_contact_${index}`}
                label={`Optional Contact Number ${index + 1}`}
                rules={[
                  {
                    required: true,
                    message: "Optional Contact Number is required",
                  },
                  {
                    pattern: /^[0-9]{10}$/gi, // {10} is better than *
                    message: "Only numbers are allowed",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={`optional_contact_email_${index}`}
                label={`Optional Email ${index + 1}`}
                required
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email",
                  },
                  {
                    required: true,
                    message: "Email is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={`optional_contact_designation_${index}`}
                label={`Designation ${index + 1}`}
              >
                <Input />
              </Form.Item>
              <Button
                danger
                onClick={() =>
                  setOptionalContacts((prev) =>
                    prev.filter((_, i) => i !== index)
                  )
                }
                className="w-100"
              >
                Remove
              </Button>
            </div>
          ))}
          <Button
            className="w-100"
            onClick={() => setOptionalContacts([...optionalContacts, ""])}
          >
            Add Contact
          </Button>
        </div>
        <Divider />
        {currentStep === "contacts" ? (
          <Space>
            <Button
              onClick={() => {
                setCurrentStep("warehouse");
              }}
            >
              Previous
            </Button>
            <SubmitButton form={form} />
          </Space>
        ) : (
          <Space>
            <Button
              onClick={() => {
                if (currentStep === "basic") {
                  // onClose();
                } else if (currentStep === "gst") {
                  setCurrentStep("basic");
                } else if (currentStep === "banking") {
                  setCurrentStep("gst");
                } else if (currentStep === "warehouse") {
                  setCurrentStep("banking");
                } else if (currentStep === "contacts") {
                  setCurrentStep("warehouse");
                }
              }}
              disabled={currentStep === "basic"}
            >
              Previous
            </Button>
            <Button
              onClick={() => {
                if (currentStep === "basic") {
                  setCurrentStep("gst");
                } else if (currentStep === "gst") {
                  setCurrentStep("banking");
                } else if (currentStep === "banking") {
                  setCurrentStep("warehouse");
                } else if (currentStep === "warehouse") {
                  setCurrentStep("contacts");
                }
              }}
            >
              Next
            </Button>
          </Space>
        )}
      </Form>
    </div>
  );
}
