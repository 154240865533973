
export interface PFIBillingDetailsProps {
  name: string;
  address: string;
  gst_no: string;
}

const PFIBillingDetails = ({
  name,
  address,
  gst_no,
}: PFIBillingDetailsProps) => {
  return (
    <div className=" p-3 py-2 row mb-2">
      <div className="th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
        Billing Details
      </div>
      <div className="col">
        <div className="row">
          <div className="col-4 th-font-weight-500">Name </div>
          <div className="col-7">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{name}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4 th-font-weight-500">Address </div>
          <div className="col-7">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{address}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4 th-font-weight-500">GST </div>
          <div className="col-7">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{gst_no}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PFIBillingDetails;
