import { Button, Form, Input } from "antd";
import {
  editProFormaInvoiceItem,
  editVariantHSNCode,
} from "../../../apis/pro_forma_invoice.api";
import { pushToOpenSearch } from "../../../apis/product.api";

export interface HSNCodeFillProps {
  items: Array<any>;
  onFinish: () => void;
}

export function HSNCodeFill({ items, onFinish }: HSNCodeFillProps) {
  const onSubmit = async (values: any) => {
    await Promise.all(
      Object.keys(values).map(async (key) => {
        await editProFormaInvoiceItem(key, { hsn_code: values[key] });
        const item = items.find((item) => String(item.id) === String(key));

        console.log("debug>>> item", item, items, key);

        if (item && item?.variant_data) {
          await editVariantHSNCode(item?.variant_data?.id, values[key]);
          await pushToOpenSearch(item?.variant_data?.variant_handle);
        }
      })
    );

    onFinish();
  };

  return (
    <div>
      <Form onFinish={onSubmit} layout="vertical">
        {items.map((item, index) => (
          <Form.Item
            key={index}
            label={item?.boq_item}
            name={`${item?.id}`}
            rules={[
              {
                required: true,
                message: "Please enter HSN code",
              },
              {
                pattern: /^\d+/,
                message: "Please enter a valid HSN code",
              },
            ]}
          >
            <Input />
          </Form.Item>
        ))}
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}
