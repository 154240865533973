import { useEffect, useState } from "react";
import { formatDecimal } from "../../../helpers/number_helper";

export interface TaxAmount1Props {
  items: Array<any>;
}

const TaxAmount1 = ({ items }: TaxAmount1Props) => {
  const [igstTaxes, setIgstTaxes]: any = useState({});
  const [cgstSgstTaxes, setCgstSgstTaxes]: any = useState({});

  const getTotalTaxableAmount = () => {
    let amount = 0;
    items?.forEach((item) => {
      amount +=
        (Number(item?.unit_selling_price) +
          (Number(item?.unit_selling_price) * Number(item?.margin)) / 100) *
        item?.quantity;
    });
    return amount;
  };
  useEffect(() => {
    let igst: any = {};
    let cgst_sgst: any = {};
    items?.forEach((item: any) => {
      if (item?.tax_type === "igst") {
        if (!igst[item?.tax_value]) {
          igst[item?.tax_value] = 0;
        }
        igst[item?.tax_value] +=
          (item.quantity *
            (item.unit_cost_price > 0
              ? item.unit_cost_price
              : item?.unit_selling_price +
                (item?.unit_selling_price * item?.margin) / 100) *
            item?.tax_value) /
          100;
      }
      console.log("i-log> item from table", item);
      if (item?.tax_type === "cgst_sgst") {
        if (!cgst_sgst[item?.tax_value]) {
          cgst_sgst[item?.tax_value] = 0;
        }
        cgst_sgst[item?.tax_value] +=
          (item.quantity *
            (item.unit_cost_price > 0
              ? item.unit_cost_price
              : item?.unit_selling_price +
                (item?.unit_selling_price * item?.margin) / 100) *
            item?.tax_value) /
          100;
      }
    });
    setIgstTaxes(igst);
    setCgstSgstTaxes(cgst_sgst);
  }, [items]);

  const getDiv = () => {
    const divs: Array<any> = [];
    Object.keys(igstTaxes).forEach((key, index) => {
      divs.push(
        <div
          className="d-flex justify-content-between"
          key={"igstTaxes" + index}
        >
          <div>IGST {key}% </div>
          <div>:</div>
          <div>₹ {formatDecimal(igstTaxes[key])}</div>
        </div>
      );
    });

    Object.keys(cgstSgstTaxes).forEach((key: any, index: number) => {
      divs.push(
        <div key={index + 1 + "cgstSgstTaxes"}>
          <div
            className="d-flex justify-content-between px-1 th-font-weight-600"
            key={"CGST" + index}
          >
            <div>CGST {key / 2}% </div>

            <div>₹ {(Number(cgstSgstTaxes[key]) / 2)?.toFixed(2)}</div>
          </div>
          <div
            className="d-flex justify-content-between px-1 th-font-weight-600"
            key={"SGST" + index}
          >
            <div>SGST {key / 2}% </div>

            <div>₹ {(cgstSgstTaxes[key] / 2)?.toFixed(2)}</div>
          </div>
        </div>
      );
    });

    return divs;
  };
  return (
    <>
      <div className="d-flex justify-content-between th-font-weight-700 px-1 mb-3">
        <div className="">Total Amount before Tax</div>

        <div className="">₹ {Number(getTotalTaxableAmount()).toFixed(2)}</div>
      </div>
      {getDiv()}
    </>
  );
};

export default TaxAmount1;
