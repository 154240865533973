import { Header } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import {
  fetchBranchGst,
  fetchBranchTermAandConditions,
  fetchBranches,
} from "../../apis/branch.api";
import BranchDetails from "../../components/branch/branch_details";
import BranchGST from "../../components/branch/branch_gst";
import BranchMemberList from "../../components/branch/branch_members_list";
import TermsAndConditionsList from "../../components/branch/terms_and_conditions";

const Branch = () => {
  const [branch_data, setBranchData] = useState<any>();
  const [gsts, setGsts] = useState<Array<any>>([]);

  const [terms_and_conditions, setTermsAndConditions] = useState<Array<any>>(
    []
  );

  useEffect(() => {
    fetchBranches().then((res) => {
      setBranchData(res);
    });

    fetchBranchTermAandConditions().then((res) => {
      setTermsAndConditions(res);
    });

    fetchBranchGst().then((res) => {
      setGsts(res);
    });
  }, []);
  return (
    <section className={`lighter-grey-color-bg`}>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
        }}
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-4 align-items-center">
            <div className="primary-color-text th-font-size-20 th-font-weight-700">
              Branch Details
            </div>
            <div className=" th-font-weight-600">
              Branch Name :
              <span className=" th-font-weight-400">
                {" "}
                {branch_data?.branch_name}
              </span>
            </div>
          </div>
        </div>
      </Header>
      <div className="m-4 p-4 px-5 white-color-bg">
        <div className="row gap-2 my-4">
          <div className="col">
            <BranchDetails branch={branch_data} />
          </div>
          <div className="col"></div>
        </div>
      </div>
      <div className="m-4 p-4 px-5 white-color-bg">
        <div className="row gap-2 my-4">
          <div className="col">
            <TermsAndConditionsList />
          </div>
        </div>
      </div>
      <div className="m-4 p-4 px-5 white-color-bg">
        <div className="row gap-2 my-4">
          <div className="col">
            <BranchGST />
          </div>
        </div>
      </div>
      <div className="m-4 p-4 px-5 white-color-bg">
        <div className="row gap-2 my-4">
          <div className="col">
            <BranchMemberList />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Branch;
