import { Button, Col, Drawer, Row } from "antd";
import React from "react";
import styles from "./index.module.scss";
import { TileView } from "./tile-view";

const ProductDrawer = (props: any) => {
  // const [productDetails, setProductDetails] = React.useState(null);
  const [properties, setProperties] = React.useState<any>({});
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [userType, setUserType] = React.useState(null);
  const [mdView, setMdView] = React.useState(false);

  React.useEffect(() => {
    const neededProperties = [
      "Calibration Value",
      "Emmission Grade",
      "Core Gap",
      "Glue Type",
      "Core Resin",
      "Core Type",
      "Grade",
      "Face Type",
      "Face Thickness",
      "Features",
      "Calibration",
    ];

    if (!props.product) return;

    const properties = props.product?.variant_property?.filter(
      (property: { property_name: string }) =>
        neededProperties.includes(property.property_name)
    );

    // Make a K-V pair of properties by property_name and value
    const propertiesObject = properties.reduce(
      (
        acc: { [x: string]: any },
        property: {
          value: {
            toString: () => {
              (): any;
              new (): any;
              trim: {
                (): { (): any; new (): any; length: number };
                new (): any;
              };
            };
          };
          property_name: string | number;
        }
      ) => {
        if (property.value.toString().trim().length === 0) return acc;
        acc[property.property_name] = property.value;
        return acc;
      },
      {}
    );

    setProperties(propertiesObject);

    // Select the first image
    setSelectedImage(props.product?.variant_image[0]?.image_url);
  }, [props.product]);

  React.useEffect(() => {
    if (props.settings?.organisation?.organisation_type) {
      setUserType(props.settings?.organisation?.organisation_type);
    }
  }, [props.settings]);

  React.useEffect(() => {
    setMdView(props.settings?.organisation?.lead_field);
  }, [props.settings?.organisation?.lead_field]);

  const priceLabel = React.useMemo(() => {
    if (userType === "studio") return "Rate (incl. GST)";
    if (userType === "enterprise") return "Rate (incl. GST)";
    return "Rate (incl. GST)";
  }, [userType]);

  const price = React.useMemo(() => {
    if (!mdView) return props.product?.md_price_gst;
    if (userType === "studio") return props.product?.md_price_gst_studio;
    if (userType === "enterprise") return props.product?.md_price_gst_msp;
  }, [
    mdView,
    props.product?.md_price_gst,
    props.product?.md_price_gst_msp,
    props.product?.md_price_gst_studio,
    userType,
  ]);

  const landingPrice = React.useMemo(() => {
    if (!mdView) return props.product?.md_landing_price_gst;
    if (userType === "studio")
      return props.product?.md_landing_price_gst_studio;
    if (userType === "enterprise")
      return props.product?.md_landing_price_gst_msp;
  }, [
    mdView,
    props.product?.md_landing_price_gst,
    props.product?.md_landing_price_gst_msp,
    props.product?.md_landing_price_gst_studio,
    userType,
  ]);

  const priceSection = React.useMemo(() => {
    if (!mdView) {
      return (
        <div className={styles.details}>
          <Row className={styles.item}>
            <Col sm={8} className={styles.label}>
              {priceLabel}:
            </Col>
            <Col sm={16}>
              <span className={styles.value}>
                ₹{price}
                {props.product?.price_unit && "/"}
                {props.product?.price_unit ?? ""}{" "}
                {landingPrice &&
                  `(₹${landingPrice}/${props.product?.md_landing_price_unit})`}
              </span>
            </Col>
          </Row>
        </div>
      );
    }

    return (
      <>
        <div className={styles.details}>
          <Row className={styles.item}>
            <Col sm={8} className={styles.label}>
              Studio (incl. GST):
            </Col>
            <Col sm={16}>
              <span className={styles.value}>
                ₹{props.product?.md_price_gst_studio}
                {props.product?.price_unit && "/"}
                {props.product?.price_unit ?? ""}{" "}
                {props.product?.md_landing_price_gst_studio &&
                  `(₹${props.product?.md_landing_price_gst_studio}/${props.product?.md_landing_price_unit})`}
              </span>
            </Col>
          </Row>
        </div>
        <div className={styles.details}>
          <Row className={styles.item}>
            <Col sm={8} className={styles.label}>
              MSP (incl. GST):
            </Col>
            <Col sm={16}>
              <span className={styles.value}>
                ₹{props.product?.md_price_gst_msp}
                {props.product?.price_unit && "/"}
                {props.product?.price_unit ?? ""}{" "}
                {props.product?.md_landing_price_gst_msp &&
                  `(₹${props.product?.md_landing_price_gst_msp}/${props.product?.md_landing_price_unit})`}
              </span>
            </Col>
          </Row>
        </div>
        <div className={styles.details}>
          <Row className={styles.item}>
            <Col sm={8} className={styles.label}>
              Margin:
            </Col>
            <Col sm={16}>
              <span className={styles.value}>
                {(
                  ((props.product?.md_price_gst_msp -
                    props.product?.md_price_gst) /
                    props.product?.md_price_gst) *
                  100
                ).toFixed(2)}
                %
              </span>
            </Col>
          </Row>
        </div>
      </>
    );
  }, [
    landingPrice,
    mdView,
    price,
    priceLabel,
    props.product?.md_landing_price_gst_msp,
    props.product?.md_landing_price_gst_studio,
    props.product?.md_landing_price_unit,
    props.product?.md_price_gst,
    props.product?.md_price_gst_msp,
    props.product?.md_price_gst_studio,
    props.product?.price_unit,
  ]);

  return (
    <Drawer
      title="Product Details"
      placement="right"
      onClose={props.onClose}
      visible={props.visible}
      width={500}
    >
      <section className={styles.container}>
        {props.product?.variant_image[0]?.image_url && (
          <div
            className={styles.image}
            style={{
              backgroundImage: `url("${selectedImage}")`,
              margin: `0 auto`,
            }}
          />
        )}

        <div className={styles.imageList}>
          {props.product?.variant_image.map((image: any, index: any) => (
            <img
              key={image?.image_url}
              alt={image?.image_alt_text}
              src={image?.image_url}
              onClick={() => {
                setSelectedImage(image?.image_url);
              }}
              className={`${styles.image} ${
                selectedImage === image?.image_url && styles.selected
              }`}
            />
          ))}
        </div>

        {/* {props?.rateContractImage?.src?.toString()} */}

        {/* <div className={styles.vendor}>{props.rateContract?.vendor?.name}</div> */}
        <div className={styles.title}>{props.product?.product_name}</div>

        <a
          href={`https://materialdepot.in/${props.product?.variant_handle}/product`}
          target="_blank"
          rel="noreferrer"
        >
          <Button type="primary" className={styles.button}>
            View on MaterialDepot
          </Button>
        </a>

        {(props?.product?.coverage_area !== undefined ||
          props?.product?.coverage_area !== null) &&
          props?.product?.category?.category_handle === "tiles" && (
            <TileView coverage_area={String(props?.product?.coverage_area)} />
          )}

        <div className={styles.details}>
          <Row className={styles.item}>
            <Col sm={8} className={styles.label}>
              Brand:
            </Col>
            <Col sm={16}>
              <span className={styles.value}>
                {props.product?.brand?.brand_name}
              </span>
            </Col>
          </Row>
        </div>
        <div className={styles.details}>
          <Row className={styles.item}>
            <Col sm={8} className={styles.label}>
              Category:
            </Col>
            <Col sm={16}>
              <span className={styles.value}>
                {props.product?.category?.category_name}
              </span>
            </Col>
          </Row>
        </div>
        {props.product?.package_size && (
          <div className={styles.details}>
            <Row className={styles.item}>
              <Col sm={8} className={styles.label}>
                Package Size:
              </Col>
              <Col sm={16}>
                <span className={styles.value}>
                  {props.product?.package_size}{" "}
                  {props.product?.package_size_unit}
                </span>
              </Col>
            </Row>
          </div>
        )}
        {/* {priceSection} */}
        {/* <div className={styles.details}>
          <Row className={styles.item}>
            <Col sm={8} className={styles.label}>
              Studio:
            </Col>
            <Col sm={16}>
              <span className={styles.value}>
                ₹{props.product?.md_price_gst}
                {props.product?.price_unit && "/"}
                {props.product?.price_unit ?? ""}{" "}
                {props.product?.md_landing_price_gst &&
                  `(₹${props.product?.md_landing_price_gst}/${props.product?.md_landing_price_unit})`}
              </span>
            </Col>
          </Row>
        </div> */}
        {props.product?.collection?.collection_name && (
          <div className={styles.details}>
            <Row className={styles.item}>
              <Col sm={8} className={styles.label}>
                Collection:
              </Col>
              <Col sm={16}>
                <span className={styles.value}>
                  {props.product?.collection?.collection_name}
                </span>
              </Col>
            </Row>
          </div>
        )}
        {props.product?.size && (
          <div className={styles.details}>
            <Row className={styles.item}>
              <Col sm={8} className={styles.label}>
                Size:
              </Col>
              <Col sm={16}>
                <span className={styles.value}>{props.product?.size}</span>
              </Col>
            </Row>
          </div>
        )}
        {props.product?.color?.color_family && (
          <div className={styles.details}>
            <Row className={styles.item}>
              <Col sm={8} className={styles.label}>
                Color:
              </Col>
              <Col sm={16}>
                <span className={styles.value}>
                  {props.product?.color?.color_family}
                </span>
              </Col>
            </Row>
          </div>
        )}
        {props.product?.finish && props.product?.finish.length > 0 && (
          <div className={styles.details}>
            <Row className={styles.item}>
              <Col sm={8} className={styles.label}>
                Finish:
              </Col>
              <Col sm={16}>
                <span className={styles.value}>
                  {(props.product?.finish ?? []).join(", ")}
                </span>
              </Col>
            </Row>
          </div>
        )}
        {props.product?.thickness && (
          <div className={styles.details}>
            <Row className={styles.item}>
              <Col sm={8} className={styles.label}>
                Thickness:
              </Col>
              <Col sm={16}>
                <span className={styles.value}>
                  {props.product?.thickness} mm
                </span>
              </Col>
            </Row>
          </div>
        )}
        {Object.keys(properties).map((key) => (
          <div className={styles.details} key={key}>
            <Row className={styles.item}>
              <Col sm={8} className={styles.label}>
                {key}:
              </Col>
              <Col sm={16}>
                <span className={styles.value}>{properties[key]}</span>
              </Col>
            </Row>
          </div>
        ))}
        {/* {(productDetails?.document?.length ?? 0) > 0 && <h6>Documents</h6>} */}
        {props.product?.document?.map(
          (doc: {
            document_id: React.Key | null | undefined;
            document_name:
              | string
              | number
              | boolean
              | React.ReactElement<
                  any,
                  string | React.JSXElementConstructor<any>
                >
              | Iterable<React.ReactNode>
              | React.ReactPortal
              | null
              | undefined;
            document_url: string | undefined;
          }) => (
            <div className={styles.details} key={doc.document_id}>
              <Row className={styles.item}>
                <Col sm={8} className={styles.label}>
                  {doc.document_name}:
                </Col>
                <Col sm={16}>
                  <span className={styles.value}>
                    <a
                      href={doc.document_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Document Link
                    </a>
                  </span>
                </Col>
              </Row>
            </div>
          )
        )}
        <section>
          <h6>Description</h6>
          <p>{props.product?.description}</p>
        </section>
      </section>
    </Drawer>
  );
};

export default ProductDrawer;
