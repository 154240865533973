import { Button, DatePicker, Form, FormInstance, Input, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { bookSlot, editSlot } from "../../../apis/client.api";

export function range(start: number, end: number) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

interface CreateBookingFormProps {
  bookingDetails?: {
    id: number
    bda: {
      id: string
      f_name: string
      contact: number
      email: any
    };
    user: {
      id: string;
      f_name: string;
      contact: number;
      email: string | null;
      modified_at: string;
      created_at: string;
    } | null;
    name: string
    contact: string
    email: string
    visit_date: null|string
    status: boolean
    created_at: string
    modified_at: string
  }
  onCreateBookingDrawerClose: (response: any) => unknown;
  getBookings: (...p: unknown[]) => unknown;
  editMode?: boolean;
}

const SubmitButton = ({ form, loading }: { form: FormInstance, loading?: boolean }) => {
  const [submittable, setSubmittable] = React.useState(false);

  // Watch all values
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values]);

  useEffect(() => {
    form.resetFields();
  }, []);

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable} loading={loading}>
      Submit
    </Button>
  );
};

const CreateBookingForm = ({
  bookingDetails,
  onCreateBookingDrawerClose,
  getBookings,
  editMode = false,
}: CreateBookingFormProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [
    client_alternate_contact_drawer_open,
    setClientAlternateContactDrawerOpen,
  ] = useState(false);

  const onFinish = async (updatedValues: any) => {
    if (updatedValues.visit_date) {
      updatedValues.visit_date = updatedValues.visit_date?.toDate()?.toISOString();
      updatedValues.booking_status = "confirmed";
    }
    try {
      setLoading(true);
      let response = null;
      if (editMode) {
        response = await editSlot({
          ...bookingDetails,
          ...updatedValues,
        });
        setLoading(false);
      } else {
        response = await bookSlot(updatedValues);
        setLoading(false);
      }
      await getBookings();
      onCreateBookingDrawerClose(response);
      form.resetFields();
    } catch (e) {}
  };

  const getInitialValues = () => {
    if (!editMode || !bookingDetails) return {};

    const result: Partial<{
      visit_date: moment.Moment;
      name: string;
      contact: string;
    }> = {};

    result.name = bookingDetails?.user?.f_name ?? bookingDetails.name;
    result.contact = bookingDetails.contact;
    if (bookingDetails.visit_date !== null) {
      // result.visit_date = moment(bookingDetails.visit_date);
    }

    return result;
  };

  return (
    <Form
      form={form}
      name="validateOnly"
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      initialValues={getInitialValues()}
      key={bookingDetails?.id ?? "new-form"}
    >
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
          },
        ]}
        label="Client Name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="contact"
        rules={[
          {
            required: true,
            pattern: /^[1-9]\d{9}$/gi,
            message: "Enter a valid Phone",
          },
        ]}
        label="Contact"
      >
        <Input />
      </Form.Item>
      <Form.Item name="visit_date" label="Visit Date">
        <DatePicker
          showSecond={false}
          showTime={{
            minuteStep: 30,
            format: "HH:mm",
            hideDisabledOptions: true,
          }}
          disabledTime={(current: any) => {
            return {
              disabledHours: () => range(0, 10).concat(range(21, 24)),
            };
          }}
          className="w-100"
          placeholder="Select Dispatch Time"
        />
      </Form.Item>

      <Form.Item>
        <Space>
          <SubmitButton form={form} loading={loading} />
          {/* <Button htmlType="reset">Reset</Button> */}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default CreateBookingForm;
