import { Drawer, Space, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import EditClientAlternateContactForm from "../edit_client_alternate_contact_form";
import { fetchClientAlternateContact } from "../../../apis/client.api";
import AddClientAlternateContactForm from "../add_client_alternate_contact_form";

interface ClientAlternateContactTableProps {
  user_id: any;
  client_alternate_contact_drawer_open: boolean;
  setClientAlternateContactDrawerOpen: Function;
}

const ClientAlternateContactTable = ({
  user_id,
  client_alternate_contact_drawer_open,
  setClientAlternateContactDrawerOpen,
}: ClientAlternateContactTableProps) => {
  const [alternate_contacts, setAlternateContacts] = useState<any>(null);
  const [contact, setContact] = useState<any>(null);
  const fetchAlternateContacts = () => {
    fetchClientAlternateContact(user_id).then((res) => {
      if (res) {
        setAlternateContacts(res);
      }
    });
  };
  const [
    client_edit_alternate_contact_drawer_open,
    setClientEditAlternateContactDrawerOpen,
  ] = useState(false);
  const columns = [
    {
      title: "S.no",
      dataIndex: "index",
      render: (value: any, data: any, index: number) => <>{index + 1}</>,
    },
    {
      title: "Name",
      key: "contact_person_name",
      render: (data: any) => <>{data?.contact_person_name}</>,
    },
    {
      title: "Phone",
      key: "contact_person_contact",
      render: (data: any) => <>{data?.contact_person_contact}</>,
    },
    {
      title: "Email",
      key: "contact_person_email",
      render: (data: any) => <>{data?.contact_person_email}</>,
    },
    {
      title: "Actions",
      key: "action",
      render: (data: any) => (
        <Space>
          <Tag
            onClick={() => {
              setContact(data);
              showClientEditAlternateContactDrawer();
            }}
            className="cursor-pointer"
            color={"warning"}
          >
            Edit Details
          </Tag>
        </Space>
      ),
    },
  ];

  const onClientEditAlternateContactDrawerClose = () => {
    setClientEditAlternateContactDrawerOpen(false);
  };

  const showClientEditAlternateContactDrawer = () => {
    setClientEditAlternateContactDrawerOpen(true);
  };

  const onClientAlternateContactDrawerClose = () => {
    setClientAlternateContactDrawerOpen(false);
  };
  useEffect(() => {
    fetchAlternateContacts();
  }, []);
  return (
    <div className="py-3 position-relative">
      <div className="th-font-size-18 th-font-weight-600 py-2 primary-color-text">
        Alternate contacts
      </div>
      <Table
        columns={columns}
        dataSource={alternate_contacts}
        pagination={false}
        // loading={loading}
        rowKey={"id"}
        scroll={{
          x: "100%",
        }}
      />

      <Drawer
        title="Edit Alternate Contact"
        placement="right"
        onClose={onClientEditAlternateContactDrawerClose}
        open={client_edit_alternate_contact_drawer_open}
        drawerStyle={{ height: "100%" }}
      >
        <EditClientAlternateContactForm
          fetchAlternateContacts={fetchAlternateContacts}
          onClientEditAlternateContactDrawerClose={
            onClientEditAlternateContactDrawerClose
          }
          contact={contact}
        />
      </Drawer>

      <Drawer
        title="Add Alternate Contact"
        placement="right"
        onClose={onClientAlternateContactDrawerClose}
        open={client_alternate_contact_drawer_open}
        drawerStyle={{ height: "100%" }}
      >
        <AddClientAlternateContactForm
          user_id={user_id}
          onClientAlternateContactDrawerClose={() => {
            onClientAlternateContactDrawerClose();
            fetchAlternateContacts();
          }}
        />
      </Drawer>
    </div>
  );
};

export default ClientAlternateContactTable;
