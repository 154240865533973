import { Button, Input, InputNumber, message } from "antd";
import { useState } from "react";
import styles from "./index.module.scss";
import { sendOtp, verifyOtp } from "../../../apis/auth.api";

const Login = () => {
  const [otp, setOtp] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [contact, setContact] = useState<string>("");
  const [view, setView] = useState<string>("phone");
  const [messageApi, contextHolder] = message.useMessage();

  const otpSend = () => {
    setLoading(true);
    messageApi.loading({
      type: "info",
      content: "Sending OTP",
      key: "otp",
    });
    sendOtp(contact).then((res) => {
      setLoading(false);

      messageApi.open({
        type: "success",
        content: "OTP sent",
        key: "otp",
      });
      if (res) {
        setView("otp");
      }
    });
  };

  const otpVerify = () => {
    setLoading(true);
    if (contact && otp) {
      verifyOtp(contact, otp).then((res) => {
        if (!res) {
          messageApi.warning({
            content: "Incorrect OTP",
            key: "otp",
          });
        }
      });
    }
  };

  const phoneNoTest = () => {
    const regexExp = /^[6-9]\d{9}$/gi;
    if (regexExp.test(contact)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <section
      className={`p-4 d-flex justify-content-center align-items-center ${styles.body_bg}`}
    >
      {contextHolder}
      <div className={`row border rounded-5 p-5 ${styles.container_login}`}>
        <div className="col-6">
          <div className="py-4 flex-column w-75">
            <h3>
              Sign in to Material Depot for{" "}
              <span className="fw-bold"> Procurement</span>
            </h3>
            <div className="mb-3">Welcome back! Log in to your account.</div>
            {view == "phone" ? (
              <>
                <div className="py-2">Phone</div>
                <InputNumber
                  placeholder="Phone"
                  className="w-75 mb-3"
                  maxLength={10}
                  minLength={10}
                  value={contact?.length > 0 ? Number(contact) : null}
                  min={0}
                  onChange={(e) => {
                    setContact(e?.toString() ?? "");
                  }}
                />
                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => {
                    otpSend();
                  }}
                  className="sticky-bottom w-75"
                  disabled={phoneNoTest()}
                >
                  <div className="fw-bold">Send OTP</div>{" "}
                </Button>
              </>
            ) : (
              <>
                <div className="py-2">OTP</div>
                <Input
                  placeholder="OTP"
                  className="w-75 mb-3"
                  value={otp}
                  min={0}
                  onChange={(e) => {
                    setOtp(e.target.value?.toString() ?? "");
                  }}
                />
                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => {
                    otpVerify();
                  }}
                  className="sticky-bottom w-75"
                >
                  <div className="fw-bold">Submit</div>{" "}
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="col-6">
          <img
            alt="logo"
            src="/images/login_bg.png"
            className={`rounded w-100 ${styles.img_container}`}
          />
        </div>
      </div>
    </section>
  );
};

export default Login;
