import Axios from "axios";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

import reportWebVitals from "./reportWebVitals";

export const baseURL = "https://api-dev2.materialdepot.in/";
// export const baseURL = "http://localhost:8000/";
Axios.defaults.baseURL = baseURL;

Axios.defaults.headers.common.Authorization = localStorage["token"]
  ? `JWT ${localStorage.getItem("token")}`
  : "";
Axios.interceptors.response.use(
  void function () {
    // throw new Axios.Cancel("Operation canceled by the user.");
  },
  function (error) {
    if (error.response?.status == 403) {
      return window.location.replace("/login");
    }
    return Promise.reject(error);
  }
);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
