import { Select, Tag } from "antd";
import { useEffect, useState } from "react";

interface PFICreateShipToAddressProps {
  removeShippingAddress: Function;
  setData: Function;
  client_addresses: Array<any>;
  data: any;
  onSelectAddress?: (address: any) => void;
  noRemove?: boolean;
}
const { Option } = Select;

export const PFICreateShipToAddress = ({
  removeShippingAddress,
  client_addresses,
  setData,
  data,
  noRemove,
  onSelectAddress,
}: PFICreateShipToAddressProps) => {
  const [ship_to_address, setShipToAddress] = useState<any>(null);

  useEffect(() => {
    if (ship_to_address?.id) {
      setData({
        ...data,
        ship_pincode: ship_to_address?.pincode,
        ship_address_description:
          ship_to_address?.address +
          ", " +
          ship_to_address?.city +
          ", " +
          ship_to_address?.pincode,
        ship_address: ship_to_address?.id,
      });
    }
  }, [ship_to_address]);

  return (
    <section className="p-3 py-2 row mb-2">
      <div className="d-flex justify-content-between">
        <h6 className="th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
          Ship To
        </h6>
        {!noRemove && (
          <div className="">
            <Tag
              style={{ cursor: "pointer" }}
              onClick={() => removeShippingAddress()}
            >
              Remove
            </Tag>
          </div>
        )}
      </div>
      <div className="mt-3 row">
        <div className="col-4">Address Label</div>
        <div className="col-8 d-flex">
          <div>:</div>
          <div className="w-100 px-2">{ship_to_address?.name ?? ""}</div>
        </div>
      </div>
      <div className="mt-3 row">
        <div className="col-4">Address</div>
        <div className="col-8 d-flex">
          <div>:</div>
          <div className="w-100 px-2">
            {/* <Form.Item name="ship_address"> */}
            <Select
              className="w-100"
              key={"ship_address_select"}
              value={ship_to_address?.id}
              optionFilterProp="children"
              placeholder={"Address"}
              onChange={(e) => {
                if (onSelectAddress) {
                  onSelectAddress(
                    client_addresses.find((addr) => addr.id === e)
                  );
                }
                setShipToAddress(
                  client_addresses.find((addr) => addr.id === e)
                );
              }}
            >
              {client_addresses?.map((each) => {
                return (
                  <Option key={each.id} value={each.id}>
                    {each?.name}
                  </Option>
                );
              })}
            </Select>
            {/* </Form.Item> */}
          </div>
        </div>
      </div>

      <div className="mt-3 row">
        <div className="col-4">Contact person</div>
        <div className="col-8 d-flex">
          <div>:</div>
          <div className="w-100 px-2">
            {ship_to_address?.contact_person_name}
          </div>
        </div>
      </div>
      <div className="mt-3 row">
        <div className="col-4">Contact number</div>
        <div className="col-8 d-flex">
          <div>:</div>
          <div className="w-100 px-2">
            {ship_to_address?.contact_person_contact}
          </div>
        </div>
      </div>

      <div className="mt-3 row">
        {ship_to_address && (
          <>
            <div className="col-4">Selected address</div>
            <div className="col-8 d-flex">
              <div>:</div>
              <div className="w-100 px-2">
                {ship_to_address?.flat_no}, {ship_to_address?.address},{" "}
                {ship_to_address?.city} {ship_to_address?.pincode}
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};
