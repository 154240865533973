import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { fetchUserDetails } from "../../../../apis/user.api";
import { range } from "../../../client/add_client_booking_form";
const { Option } = Select;

export interface BankDetails {
  bank_account_number: string;
  bank_ifsc: string;
  branch_upi: string;
}

const type_of_order_options = [
  {
    id: 1,
    label: "PO",
    value: "po",
  },
  {
    id: 2,
    label: "WO",
    value: "wo",
  },
  {
    id: 3,
    label: "Internal",
    value: "internal",
  },
  {
    id: 4,
    label: "Estimate",
    value: "estimate",
  },
];
export interface POCreateOrderDetailsProps {
  client_data: any;
  data: any;
  setData: Function;
}

const POCreateOrderDetails = ({
  client_data,
  data,
  setData,
}: POCreateOrderDetailsProps) => {
  const [user_details, setUserDetails] = useState<any>(null);
  const [po_type, setPOType] = useState("po");

  useEffect(() => {
    fetchUserDetails().then((res) => {
      setUserDetails(res);
    });
  }, []);

  useEffect(() => {
    setData("type_of_order", po_type);
  }, [po_type]);

  return (
    <div className="p-3 py-2 row mb-2">
      <div className="th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
        Order Details
      </div>
      <div className="col">
        <div className="row">
          <div className="col-4 th-font-weight-500">Created By</div>
          <div className="col-8 pb-1">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{user_details?.name}</div>
            </div>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-4 th-font-weight-500">Client </div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{client_data?.f_name}</div>
            </div>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-4 th-font-weight-500">Status</div>
          <div className="col-8">
            <div className="d-flex ">
              <div className="pe-1">:</div>
              <div className="w-100">
                <Select
                  className="w-100"
                  key={"type_of_order"}
                  value={po_type}
                  onChange={(e) => {
                    setPOType(e);
                  }}
                >
                  {type_of_order_options?.map((each, index) => (
                    <Option value={each?.value} key={each?.id}>
                      {each?.label}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row py-2">
          <div className="col-4 th-font-weight-500">Delivery Date</div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div className="w-100">
                <DatePicker
                  className="w-100"
                  placeholder="Select Deadline"
                  value={
                    dayjs(data?.delivery_date) ??
                    dayjs(moment().format("YYYY-MM-DD").toString())
                  }
                  onChange={(e: any, dateString) => {
                    setData("delivery_date", dateString);
                  }}
                />
              </div>
            </div>
          </div>
        </div> */}
        <div className="row py-2">
          <div className="col-4 th-font-weight-500">Dispatch Time & Date</div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div className="w-100">
                <DatePicker
                  showSecond={false}
                  showTime={{
                    minuteStep: 30,
                    format: "HH:mm",
                    hideDisabledOptions: true,
                  }}
                  disabledTime={(current: any) => {
                    return {
                      disabledHours: () => range(0, 10).concat(range(18, 24)),
                    };
                  }}
                  className="w-100"
                  placeholder="Select Dispatch Time"
                  value={dayjs(data?.dispatch_time ?? null)}
                  onChange={(e: any, dateString) => {
                    setData("dispatch_time", dateString);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default POCreateOrderDetails;
