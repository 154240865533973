import { Button, Drawer } from "antd";
import styles from "./index.module.scss";
import { useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import BranchTermsEditForm from "../branch_terms_edit_form";
import { deleteBranchTerms } from "../../../../apis/branch.api";
import { usePermissions } from "../../../../custom_hooks/permission/permission_hook";

export interface TermsAndConditionsCardProps {
  term: any;
  letUpdate: Function;
}

const TermsAndConditionsCard = ({
  term,
  letUpdate,
}: TermsAndConditionsCardProps) => {
  const { hasPermission } = usePermissions();

  const [branch_term_edit_form, setBranchTermEditForm] = useState(false);
  const showBranchTermEditDrawer = () => {
    setBranchTermEditForm(true);
  };

  const onTermEditDrawerClose = () => {
    setBranchTermEditForm(false);
  };
  return (
    <div className="col-5">
      <div className="row border rounded-2  p-4">
        <div className="col-10">
          {term?.title && (
            <div className="row">
              <div className="col-4 th-font-weight-500">Title </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{term?.title}</div>
                </div>
              </div>
            </div>
          )}
          {term?.description && (
            <div className="row">
              <div className="col-4 th-font-weight-500 ">Description </div>
              <div className="col-7 overflow-hidden">
                <div className="d-flex ">
                  <div className={"pe-1 "}>:</div>
                  <div className={styles.line_clamp}>{term?.description}</div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-2">
          <Button
            disabled={!hasPermission("branch_details.create")}
            onClick={() => {
              showBranchTermEditDrawer();
            }}
          >
            <EditOutlined />
          </Button>
          <div className="mt-1">
            <Button
              disabled={!hasPermission("branch_details.create")}
              onClick={() => {
                deleteBranchTerms(term?.id).then(() => {
                  letUpdate();
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        </div>
      </div>
      <Drawer
        title="Edit Terms"
        placement="right"
        closable={false}
        onClose={onTermEditDrawerClose}
        open={branch_term_edit_form}
      >
        <BranchTermsEditForm
          terms={term}
          closeDrawer={onTermEditDrawerClose}
          letUpdate={letUpdate}
        />
      </Drawer>
    </div>
  );
};

export default TermsAndConditionsCard;
