import { Button } from "antd";
import CartItemCard from "../cart_item_card";
import CartConsolidation from "../cart_consolidation";
import { usePermissions } from "../../../custom_hooks/permission/permission_hook";

interface ClientCartProps {
  cart_items: Array<any>;
  createEstimate: Function;
  loading: boolean;
  setLoading: Function;
}

const ClientCart = ({
  cart_items,
  createEstimate,
  loading,
  setLoading,
}: ClientCartProps) => {
  const { hasPermission } = usePermissions();

  return (
    <div className="px-1 position-relative">
      <div className="pb-3 d-flex justify-content-between">
        <div className="align-middle th-font-size-18 th-font-weight-600  primary-color-text">
          Cart Details ({cart_items?.length ?? 0} items)
        </div>
        {hasPermission("pi.create") && (
          <Button
            onClick={() => {
              createEstimate();
            }}
            loading={loading}
            size="middle"
            className="fw-bold"
            type="primary"
          >
            Create PI from cart
          </Button>
        )}
      </div>
      <div className="row">
        <div className="col-8">
          {cart_items?.map((cart_item) => {
            return <CartItemCard cart_item={cart_item} />;
          })}
        </div>
        <div className="col-4">
          <CartConsolidation cart_items={cart_items} />
        </div>
      </div>
    </div>
  );
};

export default ClientCart;
