import { Button, Form, Input, InputNumber, Select, Space } from "antd";
import { useState } from "react";

export interface OrderPlacedDetailsDrawerProps {
  onFinish: (values: any) => void;
  onCancel: () => void;
}

export const PaymentTypes = [
  { value: "cash", label: "Cash", referenceTypes: ["others"] },
  {
    value: "neft_rtgs_imps",
    label: "NEFT / RTGS / IMPS",
    referenceTypes: ["utr"],
  },
  { value: "upi", label: "UPI", referenceTypes: ["upi_transaction_id"] },
  { value: "razorpay", label: "Razorpay", referenceTypes: ["others"] },
];

export const PaymentReferenceTypes = [
  { value: "utr", label: "UTR" },
  { value: "upi_transaction_id", label: "UPI Transaction ID" },
  { value: "others", label: "Others" },
];

export function OrderPlacedDetailsDrawer({
  onFinish,
  onCancel,
}: OrderPlacedDetailsDrawerProps) {
  const [paymentType, setPaymentType] = useState<string | undefined>(undefined);

  const onValuesChange = (changedValues: any, allValues: any) => {
    if (changedValues.payment_type) {
      setPaymentType(changedValues.payment_type);
    }
  };

  return (
    <Form layout="vertical" onFinish={onFinish} onValuesChange={onValuesChange}>
      <Form.Item name="payment_type" label="Payment Type" required>
        <Select placeholder="Select Payment Type" options={PaymentTypes} />
      </Form.Item>
      <Form.Item
        name="payment_reference_type"
        label="Payment Reference Type"
        required
      >
        <Select
          placeholder="Select Payment Reference Type"
          options={PaymentReferenceTypes.filter((type) => {
            const selectedPaymentType = PaymentTypes.find(
              (type) => type.value === paymentType
            );
            console.log("debug>>>", paymentType);
            return selectedPaymentType?.referenceTypes?.includes(type.value);
          })}
        />
      </Form.Item>
      <Form.Item name="payment_reference_id" label="Payment Reference" required>
        <Input placeholder="Enter Payment Reference" />
      </Form.Item>
      <Form.Item
        label="Amount"
        name="payment_amount"
        required
        rules={[
          {
            type: "regexp",
            pattern: /^[0-9]+$/,
          },
        ]}
      >
        <InputNumber className="w-100" placeholder="Enter Amount" />
      </Form.Item>
      <Form.Item className="d-flex justify-content-end">
        <Space>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
