import { Select } from "antd";
import { useEffect, useState } from "react";
import { getVendor } from "../../../../apis/vendor.api";

export interface Vendor {
  id: number;
  category: Category[];
  brand: any[];
  name: string;
  vendor_code: string;
  contact: string;
  vendor_type: string;
  email: string;
  contact_person: string;
  comment: any;
  designation: any;
  pan_number: string;
  gst: string;
  bank_account_holder: string;
  bank_account_number: string;
  bank_ifsc: string;
  billing_address: string;
  pincode: string;
  city: any;
  optional_contact1: string;
  optional_contact_name1: string;
  optional_contact2: string;
  optional_contact_name2: string;
  optional_email1: string;
  optional_email2: string;
  map1: any;
  map2: any;
  map3: any;
  is_verified: boolean;
  status: boolean;
  organisation: number;
  branch: number;
}

export interface Category {
  id: number;
  category_name: string;
  status: boolean;
  organisation: number;
}

interface POEditVendorDetailsProps {
  setData: Function;
  client_addresses: Array<any>;
  data: any;
  vendors: Array<any>;
}
const { Option } = Select;

export const POEditVendorDetails = ({
  client_addresses,
  setData,
  data,
  vendors,
}: POEditVendorDetailsProps) => {
  const [selected_vendor, setSelectedVendor] = useState<any>(null);
  const [vendorWarehouses, setVendorWarehouses] = useState<any>([]);

  useEffect(() => {
    if (data?.vendor !== selected_vendor?.id && selected_vendor?.id) {
      setData({
        ...data,
        vendor: selected_vendor?.id,
      });
    }

    (async () => {
      const vendor = await getVendor(selected_vendor?.id);
      if (vendor?.warehouses) {
        setVendorWarehouses(vendor?.warehouses);

        if (data?.vendor_warehouse) {
          const vendorWarehouseId =
            typeof data?.vendor_warehouse === "object"
              ? data?.vendor_warehouse?.id
              : data?.vendor_warehouse;
          if (
            !vendor?.warehouses?.find(
              (each: any) => String(each.id) === String(vendorWarehouseId)
            )
          ) {
            console.log("debug>>> aaaa", data?.vendor_warehouse);
            setData({
              ...data,
              vendor_warehouse: null,
            });
          }
        }
      }
    })();
  }, [selected_vendor]);

  useEffect(() => {
    console.log("data?.vendor", data?.vendor, selected_vendor?.id);
    if (data?.vendor !== selected_vendor?.id && vendors?.length > 0) {
      console.log("data?.vendor 1", data?.vendor, selected_vendor?.id);
      setSelectedVendor(
        vendors?.find((element: any) => {
          return data?.vendor == element?.id;
        })
      );
    }
  }, [data?.vendor, vendors]);

  return (
    <section className="p-3 py-2 row mb-2">
      <div className="d-flex justify-content-between">
        <h6 className="th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
          Vendor Details
        </h6>
      </div>
      <div className="mt-3 row">
        <div className="col-4">Vendor Name </div>
        <div className="col-8 d-flex">
          <div>:</div>
          <div className="w-100 px-2">
            {/* <Form.Item name="bill_address"> */}
            <Select
              className="w-100"
              showSearch
              key={"vendor"}
              value={data?.vendor}
              optionFilterProp="children"
              placeholder={"Vendor"}
              onChange={(e) => {
                setSelectedVendor(vendors?.find((addr) => addr.id === e));
              }}
            >
              {vendors?.map((each) => {
                return (
                  <Option key={each.id} value={each.id}>
                    {each?.name}
                  </Option>
                );
              })}
            </Select>
            {/* </Form.Item> */}
          </div>
        </div>
      </div>

      <div className="mt-3 row">
        <div className="col-4">Address</div>
        <div className="col-8 d-flex">
          <div>:</div>
          <div className="w-100 px-2">
            {selected_vendor?.billing_address ?? ""}
          </div>
        </div>
      </div>
      <div className="mt-3 row">
        <div className="col-4">Contact details</div>
        <div className="col-8 d-flex">
          <div>:</div>
          <div className="w-100 px-2">
            {selected_vendor?.contact}, {selected_vendor?.email}
          </div>
        </div>
      </div>
      <div className="mt-3 row">
        <div className="col-4">GST</div>
        <div className="col-8 d-flex">
          <div>:</div>
          <div className="w-100 px-2">{selected_vendor?.gst}</div>
        </div>
      </div>

      {Array.isArray(vendorWarehouses) && vendorWarehouses.length > 0 && (
        <div className="mt-3 row">
          <div className="col-4">Warehouse</div>
          <div className="col-8 d-flex">
            <div>:</div>
            <Select
              className="w-100 px-2"
              showSearch
              key={"warehouse_select"}
              value={data?.vendor_warehouse?.id ?? data?.vendor_warehouse}
              optionFilterProp="children"
              placeholder={"Warehouse"}
              onChange={(e) => {
                setData({
                  ...data,
                  vendor_warehouse: e,
                });
              }}
              allowClear
            >
              {vendorWarehouses?.map((each: any) => {
                return (
                  <Option key={each.id} value={each.id}>
                    {each?.name}
                  </Option>
                );
              })}
            </Select>
          </div>
          {data?.vendor_warehouse && (
            <div className="col-8 offset-4 d-flex">
              <div className="w-100 px-2">
                {
                  vendorWarehouses?.find(
                    (each: any) => each.id === data?.vendor_warehouse
                  )?.address
                }
              </div>
            </div>
          )}
        </div>
      )}
    </section>
  );
};
