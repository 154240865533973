import { Button, Drawer, Form, FormInstance, Input, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

interface EWayBillDrawerProps {
  visible: boolean;
  onFinish: Function;
  onClose: Function;
}

const EWayBillDrawer = ({
  visible,
  onFinish,
  onClose,
}: EWayBillDrawerProps) => {
  const formRef = React.useRef<FormInstance>(null);
  const onSubmit = () => {
    if (eway_bill_no?.length > 0) {
      onFinish({
        ...formRef.current?.getFieldsValue(),
      });
    } else {
      message.warning("Please set e way bill no.");
    }
  };
  const [eway_bill_no, setEWayBillNo] = useState("");

  useEffect(() => {
    formRef.current?.resetFields();
  }, []);
  return (
    <>
      <Drawer
        title="Shipment Details"
        placement="right"
        open={visible}
        closable={true}
        onClose={() => {
          onClose();
        }}
        width={600}
        footer={
          <div className="d-flex gap-2">
            <Button onClick={() => onClose(true)}>Cancel</Button>
            <Button type="primary" onClick={onSubmit}>
              Save
            </Button>
          </div>
        }
      >
        <Form layout="vertical" onFinish={onSubmit} ref={formRef}>
          <Form.Item
            name="eway_bill_no"
            label="E way bill"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              onChange={(e) => {
                setEWayBillNo(e.target.value);
              }}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default EWayBillDrawer;
