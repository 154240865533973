import { getImageUrl } from "../../../../helpers/image_helper";
import styles from "./index.module.scss";
import { getFormattedDate } from "../../../../helpers/date_helper";

export interface BankDetails {
  bank_account_number: string;
  bank_ifsc: string;
  branch_upi: string;
}
export interface POOrderDetails1Props {
  order_number: string;
  created_by: string;
  client: string;
  client_contact: number;
  delivery_date: string;
  order_date: string;
  lead_id: string;
  quotation_no: string;
  status: string;
  bank_details: BankDetails;
}

const POOrderDetails1 = ({
  order_number,
  client_contact,
  created_by,
  client,
  delivery_date,
  order_date,
  lead_id,
  quotation_no,
  status,
  bank_details,
}: POOrderDetails1Props) => {
  return (
    <div className="p-3 py-2 row mt-5 ml-2 pl-2 th-font-size-16 th-font-weight-500">
      <div className="th-font-weight-700 pb-1 black-color-text">
        Order Details
      </div>
      <div className="col">
        <div className="row">
          <div className="col-4 th-font-weight-500">Purchase Order#</div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1 ">:</div>
              <div className="th-font-weight-500 py-0">
                {order_number /*replace with dummy id*/}
              </div>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="col-4 th-font-weight-500">Order Date</div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div className="th-font-weight-500 py-0">
                {getFormattedDate(order_date)}
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-4 th-font-weight-500">Delivery Date</div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div className="th-font-weight-500 py-0">
                {getFormattedDate(delivery_date)}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4 th-font-weight-500">Purchaser</div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div className="th-font-weight-500 py-0">{created_by}</div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-4 th-font-weight-500">Contact Details</div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div className="th-font-weight-500 py-0">{client_contact}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default POOrderDetails1;
