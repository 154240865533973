import { message } from "antd";
import axios from "axios";
import { errorActions } from "../helpers/auth_helper";

export interface BranchMember {
  id: number;
  user: User;
  branch: Branch[];
  category_name: any;
  department: string;
  designation: any;
  status: boolean;
  user_permission: number;
}

export interface User {
  id: string;
  roles: Roles;
  date_joined: string;
  show_priced_products: boolean;
  is_verified: boolean;
  last_login: any;
  is_superuser: boolean;
  md_user_id: any;
  f_name: string;
  l_name: any;
  email: string;
  company_name: any;
  profile_img: any;
  company_img: any;
  whatsapp_group_link: any;
  gst_number: any;
  coa_reg_no: any;
  pincode: number;
  city: any;
  other_profession: any;
  pan: any;
  company_address: any;
  contact: number;
  country_code: string;
  fcmToken: any;
  is_staff: boolean;
  is_active: boolean;
  migrated_id: any;
  referral_code: string;
  alternate_contact: any;
  alternate_email: any;
  bnpl_active: boolean;
  rupifi_activation_url: any;
  created_at: string;
  modified_at: string;
  verification_status: any;
  user_source: string;
  channel: any;
  medium: any;
  md_project_id: any;
  zoho_customer_id: any;
  default_address: any;
  groups: any[];
  user_permissions: any[];
  profession: any[];
}

export interface Roles {
  id: number;
  name: string;
}

export interface Branch {
  id: number;
  branch_name: string;
  address: string;
  branch_head_designation: any;
  branch_head_name: any;
  branch_head_signature: any;
  bank_account_holder: string;
  bank_account_number: string;
  bank_ifsc: string;
  branch_upi: string;
  project_code_initials: string;
  project_code_format: string;
  project_code_seprator: string;
  po_number_initials: string;
  po_number_format: string;
  po_number_seprator: string;
  bid_number_initials: string;
  bid_number_format: string;
  bid_number_seprator: string;
  po_counter: number;
  bid_counter: number;
  estimate_number_initials: string;
  estimate_number_format: string;
  estimate_number_seprator: string;
  estimate_counter: number;
  estimate_trail_zero: number;
  lead_number_initials: string;
  lead_number_format: string;
  lead_number_seprator: string;
  lead_counter: number;
  inventory_number_initials: string;
  inventory_number_format: string;
  inventory_counter: number;
  qr_code: any;
  organisation_logo: string;
  payment_logo: string;
  access_token: string;
  status: boolean;
}

export const fetchBranches = async () => {
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/orgainsation-branch/`)
      .then((res) => {
        return res?.data[0] ?? null;
      })
      .catch((error) => {
        errorActions(
          error,
          "Error fetching branches. Please check and try again"
        );
        return;
      });
  } catch (error) {}
};

export const fetchBranchTermAandConditions = async () => {
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/terms-and-condition/`)
      .then((res) => {
        return res?.data ?? [];
      })
      .catch((error) => {
        errorActions(
          error,
          "Error fetching branch terms and conditions. Please check and try again"
        );
        return;
      });
  } catch (error) {
    return null;
  }
};

export const fetchBranchGst = async () => {
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/gst/`)
      .then((res) => {
        console.log(res);
        return res?.data ?? [];
      })
      .catch((error) => {
        errorActions(
          error,
          "Error fetching branch gst. Please check and try again"
        );
        return;
      });
  } catch (error) {
    return null;
  }
};

export const addGstToBranch = async (values: any) => {
  try {
    return axios
      .post("https://api-dev2.materialdepot.in/apiV1/gst/", {
        ...values,
        branch: 1,
      })
      .then((res) => {
        console.log("GST added", res);
        message.success("New gst added succesfully");
      })
      .catch((e) => {
        if (e?.response?.status == 400) {
          message.error("GST already added.");
          errorActions(e, "Error Occurred. Please try again");
        }
      });
  } catch (error) {}
};

export const deleteBranchGst = async (id: any) => {
  try {
    return axios
      .delete(`apiV1/gst/${id}`)
      .then((res) => {
        console.log("GST deleted", res);
        message.success("Gst deleted succesfully");
      })
      .catch((e) => {
        errorActions(e, "Error Occurred. Please try again");
      });
  } catch (error) {}
};

export const editBranchGst = async (id: any, values: any) => {
  try {
    return axios
      .put(`apiV1/gst/${id}/`, { ...values, branch: 1 })
      .then((res) => {
        console.log("GST edited", res);
        message.success("Gst edited succesfully");
      })
      .catch((e) => {
        errorActions(e, "Error Occurred. Please try again");
      });
  } catch (error) {}
};

export const editBranchTerms = async (id: any, values: any) => {
  try {
    return axios
      .put(`apiV1/terms-and-condition/${id}/`, { ...values, branch: 1 })
      .then((res) => {
        message.success("Edited succesfully");
      })
      .catch((error) => {
        errorActions(error, "Error Occurred. Please check and try again");
        return;
      });
  } catch (error) {
    return null;
  }
};

export const deleteBranchTerms = async (id: any) => {
  try {
    return axios
      .delete(`apiV1/terms-and-condition/${id}/`)
      .then((res) => {
        message.success("Deleted succesfully");
      })
      .catch((error) => {
        errorActions(error, "Error Occurred. Please check and try again");
        return;
      });
  } catch (error) {
    return null;
  }
};

export const addBranchTerms = async (values: any) => {
  try {
    return axios
      .post(`https://api-dev2.materialdepot.in/apiV1/terms-and-condition/`, {
        ...values,
        branch: 1,
      })
      .then((res) => {
        message.success("New terms added succesfully");
      })
      .catch((error) => {
        errorActions(error, "Error Occurred. Please check and try again");
        return;
      });
  } catch (error) {
    return null;
  }
};

export const fetchBranchMembers = async (params?: any) => {
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/user-organisation/`, {
        params: { ...params },
      })
      .then((res) => {
        return res?.data ?? [];
      })
      .catch((error) => {
        errorActions(
          error,
          "Error fetching branch members. Please check and try again"
        );
        return;
      });
  } catch (error) {
    return null;
  }
};

export const addMemberToBranch = async (values: any) => {
  try {
    return axios
      .post("https://api-dev2.materialdepot.in/apiV1/user-organisation/", {
        ...values,
        branch: [1],
      })
      .then((res) => {
        console.log("Member added", res);
        message.success("New member added succesfully");
      })
      .catch((e) => {
        if (e?.response?.status == 400) {
          message.error("User already added.");
          errorActions(e, "Error Occurred. Please try again");
        }
      });
  } catch (error) {}
};

export const editMemberOfBranch = async (values: any, user_id: string) => {
  try {
    return axios
      .put("apiV1/user-organisation/" + user_id + "/", { ...values })
      .then((res) => {
        console.log("Member added", res);
        message.success("Edited succesfully");
      })
      .catch((e) => {
        errorActions(e, "Error Occurred. Please try again");
      });
  } catch (error) {}
};

export const fetchUserPermissions = async () => {
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/user-permission/`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        errorActions(
          error,
          "Error fetching user permissions. Please check and try again"
        );
        return;
      });
  } catch (error) {
    return [];
  }
};

export const fetchBranchTerms = async (branch_id: string) => {
  try {
    return axios
      .get(
        `https://api-dev2.materialdepot.in/apiV1/terms-and-condition/` +
          "?branch=" +
          branch_id
      )
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        errorActions(
          error,
          "Error fetching branch terms. Please check and try again"
        );
        return;
      });
  } catch (error) {
    return [];
  }
};
