import { Header } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { fetchUserDetails } from "../../../apis/user.api";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { PoweroffOutlined } from "@ant-design/icons";

const UserProfile = () => {
  const [user, setUser] = useState<any>({});

  useEffect(() => {
    fetchUserDetails().then((res) => {
      setUser(res);
      console.log(res);
    });
  }, []);

  return (
    <section className={`lighter-grey-color-bg`}>
      <Header
        className="d-flex flex-row justify-content-end align-items-center"
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
        }}
      >
        <Link to={"/login"} className="text-decoration-none">
          <div className="d-flex align-items-center">
            <Button
              danger
              type="primary"
              onClick={() => {
                localStorage.removeItem("token");
              }}
              className="px-2 fw-bold text-center d-flex flex-row align-items-center"
            >
              <PoweroffOutlined style={{ strokeWidth: "50" }} />

              <span>Logout</span>
            </Button>
          </div>
        </Link>
      </Header>
      <div>
        <div className="row mx-1 py-4 d-flex flex-row justify-content-start align-items-top">
          <div className="col-4 mx-4 white-color-bg">
            <div className="p-3 th-font-size-20 primary-color-text th-font-weight-700">
              My Profile
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                className="rounded-circle mt-2"
                width="150px"
                src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
              />

              <span className="pb-2 th-font-weight-700  th-font-size-20">
                {user?.name}
              </span>
              <span className="pb-2 th-font-size-16">{user?.designation}</span>
            </div>
          </div>
          <div className="col-7 white-color-bg">
            <div className="col-md-5 border-right">
              <form id="contactForm">
                <div className="p-3">
                  <div className="col pt-2">
                    <label className="form-label" style={{ fontWeight: 700 }}>
                      Name
                    </label>
                    <div className="th-font-size-18">
                      {user?.name ? user.name : ""}
                    </div>
                  </div>

                  <div className="col pt-3">
                    <label className="form-label" style={{ fontWeight: 700 }}>
                      Email Address
                    </label>

                    <div className="th-font-size-18">
                      {user?.email ? user.email : ""}
                    </div>
                  </div>

                  <div className="col pt-3">
                    <label className="form-label" style={{ fontWeight: 700 }}>
                      Designation
                    </label>

                    <div className="th-font-size-18">
                      {user?.designation ? user.designation : ""}
                    </div>
                  </div>

                  <div className="col pt-3">
                    <label className="form-label" style={{ fontWeight: 700 }}>
                      Department
                    </label>

                    <div className="th-font-size-18">
                      {user?.department ? user.department : ""}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserProfile;
