export function getImageUrl(path: string, width: string) {
  var img_url: string = path?.replace(
    "materialdepotimages.s3.ap-south-1.amazonaws.com",
    "dpy2z8n9cxui1.cloudfront.net"
    // `materialdepotimages-compressed-${percent}-percent`
  );

  img_url = img_url?.replaceAll(
    "material-depot-content-files.s3.ap-south-1.amazonaws.com",
    "d3faqy0icgqzj8.cloudfront.net"
  );

  if (img_url) {
    if (img_url[0] === "/") {
      img_url = "https://dpy2z8n9cxui1.cloudfront.net" + img_url;
    }
  }

  img_url = img_url?.replaceAll("+", "%20");
  if (!img_url?.startsWith("https")) {
    img_url = "https://dpy2z8n9cxui1.cloudfront.net" + img_url;
  }
  if (width !== "") {
    img_url += "?width=" + width;
  }

  return img_url;
}

export const reducedImageUrl = (
  percent: number,
  imgUrl: string,
  isMobile: boolean | null | undefined
) => {
  if (percent === 100 || !imgUrl) {
    return imgUrl;
  }
  var newUrl = imgUrl.replace(
    "materialdepotimages.s3.ap-south-1.amazonaws.com",
    "dpy2z8n9cxui1.cloudfront.net"
    // `materialdepotimages-compressed-${percent}-percent`
  );
  newUrl = newUrl.replaceAll(
    "material-depot-content-files.s3.ap-south-1.amazonaws.com",
    "d3faqy0icgqzj8.cloudfront.net"
  );
  if (newUrl[0] == "/") {
    newUrl = "https://dpy2z8n9cxui1.cloudfront.net" + newUrl;
  }

  newUrl = newUrl.replaceAll("+", "%20");

  if (isMobile == undefined || isMobile == null || !isMobile) {
    if (window.innerWidth < 768) {
      if (percent == 20) {
        newUrl += "?width=100";
      } else if (percent == 40) {
        newUrl += "?width=200";
      } else if (percent == 60) {
        newUrl += "?width=360";
      } else if (percent == 80) {
        newUrl += "?width=460";
      }
    } else {
      if (percent == 20) {
        newUrl += "?width=100";
      } else if (percent == 40) {
        newUrl += "?width=300";
      } else if (percent == 60) {
        newUrl += "?width=800";
      } else if (percent == 80) {
        newUrl += "?width=1200";
      }
    }
  } else {
    if (isMobile == true) {
      if (percent == 20) {
        newUrl += "?width=40";
      } else if (percent == 40) {
        newUrl += "?width=80";
      } else if (percent == 60) {
        newUrl += "?width=160";
      } else if (percent == 80) {
        newUrl += "?width=360";
      }
    } else {
      if (percent == 20) {
        newUrl += "?width=100";
      } else if (percent == 40) {
        newUrl += "?width=300";
      } else if (percent == 60) {
        newUrl += "?width=800";
      } else if (percent == 80) {
        newUrl += "?width=1200";
      }
    }
  }

  return newUrl;
};
export const getImage = (
  page: string,
  section: string,
  imgUrl: string,
  isMobile: any
) => {
  if (imgUrl) {
    imgUrl = imgUrl.replaceAll(" ", "+");
    imgUrl = imgUrl.replaceAll("#", "%23");
  }
  if (page === "home") {
    if (section === "carousel") {
      return reducedImageUrl(60, imgUrl, isMobile);
    } else if (section === "explore-by-category") {
      return reducedImageUrl(40, imgUrl, isMobile);
    } else if (section === "explore-by-space") {
      return reducedImageUrl(60, imgUrl, isMobile);
    } else if (section === "material-palettes") {
      return reducedImageUrl(40, imgUrl, isMobile);
    } else if (section === "bundles") {
      return reducedImageUrl(40, imgUrl, isMobile);
    } else if (section === "about-us") {
      return reducedImageUrl(60, imgUrl, isMobile);
    } else if (section === "blogs") {
      return reducedImageUrl(60, imgUrl, isMobile);
    } else if (section == "competition") {
      return reducedImageUrl(60, imgUrl, isMobile);
    }
  } else if (page === "product") {
    if (section === "full-image") {
      return reducedImageUrl(80, imgUrl, isMobile);
    } else if (section === "main-image") {
      return reducedImageUrl(60, imgUrl, isMobile);
    } else if (section === "small-image") {
      return reducedImageUrl(20, imgUrl, isMobile);
    } else if (section === "variant-image") {
      return reducedImageUrl(20, imgUrl, isMobile);
    }
  } else if (page === "product-tile") {
    if (section === "tile-image") {
      return reducedImageUrl(60, imgUrl, isMobile);
    }
    if (section === "compare-image") {
      return reducedImageUrl(20, imgUrl, isMobile);
    }
  } else if (page === "category") {
    if (section === "banner") {
      return reducedImageUrl(60, imgUrl, isMobile);
    }
  } else if (page === "header") {
    if (section === "category") {
      return reducedImageUrl(20, imgUrl, isMobile);
    }
  } else if (page === "all-material-palettes") {
    if (section === "palette-image") {
      return reducedImageUrl(80, imgUrl, isMobile);
    }
  } else if (page === "material-palettes") {
    if (section === "palette-image") {
      return reducedImageUrl(80, imgUrl, isMobile);
    } else if (section === "small-image") {
      return reducedImageUrl(20, imgUrl, isMobile);
    }
  } else if (page === "competiton-submitted-entry") {
    if (section === "small-image") {
      return reducedImageUrl(20, imgUrl, isMobile);
    }
  } else if (page === "competiton-submission-preview") {
    if (section === "small-image") {
      return reducedImageUrl(20, imgUrl, isMobile);
    }
  } else if (page === "all-bundle") {
    if (section === "related-image") {
      return reducedImageUrl(20, imgUrl, isMobile);
    } else if (section === "bundle-image") {
      return reducedImageUrl(20, imgUrl, isMobile);
    }
  } else if (page === "all-category") {
    if (section === "category-image") {
      return reducedImageUrl(40, imgUrl, isMobile);
    }
  } else if (page === "category") {
    if (section === "palette-image") {
      return reducedImageUrl(40, imgUrl, isMobile);
    }
  } else if (page === "all-vendor") {
    if (section === "main-image") {
      return reducedImageUrl(60, imgUrl, isMobile);
    }
  } else if (page === "project") {
    if (section === "board") {
      return reducedImageUrl(40, imgUrl, isMobile);
    }
  } else if (page === "blogs") {
    if (section === "featured") {
      return reducedImageUrl(60, imgUrl, isMobile);
    }
    if (section === "item") {
      return reducedImageUrl(60, imgUrl, isMobile);
    }
  }

  return imgUrl;
};
