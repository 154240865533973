import {
  Button,
  Drawer,
  Form,
  FormInstance,
  Input,
  Select,
  message,
} from "antd";
import React, { useEffect, useState } from "react";

interface OrderLostReasonDrawerProps {
  visible: boolean;
  onFinish: Function;
  onClose: Function;
}
const { Option } = Select;
const lost_options = [
  { id: "1", value: "pricing_issue", label: "Pricing Issue" },
  { id: "2", value: "credit_issue", label: "Credit Issue" },
  { id: "3", value: "order_closed_already", label: "Order Closed Already" },
  { id: "4", value: "cash_non_gst", label: "Cash/Non GST Issue" },
  { id: "5", value: "delayed_estimate", label: "Delayed Estimate" },
  {
    id: "6",
    value: "sample_not_approved",
    label: "Sample/material not approved",
  },
  { id: "7", value: "enquiry_invalid", label: "Enquiry Invalid" },
  { id: "8", value: "enquiry_cancelled", label: "Enquiry Cancelled" },
  {
    id: "9",
    value: "material_not_available",
    label: "Material not available/Discontinued item",
  },
  {
    id: "10",
    value: "transportation_cost",
    label: "Transportation cost",
  },
  {
    id: "11",
    value: "brand_trust_issue",
    label: "Brand Trust issue",
  },
  {
    id: "12",
    value: "lead_time_high",
    label: "Lead time high",
  },
  {
    id: "13",
    value: "delivery_less_6_hours",
    label: "Need delivery in less than 6 hours",
  },
];
const OrderLostReasonDrawer = ({
  visible,
  onFinish,
  onClose,
}: OrderLostReasonDrawerProps) => {
  const formRef = React.useRef<FormInstance>(null);
  const onSubmit = () => {
    if (lost_reason?.length > 0) {
      onFinish({
        ...formRef.current?.getFieldsValue(),
      });
    } else {
      message.warning("Please select ship to address");
    }
  };
  const [lost_reason, setLiveLocationLink] = useState("");
  useEffect(() => {
    formRef.current?.resetFields();
  }, [visible]);
  return (
    <>
      <Drawer
        title="Delivery details"
        placement="right"
        open={visible}
        closable={true}
        onClose={() => {
          onClose();
        }}
        width={600}
        footer={
          <div className="d-flex gap-2">
            <Button onClick={() => onClose(true)}>Cancel</Button>
            <Button type="primary" onClick={onSubmit}>
              Save
            </Button>
          </div>
        }
      >
        <Form layout="vertical" onFinish={onSubmit} ref={formRef}>
          <Form.Item
            name="lost_reason"
            label="Lost reason"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Select reason"
              allowClear
              onChange={(e) => {
                setLiveLocationLink(e);
              }}
            >
              {lost_options?.map((each: any) => {
                return (
                  <Option key={each.value} value={each.value}>
                    {each?.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="remark" label="Remarks">
            <Input.TextArea
              placeholder="Remarks"
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default OrderLostReasonDrawer;
