import { Button, Drawer, Input, Space, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import { fetchVendors } from "../../apis/vendor.api";
import { usePermissions } from "../../custom_hooks/permission/permission_hook";
import { IVendor } from "../../interfaces/vendor.interface";
import VendorAddForm from "./form/add";
import { VendorOptionalContactForm } from "./form/contact";
import { VendorEditForm } from "./form/edit";
import { VendorWarehouseForm } from "./form/warehouse";

const Vendors = () => {
  const { hasPermission } = usePermissions();
  const [vendors, setVendors] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [showVendorDrawer, setShowVendorDrawer] = useState(false);
  const [activeVendorEdit, setActiveVendorEdit] = useState<IVendor | null>(
    null
  );
  const [showDrawer, setShowDrawer] = useState<
    "edit" | "warehouse" | "contacts" | null
  >(null);

  const getVendors = useCallback(() => {
    fetchVendors({ search }).then((res) => {
      setVendors(res);
      setLoading(false);
    });
  }, [search]);

  useEffect(() => {
    getVendors();
  }, []);

  const columns: ColumnsType<IVendor> = [
    {
      title: "Vendor Name",
      key: "name",
      dataIndex: "name",
      fixed: "left",
    },
    {
      title: "Vendor code",
      key: "vendor_code",
      dataIndex: "vendor_code",
    },
    // {
    //   title: "Vendor Categories",
    //   key: "category",
    //   render: (data) => (
    //     <span className="text-capitalize" style={{ fontWeight: "normal" }}>
    //       {data?.category?.map(
    //         (each: any) =>
    //           each?.category_name?.length !== 0 && (
    //             <Tag color="blue">{each?.category_name}</Tag>
    //           )
    //       )}
    //     </span>
    //   ),
    //   width: 180,
    // },
    {
      title: "Contact Person",
      key: "contact_person",
      dataIndex: "contact_person",
    },
    {
      title: "Mobile Number",
      key: "contact",
      dataIndex: "contact",
    },
    {
      title: "Email Id",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Whatsapp group link",
      key: "whatsapp_group_link",
      dataIndex: "whatsapp_group_link",
    },
    {
      title: "Billing Address",
      key: "billingAddress",
      dataIndex: "billing_address",
    },
    {
      title: "Pincode",
      key: "pincode",
      dataIndex: "pincode",
      render: (data) => {
        return String(data).replace(/\..*$/, "");
      },
    },
    {
      title: "Actions",
      key: "action",
      render: (data, record) => (
        <Space>
          {/* <Tag onClick={() => {}} color="blue" role="button">
            See Details
          </Tag> */}
          <Tag
            className={
              hasPermission("vendor.update")
                ? ""
                : "unclickable grey-color-text"
            }
            onClick={() => {
              setActiveVendorEdit(record);
              setShowDrawer("edit");
            }}
            color="cyan"
            role="button"
          >
            Edit
          </Tag>
          <Tag
            className={
              hasPermission("vendor.update")
                ? ""
                : "unclickable grey-color-text"
            }
            onClick={() => {
              setActiveVendorEdit(record);
              setShowDrawer("warehouse");
            }}
            color="green"
            role="button"
          >
            Add / Edit Warehouse
          </Tag>

          <Tag
            className={
              hasPermission("vendor.update")
                ? ""
                : "unclickable grey-color-text"
            }
            onClick={() => {
              setActiveVendorEdit(record);
              setShowDrawer("contacts");
            }}
            color="blue"
            role="button"
          >
            Add / Edit Contacts
          </Tag>
        </Space>
      ),
    },
  ];

  return (
    <div className="mx-3 my-3 px-3 py-2 white-color-bg">
      <div>
        <div className="d-flex justify-content-between">
          <h3>Vendors</h3>
          <div className="d-flex gap-2">
            <Input.Search
              type="text"
              style={{ maxWidth: 180 }}
              placeholder="Search"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onSearch={(value) => {
                fetchVendors({ search: value }).then((res) => {
                  setVendors(res);
                  setLoading(false);
                });
              }}
            />

            <Button
              type="primary"
              disabled={!hasPermission("vendor.create")}
              onClick={() => setShowVendorDrawer(true)}
            >
              Add Vendor
            </Button>
          </div>
        </div>
        <div className="my-2">
          <Table
            columns={columns}
            dataSource={vendors}
            loading={loading}
            rowKey={"id"}
            scroll={{
              x: "100%",
            }}
          />
          {/* <Pagination
            current={page}
            total={total_vendors ?? 1}
            showSizeChanger={false}
            pageSize={10}
            className="mt-3"
            onChange={(p) => {
              getVendors({ page: p });
            }}
          /> */}
        </div>
      </div>
      {/* Add vendor drawer */}
      <Drawer
        open={showVendorDrawer}
        title="Add Vendor"
        onClose={() => setShowVendorDrawer(false)}
      >
        <VendorAddForm
          refresh={getVendors}
          onClose={() => setShowVendorDrawer(false)}
        />
      </Drawer>
      {/* Edit vendor drawer */}
      <Drawer
        open={showDrawer === "edit"}
        title="Edit vendor"
        onClose={() => {
          setActiveVendorEdit(null);
          setShowDrawer(null);
        }}
      >
        {activeVendorEdit !== null && (
          <VendorEditForm
            vendor={activeVendorEdit}
            refresh={getVendors}
            onClose={() => {
              setActiveVendorEdit(null);
              setShowDrawer(null);
            }}
          />
        )}
      </Drawer>
      <Drawer
        open={Boolean(showDrawer) && showDrawer !== "edit"}
        title={`Add / Edit ${showDrawer}`}
        onClose={() => {
          setActiveVendorEdit(null);
          setShowDrawer(null);
        }}
      >
        {showDrawer === "contacts" && activeVendorEdit !== null && (
          <VendorOptionalContactForm
            vendor={activeVendorEdit}
            onClose={() => {
              setActiveVendorEdit(null);
              setShowDrawer(null);
            }}
          />
        )}
        {showDrawer === "warehouse" && activeVendorEdit !== null && (
          <VendorWarehouseForm
            vendor={activeVendorEdit}
            onClose={() => {
              setActiveVendorEdit(null);
              setShowDrawer(null);
            }}
          />
        )}
      </Drawer>
    </div>
  );
};

export default Vendors;
