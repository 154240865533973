import axios from "axios";

export const sendOtp = async (phone: string) => {
  return axios
    .get(
      `https://api-dev2.materialdepot.in/apiV1/login-otp/?contact=${phone}&country_code=91`,
      {
        headers: {
          Authorization: "",
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        return false;
      }
    })
    .catch((res) => {
      return false;
    });
};

export const verifyOtp = async (phone: string, otp: string) => {
  return axios
    .get(
      `https://api-dev2.materialdepot.in/apiV1/verify-otp-mysite/?contact=${phone}&otp=${otp}`,
      {
        headers: {
          Authorization: "",
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        localStorage.setItem("token", res?.data?.token);
        window.location.href = `/`;
        return res.data;
      } else {
        return false;
      }
    })
    .catch((res) => {
      return false;
    });
};
