import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { ChargeTypeChoices } from "../../po_create/po_additional_charges";

interface POEditAdditionalChargesProps {
  setData: Function;
  data: any;
}

const POEditAdditionalCharges = ({
  setData,
  data,
}: POEditAdditionalChargesProps) => {
  const { TextArea } = Input;

  const { Option } = Select;
  const taxTypes: Array<any> = [
    {
      id: 1,
      label: "None",
      value: "none",
    },
    {
      id: 2,
      label: "CGST/SGST",
      value: "cgst_sgst",
    },
    {
      id: 4,
      label: "IGST",
      value: "igst",
    },
  ];
  const [formList, setFormList] = useState<Array<any>>([
    {
      charge_type: "Transport Charges",
      amount: 0,
      tax_value: 18,
      tax_type: "cgst_sgst",
      total: 0,
    },
  ]);

  const onFinish = (values: any) => {
    console.log("Received values of form:", values);
  };
  const onAdd = () => {
    setFormList([
      ...formList,
      {
        charge_type: "Additional Charge",
        amount: 0,
        tax_value: 18,
        tax_type: "cgst_sgst",
        total: 0,
      },
    ]);
  };
  const onRemove = (index: number) => {
    var arr = [...formList];
    arr.splice(index, 1);
    setFormList(arr);
  };
  const onChangeInput = (value: string, index: number, name: string) => {
    var arr = formList;
    arr[index][name] = value;
    var amount = arr[index]["amount"];
    var percentage = arr[index].tax_value;
    var final_amount = Number(
      Number(amount) + amount * percentage * 0.01
    ).toFixed(2);
    arr[index]["total"] = Number(final_amount);
    setFormList([...arr]);
    // setPOEditAdditionalCharges([...arr]);
    console.log(arr);
  };
  useEffect(() => {
    setData("po_additional_charges", formList);
  }, [formList]);

  useEffect(() => {
    if (data?.po_additionals?.length != formList?.length) {
      setFormList(data?.po_additional_charges ?? []);
    }
  }, [data]);

  return (
    <React.Fragment>
      <div className="my-2">
        <hr />
      </div>
      <Form
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
      >
        {formList?.map((each: any, index) => (
          <div
            key={index.toString()}
            style={{
              marginBottom: 8,
              background: "#F9F8F6",
              borderRadius: 8,
              padding: 2,
            }}
            // align="baseline"
          >
            <Space
              style={{
                display: "flex",
              }}
            >
              <Form.Item>
                {/* <Input
                  onChange={(e) => {
                    onChangeInput(e.target.value, index, "charge_type");
                  }}
                  value={each?.charge_type ?? ""}
                  placeholder="Charge"
                /> */}

                <Select
                  onChange={(e) => {
                    onChangeInput(e, index, "charge_type");
                  }}
                  value={each.charge_type}
                  placeholder="Select charge type"
                >
                  {ChargeTypeChoices?.map((obj) => (
                    <Option key={obj} value={obj}>
                      {obj}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Select
                  className="w-100"
                  onChange={(e) => {
                    onChangeInput(e, index, "tax_type");
                  }}
                  placeholder="Select tax type"
                  value={each?.tax_type}
                >
                  {taxTypes?.map((obj) => (
                    <Option key={obj?.id} value={obj?.value}>
                      {obj?.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item>
                <Select
                  className="w-100"
                  onChange={(e) => {
                    onChangeInput(e, index, "tax_value");
                  }}
                  placeholder="Select tax value"
                  value={each?.tax_value}
                >
                  {[0, 5, 12, 18, 28]?.map((obj) => (
                    <Option key={obj} value={obj}>
                      {obj}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Input
                  value={each?.amount ?? 0}
                  // formatter={(value: any) => `₹ ${value}`}
                  // parser={(value: string) => value.replace("₹", "")}
                  onChange={(e) => {
                    onChangeInput(e.target.value, index, "amount");
                  }}
                  placeholder="Amount"
                />
              </Form.Item>
              <Form.Item>
                <Input disabled value={"₹ " + each?.total ?? 0} />
              </Form.Item>
              {/* <MinusCircleOutlined
                onClick={() => {
                  onRemove(index);
                }}
              /> */}
            </Space>

            <Form.Item>
              <TextArea
                value={each?.remark ?? ""}
                // formatter={(value: any) => `₹ ${value}`}
                // parser={(value: string) => value.replace("₹", "")}
                onChange={(e) => {
                  onChangeInput(e.target.value, index, "remark");
                }}
                style={{ fontSize: 12 }}
                placeholder="Remarks"
              />
            </Form.Item>
          </div>
        ))}
        <Form.Item className="">
          <Button
            type="dashed"
            onClick={() => {
              onAdd();
            }}
            block
            icon={<PlusOutlined />}
          >
            Additional Charges
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default POEditAdditionalCharges;
