export function convertNumberToWords(number: number): string {
  if (!number) {
    return "";
  }
  const numbersToWords: { [key: number]: string } = {
    0: "Zero",
    1: "One",
    2: "Two",
    3: "Three",
    4: "Four",
    5: "Five",
    6: "Six",
    7: "Seven",
    8: "Eight",
    9: "Nine",
    10: "Ten",
    11: "Eleven",
    12: "Twelve",
    13: "Thirteen",
    14: "Fourteen",
    15: "Fifteen",
    16: "Sixteen",
    17: "Seventeen",
    18: "Eighteen",
    19: "Nineteen",
    20: "Twenty",
    30: "Thirty",
    40: "Forty",
    50: "Fifty",
    60: "Sixty",
    70: "Seventy",
    80: "Eighty",
    90: "Ninety",
  };

  if (number in numbersToWords) {
    return numbersToWords[number];
  }

  let words = "";

  if (number < 100) {
    words += numbersToWords[Math.floor(number / 10) * 10];
    if (number % 10 !== 0) {
      words += "-" + numbersToWords[number % 10];
    }
  } else if (number < 1000) {
    words += numbersToWords[Math.floor(number / 100)] + " Hundred";
    if (number % 100 !== 0) {
      words += " " + convertNumberToWords(number % 100);
    }
  } else if (number < 100000) {
    words += convertNumberToWords(Math.floor(number / 1000)) + " Thousand";
    if (number % 1000 !== 0) {
      words += " " + convertNumberToWords(number % 1000);
    }
  } else if (number < 10000000) {
    words += convertNumberToWords(Math.floor(number / 100000)) + " Lakh";
    if (number % 100000 !== 0) {
      words += " " + convertNumberToWords(number % 100000);
    }
  } else {
    words += convertNumberToWords(Math.floor(number / 10000000)) + " Crore";
    if (number % 10000000 !== 0) {
      words += " " + convertNumberToWords(number % 10000000);
    }
  }

  return words;
}
