import { Button, Form, Select } from "antd";

export interface DispatchTimeChangeReasonDrawerProps {
  onFinish: (values: any) => unknown;
}

/*
Reasons to update dispatch time:
1. Material not ready
2. Vehicles not available
3. Customer Requested rescheduling
4. Accounting/payment issues
5. Transit Delay (Outstation)
6. Others
*/

export function DispatchTimeChangeReasonDrawer({
  onFinish,
}: DispatchTimeChangeReasonDrawerProps) {
  return (
    <Form onFinish={onFinish} layout="vertical">
      <Form.Item
        name="dispatch_change_reason"
        label="Reason for changing dispatch time"
        required
      >
        <Select
          placeholder="Select a reason"
          allowClear
          options={[
            { label: "Material not ready", value: "material_not_ready" },
            {
              label: "Vehicles not available",
              value: "vehicles_not_available",
            },
            {
              label: "Customer Requested rescheduling",
              value: "customer_requested_rescheduling",
            },
            {
              label: "Accounting/payment issues",
              value: "accounting_payment_issues",
            },
            {
              label: "Transit Delay (Outstation)",
              value: "transit_delay_outstation",
            },
            { label: "Others", value: "others" },
          ]}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
