import { Table } from "antd";
import CartConsolidation from "../cart_consolidation";

interface ClientCartTableProps {
  cart_items: Array<any>;
  createEstimate: Function;
  loading: boolean;
  setLoading: Function;
}

const ClientCartTable = ({
  cart_items,
  createEstimate,
  loading,
  setLoading,
}: ClientCartTableProps) => {
  const columns = [
    {
      title: "S.no",
      dataIndex: "index",
      render: (value: any, data: any, index: number) => <>{index + 1}</>,
    },
    {
      title: "Name",
      key: "name",
      render: (data: any) => <>{data?.variant?.product_name}</>,
    },
    {
      title: "Brand",
      key: "brand_name",
      render: (data: any) => <>{data?.variant?.product?.brand?.brand_name}</>,
    },
    {
      title: "Category",
      key: "category_name",
      render: (data: any) => (
        <>{data?.variant?.product?.category?.category_name}</>
      ),
    },
    {
      title: "Quantity",
      key: "quantity",
      render: (data: any) => <>{data?.quantity}</>,
    },
    {
      title: "Price",
      key: "price",
      render: (data: any) => (
        <>
          {" "}
          ₹ {Number(data?.variant?.md_price_gst)} x {Number(data?.quantity)}
        </>
      ),
    },
    {
      title: "Total price",
      key: "total_price",
      render: (data: any) => (
        <>
          {" "}
          ₹ {Number(data?.variant?.md_price_gst * data?.quantity)?.toFixed(2)}
        </>
      ),
    },
  ];
  return (
    <div className="px-1 position-relative">
      <Table
        columns={columns}
        dataSource={cart_items}
        pagination={false}
        // loading={loading}
        rowKey={"id"}
        scroll={{
          x: "100%",
        }}
      />
      <div className="row py-3">
        <div className="col"></div>
        <div className="col">
          {" "}
          <CartConsolidation cart_items={cart_items} />
        </div>
      </div>
    </div>
  );
};

export default ClientCartTable;
