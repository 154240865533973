import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LayoutRoutes from "./layout_routes";
import Login from "../pages/auth/login";

const Routers = () => {
  return (
    <BrowserRouter basename={"/"}>
      <>
        <Suspense>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path={`/*`} element={<LayoutRoutes />} />

            {/* <Route element={<WebsiteLayout />}>
              {routes.map(({ path, Component }, i) => (
                <Route key={i} path={path} element={Component} />
              ))}
            </Route> */}
          </Routes>
        </Suspense>
      </>
    </BrowserRouter>
    // </Auth0Provider>
  );
};

export default Routers;
