import { CarFilled, PlusOutlined } from "@ant-design/icons";

import {
  Button,
  DatePicker,
  Drawer,
  Form,
  FormInstance,
  Input,
  Select,
  Space,
  Tag,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { fetchClientAddresses } from "../../../apis/client.api";
import AddClientAddressForm from "../../client/add_client_address_form";
import { range } from "../../client/add_client_booking_form";
interface DeliveryAddressDrawerProps {
  data: any;
  visible: boolean;
  onFinish: Function;
  onClose: Function;
}
const { Option } = Select;

const DeliveryAddressDrawer = ({
  data,
  visible,
  onFinish,
  onClose,
}: DeliveryAddressDrawerProps) => {
  const formRef = React.useRef<FormInstance>(null);
  const onSubmit = () => {
    // if (ship_to_address) {
    console.log("--->", formRef.current?.getFieldsValue());
    onFinish({
      ...formRef.current?.getFieldsValue(),
      expected_delivery_time: moment(
        formRef.current?.getFieldValue("expected_delivery_time")
      ).format("YYYY-MM-DD"),
    });
    // } else {
    //   message.warning("Please select ship to address");
    // }
  };
  const [client_add_address_drawer_open, setClientAddAddressDrawerOpen] =
    useState(false);
  const [client_addresses, setClientAddresses] = useState<Array<any>>([]);
  const [ship_to_address, setShipAddress] = useState<any>(null);
  const [bill_to_address, setBillAddress] = useState<any>(null);
  const fetchAddresses = () => {
    if (data) {
      fetchClientAddresses(data?.client?.id ?? data?.client).then((res) => {
        setClientAddresses(res);
      });
    }
  };

  const onClientAddAddressDrawerClose = () => {
    setClientAddAddressDrawerOpen(false);
  };
  useEffect(() => {
    if (data?.client) {
      fetchAddresses();
    }
    formRef.current?.setFieldsValue({
      ...data,
      expected_delivery_time: moment(data?.expected_delivery_time ?? null),
    });
    console.log("data", data);
  }, [data]);

  useEffect(() => {
    if (data?.bill_address) {
      setBillAddress(
        client_addresses.find((item) => item.id == data?.bill_address)
      );
    }
    if (data?.ship_address) {
      setShipAddress(
        client_addresses.find((item) => item.id == data?.ship_address)
      );
    }
  }, [client_addresses]);
  return (
    <>
      <Drawer
        title="Delivery details"
        placement="right"
        open={visible}
        closable={true}
        onClose={() => {
          onClose();
        }}
        width={600}
        footer={
          <Space className="d-flex justify-content-between">
            <div className="d-flex gap-2">
              <Button onClick={() => onClose(true)}>Cancel</Button>
              <Button type="primary" onClick={onSubmit}>
                Save
              </Button>
            </div>
            <div>
              <Button
                className="d-flex align-items-center"
                onClick={() => {
                  setClientAddAddressDrawerOpen(true);
                }}
              >
                <PlusOutlined /> Add new address
              </Button>
            </div>
          </Space>
        }
      >
        <Form layout="vertical" onFinish={onSubmit} ref={formRef}>
          <Form.Item
            name="expected_delivery_time"
            label="Expected Delivery Time"
            rules={[{ required: true }]}
          >
            <DatePicker
              style={{ width: "100%" }}
              showTime={{
                minuteStep: 30,
                format: "HH:mm",
                hideDisabledOptions: true,
              }}
              disabledTime={(current: any) => {
                return {
                  disabledHours: () => range(0, 10).concat(range(21, 24)),
                };
              }}
            />
          </Form.Item>
          <Form.Item name="remarks" label="Remarks">
            <Input.TextArea />
          </Form.Item>
          {!data?.is_store_pickup ? (
            <div className="border border-1 p-3 rounded mb-4">
              <Form.Item
                name="ship_address"
                label="Ship to Address"
                rules={[{ required: data?.is_store_pickup ? false : true }]}
              >
                <Select
                  placeholder="Select ship to address"
                  allowClear
                  onChange={(e) => {
                    setShipAddress(
                      client_addresses.find((item) => item.id == e)
                    );
                  }}
                >
                  {client_addresses?.map((each: any) => {
                    return (
                      <Option key={each.id} value={each.id}>
                        {each?.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <div className="mt-3 row">
                <div className="col-4">Name</div>
                <div className="col-8 d-flex">
                  <div>:</div>
                  <div className="w-100 px-2">
                    {ship_to_address?.name ?? ""}
                  </div>
                </div>
              </div>
              <div className="mt-3 row">
                <div className="col-4">Selected address</div>
                <div className="col-8 d-flex">
                  <div>:</div>
                  <div className="w-100 px-2">
                    {ship_to_address?.address}, {ship_to_address?.city}{" "}
                    {ship_to_address?.pincode}
                  </div>
                </div>
              </div>
              <div className="mt-3 row">
                <div className="col-4">Contact person</div>
                <div className="col-8 d-flex">
                  <div>:</div>
                  <div className="w-100 px-2">
                    {ship_to_address?.contact_person_name}
                  </div>
                </div>
              </div>
              <div className="mt-3 row">
                <div className="col-4">Contact number</div>
                <div className="col-8 d-flex">
                  <div>:</div>
                  <div className="w-100 px-2">
                    {ship_to_address?.contact_person_contact}
                  </div>
                </div>
              </div>
              <div className="mt-3 row">
                <div className="col-4">GST</div>
                <div className="col-8 d-flex">
                  <div>:</div>
                  <div className="w-100 px-2">{ship_to_address?.gst_no}</div>
                </div>
              </div>
              <div className="mt-3 row">
                <div className="col-4">PAN No.</div>
                <div className="col-8 d-flex">
                  <div>:</div>
                  <div className="w-100 px-2">{ship_to_address?.pan_no}</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="py-3">
              <Tag
                style={{ cursor: "pointer" }}
                color="green"
                className="th-font-size-16 th-font-weight-600 p-2 px-3 d-flex justify-content-center"
              >
                <CarFilled className="th-font-size-22 me-2" />
                Store Pickup
              </Tag>
            </div>
          )}

          <div className="border border-1 p-3 rounded">
            <Form.Item name="bill_address" label="Bill to Address">
              <Select
                placeholder="Select bill to address"
                allowClear
                onChange={(e) => {
                  setBillAddress(client_addresses.find((item) => item.id == e));
                }}
              >
                {client_addresses?.map((each: any) => {
                  return (
                    <Option key={each.id} value={each.id}>
                      {each?.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <div className="mt-3 row">
              <div className="col-4">Name</div>
              <div className="col-8 d-flex">
                <div>:</div>
                <div className="w-100 px-2">{bill_to_address?.name ?? ""}</div>
              </div>
            </div>
            <div className="mt-3 row">
              <div className="col-4">Selected address</div>
              <div className="col-8 d-flex">
                <div>:</div>
                <div className="w-100 px-2">
                  {bill_to_address?.address}, {bill_to_address?.city}{" "}
                  {bill_to_address?.pincode}
                </div>
              </div>
            </div>
            <div className="mt-3 row">
              <div className="col-4">Contact person</div>
              <div className="col-8 d-flex">
                <div>:</div>
                <div className="w-100 px-2">
                  {bill_to_address?.contact_person_name}
                </div>
              </div>
            </div>
            <div className="mt-3 row">
              <div className="col-4">Contact number</div>
              <div className="col-8 d-flex">
                <div>:</div>
                <div className="w-100 px-2">
                  {bill_to_address?.contact_person_contact}
                </div>
              </div>
            </div>
            <div className="mt-3 row">
              <div className="col-4">GST</div>
              <div className="col-8 d-flex">
                <div>:</div>
                <div className="w-100 px-2">{bill_to_address?.gst_no}</div>
              </div>
            </div>
            <div className="mt-3 row">
              <div className="col-4">PAN No.</div>
              <div className="col-8 d-flex">
                <div>:</div>
                <div className="w-100 px-2">{bill_to_address?.pan_no}</div>
              </div>
            </div>
          </div>
        </Form>
      </Drawer>
      <Drawer
        title="Add Address"
        placement="right"
        onClose={onClientAddAddressDrawerClose}
        open={client_add_address_drawer_open}
        drawerStyle={{ height: "100%" }}
        width={500}
      >
        <AddClientAddressForm
          user_id={data?.client?.id ?? data?.client}
          onClientAddAddressDrawerClose={onClientAddAddressDrawerClose}
          fetchAddresses={fetchAddresses}
        />
      </Drawer>
    </>
  );
};

export default DeliveryAddressDrawer;
