import { Form, Input, Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchBranchTerms } from "../../../../apis/branch.api";

interface PFITermsAndConditionsSelectProps {
  branch_data: any;
  setData: Function;
  data: any;
}

const PFITermsAndConditionsSelect = ({
  branch_data,
  setData,
  data,
}: PFITermsAndConditionsSelectProps) => {
  // const { defaultTC, defaultTCDesc } = props;
  const { Option } = Select;
  const [terms, setTerms] = useState<Array<any>>([]);
  useEffect(() => {
    if (branch_data?.id) {
      fetchBranchTerms(branch_data?.id).then((res) => {
        setTerms(res);
      });
    }
  }, [branch_data]);
  // useEffect(() => {
  //   console.log("defaultTC", defaultTC, defaultTCDesc);
  //   if (terms.length > 0 && !defaultTC) {
  //     props.handlePFITermsandConditionsSelect({
  //       id: terms[0]?.id,
  //       terms_and_conditions_description: terms[0]?.description,
  //     });
  //   }
  // }, [defaultTC, defaultTCDesc, terms]);

  const termsOptions = terms?.map((each) => (
    <Option key={each.id} value={each.id}>
      {each?.title}
    </Option>
  ));

  return (
    <React.Fragment>
      <div className="">
        <h6>Terms and Conditions</h6>
        <Form.Item>
          <Select
            className="w-100"
            // key={defaultTC}
            value={data?.terms_and_condition?.id ?? data?.terms_and_condition}
            onChange={(e) => {
              const termSelected = terms?.find((term) => term?.id == e);

              setData("terms_and_condition", termSelected?.id);
              setData(
                "terms_and_conditions_description",
                termSelected?.description
              );
            }}
          >
            {termsOptions}
          </Select>
          <div className="mt-2">
            <Input.TextArea
              autoSize={{
                minRows: 4,
                maxRows: 20,
              }}
              key="description_terms"
              placeholder="Add Terms and Conditions here"
              value={data?.terms_and_conditions_description}
              onChange={(e) => {
                setData("terms_and_conditions_description", e.target.value);
              }}
            />
          </div>
        </Form.Item>
      </div>
    </React.Fragment>
  );
};

export default PFITermsAndConditionsSelect;
