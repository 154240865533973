import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  Pagination,
  Result,
  Select,
  Space,
  Spin,
} from "antd";
import React from "react";
import { fetchOpenSearchProducts, getLabel } from "../../../apis/product.api";
import styles from "./index.module.scss";
import ProductDrawer from "./product_drawer";
import { RateContractProductCard } from "./rate_contract_product_div";

interface IRateContracts {
  added: Array<{
    handle: string;
    quantity: string;
  }>;
  customOnAdd: Function;
  narrow: boolean;
  onQuantityChange: (handle: string, quantity: string) => unknown;
}

const RateContracts = ({
  added = [],
  customOnAdd,
  narrow = false,
  onQuantityChange,
}: IRateContracts) => {
  const [search, setSearch] = React.useState("");
  const [products, setProducts] = React.useState([]);
  const [filterOptions, setFilterOptions] = React.useState<any>({});
  const [subFilterOptions, setSubFilterOptions] = React.useState<any>({});
  const [filterValues, setFilterValues] = React.useState<any>({});
  const [subFilterValues, setSubFilterValues] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(30);
  const [pages, setPages] = React.useState(0);
  const [sortBy, setSortBy] = React.useState("relevance");
  const [showFilterPopup, setShowFilterPopup] = React.useState(false);
  const [lastChangedFilter, setLastChangedFilter] = React.useState<string>();
  const [lastChangedSubFilter, setLastChangedSubFilter] = React.useState(null);
  const [showBudgetAddModal, setShowBudgetAddModal] = React.useState(false);
  const [budgetItemProduct, setBudgetItemProduct] = React.useState(null);
  const [itemQuantity, setItemQuantity] = React.useState(1);
  const [itemProject, setItemProject] = React.useState(null);
  const [showItemDrawer, setShowItemDrawer] = React.useState(false);
  const [itemProduct, setItemProduct] = React.useState(null);
  const [projects, setProjects] = React.useState<Array<any>>([]);
  const [userType, setUserType] = React.useState("studio");
  const [mdView, setMdView] = React.useState(true);
  const [fetchTimeout, setFetchTimeout] = React.useState(null);
  const [pageChanged, setPageChanged] = React.useState(false);

  const [view, setView] = React.useState("grid");

  const containerRef = React.useRef<any>(null);

  const handleAggregations = React.useCallback(
    (aggregations: any) => {
      const newFilterOptions: any = {};
      Object.keys(aggregations).forEach((key) => {
        if (
          key === lastChangedFilter &&
          aggregations[key].length < filterOptions[key]?.length
        ) {
          newFilterOptions[key] = filterOptions[key];
        } else {
          newFilterOptions[key] = aggregations[key];
        }
      });
      setFilterOptions({
        ...filterOptions,
        ...newFilterOptions,
      });
    },
    [filterOptions, lastChangedFilter]
  );

  const handleSubAggregations = React.useCallback(
    (subAggregations: any) => {
      const newFilterOptions: any = {};
      Object.keys(subAggregations).forEach((key) => {
        if (
          key === lastChangedSubFilter &&
          subAggregations[key].length < subFilterOptions[key].length
        ) {
          newFilterOptions[key] = subFilterOptions[key];
        } else {
          newFilterOptions[key] = subAggregations[key];
        }
      });
      setSubFilterOptions(newFilterOptions);
    },
    [subFilterOptions, lastChangedSubFilter]
  );

  const getProducts = React.useCallback(async () => {
    try {
      setLoading(true);
      let page = pageNumber - 1;
      if (!pageChanged) {
        setPageNumber(1);
        page = 0;
      } else {
        setPageChanged(false);
      }
      const response = await fetchOpenSearchProducts(
        search,
        {
          ...filterValues,
          ...subFilterValues,
        },
        page,
        pageSize,
        sortBy,
        userType,
        mdView
      );
      setProducts(response?.product);
      handleAggregations(response?.aggregations);
      handleSubAggregations(response?.sub_aggregations);
      setPages(response?.total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [
    search,
    filterValues,
    subFilterValues,
    pageNumber,
    pageSize,
    sortBy,
    handleAggregations,
    userType,
    mdView,
  ]);

  React.useEffect(() => {
    getProducts();
  }, [
    filterValues,
    subFilterValues,
    pageNumber,
    pageSize,
    sortBy,
    userType,
  ]);

  const handleSearch = React.useCallback(
    (e: any) => {
      e.preventDefault();
      getProducts();
    },
    [getProducts]
  );

  const onAdd = React.useCallback(
    (product: any) => {
      if (customOnAdd) {
        if (
          added?.find((item) => {
            return product?.variant_handle == item.handle;
          })
        ) {
          // Remove
          customOnAdd(product, true);
          return;
        }

        customOnAdd(product);
        return;
      }

      setShowBudgetAddModal(true);
      setBudgetItemProduct(product);
    },
    [added, customOnAdd]
  );

  const onOpen = React.useCallback((product: any) => {
    setShowItemDrawer(true);
    setItemProduct(product);
  }, []);

  const addToBudget = React.useCallback(() => {
    setShowBudgetAddModal(false);
  }, []);

  const sortOptions = React.useRef([
    {
      value: "relevance",
      label: "Relevance",
    },
    // {
    //   value: "newest",
    //   label: "Newest",
    // },
    {
      value: "price-low-high",
      label: "Price Low to High",
    },
    {
      value: "price-high-low",
      label: "Price High to Low",
    },
    {
      value: "a-z",
      label: "A-Z",
    },
    {
      value: "z-a",
      label: "Z-A",
    },
  ]);

  const getFilterSelect = React.useCallback(
    (key: string) => {
      return (
        <Select
          key={key}
          className={styles.select}
          mode="multiple"
          placeholder={getLabel(key)}
          value={filterValues[key] ?? []}
          onChange={(value) => {
            setFilterValues({
              ...filterValues,
              [key]: value,
            });
            setLastChangedFilter(key);
          }}
        >
          {filterOptions[key].map((option: any) => (
            <Select.Option key={option.key} value={option.key}>
              {option.key} ({option.doc_count})
            </Select.Option>
          ))}
        </Select>
      );
    },
    [filterOptions, filterValues]
  );

  const convertSubFilterKeyToLabel = React.useCallback((key: string) => {
    // Remove the property_ prefix and normalize the camel case to spaces
    // with the first letter capitalized
    return key
      .replace("property_", "")
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str: string) => str.toUpperCase());
  }, []);

  const getSubFilterSelect = React.useCallback(
    (key: any) => {
      return (
        <Select
          key={key}
          className={styles.select}
          mode="multiple"
          placeholder={convertSubFilterKeyToLabel(key)}
          value={subFilterValues[key] ?? []}
          onChange={(value) => {
            setSubFilterValues({
              ...subFilterValues,
              [key]: value,
            });
            setLastChangedSubFilter(key);
          }}
        >
          {subFilterOptions[key].map((option: any) => (
            <Select.Option key={option.key} value={option.key}>
              {option.key} ({option.doc_count})
            </Select.Option>
          ))}
        </Select>
      );
    },
    [subFilterOptions, subFilterValues, convertSubFilterKeyToLabel]
  );

  // const getPrice = React.useCallback(
  //   async (product) => {
  //     if (!mdView) {
  //       return product?.md_price_gst;
  //     }
  //     if (userType === "studio" || mdView) return product?.md_price_gst_studio;
  //     if (userType === "enterprise") return product?.md_price_gst_msp;
  //   },
  //   [userType, mdView]
  // );

  // const getLandingPrice = React.useCallback(
  //   async (product) => {
  //     if (!mdView) {
  //       return product?.md_landing_price_gst;
  //     }
  //     if (userType === "studio" || mdView)
  //       return product?.md_landing_price_gst_studio;
  //     if (userType === "enterprise") return product?.md_landing_price_gst_msp;
  //   },
  //   [userType, mdView]
  // );

  // const tableColumns = React.useMemo(
  //   () => [
  //     {
  //       title: "Name",
  //       dataIndex: "product_name",
  //       key: "product_name",
  //     },
  //     {
  //       title: "Category",
  //       key: "category",
  //       render: (record) => {
  //         return record?.category?.category_name;
  //       },
  //     },
  //     {
  //       title: "Brand",
  //       key: "brand",
  //       render: (record) => {
  //         return record?.brand?.brand_name;
  //       },
  //     },
  //     {
  //       title: "MSP",
  //       key: "msp",
  //       render: (record) => {
  //         return (
  //           <div>
  //             <div>
  //               ₹ {getPrice(record)}
  //               {record?.price_unit ? "/" + record?.price_unit : ""}
  //             </div>
  //             {getLandingPrice(record) && (
  //               <div
  //                 style={{
  //                   display: "flex",
  //                   fontSize: "75%",
  //                   color: "#535353",
  //                 }}
  //               >
  //                 ₹{getLandingPrice(record)}/{record.md_landing_price_unit}
  //               </div>
  //             )}
  //           </div>
  //         );
  //       },
  //       width: 170,
  //     },
  //     {
  //       title: "Action",
  //       key: "action",
  //       render: (record) => {
  //         return (
  //           <Space>
  //             <Tag color="blue" onClick={() => onAdd(record)}>
  //               Add to Budget
  //             </Tag>
  //             <Tag color="green" onClick={() => onOpen(record)}>
  //               View
  //             </Tag>
  //           </Space>
  //         );
  //       },
  //     },
  //   ],
  //   [getLandingPrice, getPrice, onAdd, onOpen]
  // );

  const getView = React.useMemo(() => {
    if (loading) {
      return (
        <div className={styles.loadingContainer}>
          <Spin size="large" />
        </div>
      );
    }
    if (view === "grid") {
      return (
        <>
          <div className={`${styles.grid} ${narrow && styles.narrow}`}>
            {products?.map((product: any) => (
              <RateContractProductCard
                key={product?.id}
                product={product}
                onAdd={onAdd}
                onClick={onOpen}
                userType={userType}
                mdView={mdView}
                addedArr={added}
                onQuantityChange={onQuantityChange}
              />
            ))}
          </div>
          {products?.length == 0 && (
            <>
              <Result
                status="404"
                title="We couldn't find any matches!"
                subTitle="Please check the spelling or try searching something else"
              />
            </>
          )}
        </>
      );
    }
    // else {
    //   return (
    //     <Table
    //       columns={tableColumns}
    //       dataSource={products}
    //       rowKey="id"
    //       pagination={false}
    //     />
    //   );
    // }
  }, [loading, view, products, added, narrow, onAdd, onOpen, userType, mdView]);

  const handleResetFilters = React.useCallback(() => {
    setSubFilterValues({});
    setFilterValues({});
    setSearch("");
  }, []);

  return (
    <div ref={containerRef}>
      <div>
        <div>
          <h5>All Rate Contracts</h5>
          <div className={styles.headerActions}>
            <form
              onSubmit={(e) => {
                handleSearch(e);
              }}
            >
              <Space>
                <Input
                  placeholder="Search"
                  value={search}
                  className={styles.search_input}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <Button type="primary" htmlType="submit">
                  <SearchOutlined />
                </Button>
                <Button onClick={() => setShowFilterPopup((p) => !p)}>
                  <FilterOutlined />
                </Button>
                {filterOptions["category.category_name"] &&
                  !narrow &&
                  getFilterSelect("category.category_name")}
                {filterOptions["brand.brand_name"] &&
                  !narrow &&
                  getFilterSelect("brand.brand_name")}
                <Select
                  className={`${styles.select} && ${
                    narrow && styles.narrowSort
                  }`}
                  placeholder="Sort By"
                  value={sortBy}
                  onChange={(value) => {
                    setSortBy(value);
                  }}
                >
                  {sortOptions.current.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
                <Button onClick={handleResetFilters}>Reset filters</Button>
              </Space>
            </form>
            {/* {!narrow && (
              <Space>
                <Radio.Group
                  onChange={(v) => setView(v.target.value)}
                  value={view}
                  buttonStyle="solid"
                >
                  <Radio.Button value="grid">Grid</Radio.Button>
                  <Radio.Button value="list">List</Radio.Button>
                </Radio.Group>
              </Space>
            )} */}
          </div>
          <div className={styles.filters}>
            {showFilterPopup &&
              Object.keys(filterOptions)
                .filter(
                  (key) =>
                    !["category.category_name", "brand.brand_name"].includes(
                      key
                    ) || narrow
                )
                .map((key) => getFilterSelect(key))}
            {showFilterPopup &&
              Object.keys(subFilterOptions).map((key) =>
                getSubFilterSelect(key)
              )}
          </div>
          {getView}
          <section
            className={`${styles.pagination} ${narrow && styles.narrow}`}
          >
            <Pagination
              current={pageNumber}
              pageSize={pageSize}
              total={pages}
              onChange={(page, pageSize) => {
                setPageNumber(page);
                setPageSize(pageSize);
                setPageChanged(true);
                // Scroll to top
                containerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
              }}
              showLessItems={narrow}
              showSizeChanger={!narrow}
            />
          </section>
        </div>
      </div>
      <Modal
        title="Add item to budget"
        visible={showBudgetAddModal}
        onCancel={() => setShowBudgetAddModal(false)}
        onOk={addToBudget}
      >
        <Form
          name="basic"
          labelCol={{
            span: 4,
          }}
          style={{
            margin: "0 20px",
          }}
        >
          <Form.Item label="Quantity" name="quantity">
            <Input
              placeholder="Quantity..."
              onChange={(e: any) => {
                setItemQuantity(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item label="Project" name="project">
            <Select
              placeholder="Select project"
              value={projects && projects[0] ? projects[0]?.id : null}
              onChange={(e) => {
                setItemProject(e);
              }}
            >
              {projects?.map((project: any) => (
                <Select.Option key={project.id} value={project.id}>
                  {project?.project_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <ProductDrawer
        product={itemProduct}
        visible={showItemDrawer}
        onClose={() => setShowItemDrawer(false)}
      />
    </div>
  );
};

export default RateContracts;
