import { Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { editSlot } from "../../../apis/client.api";

export interface BookingRemarkFormProps {
  status?: string;
  id: number;
  remarks: string | null;
  onFinish: () => void;
}

export function BookingRemarkForm({ id, status, remarks, onFinish }: BookingRemarkFormProps) {
  const [remarksJSON, setRemarksJSON] = useState<Array<Record<string, any>>>([]);

  useEffect(() => {
    if (!remarks) {
      setRemarksJSON([]);
    } else {
      try {
        setRemarksJSON(JSON.parse(remarks));
      } catch (e) {
        setRemarksJSON([]);
      }
    }
  }, [remarks])

  const onSubmit = async (values: any) => {
    const date = new Date();
    const newRemark = {
      ...values,
      date: date.toISOString(),
      status,
    };

    const newRemarks = [...remarksJSON, newRemark];

    try {
      await editSlot({
        id: id,
        remarks: JSON.stringify(newRemarks),
      });
    } catch (e) {
      console.error(e);
    } finally {
      onFinish();
    }
  };

  return (
    <Form onFinish={onSubmit} layout="vertical">
      <Form.Item label="Remarks" name="value">
        <Input.TextArea placeholder="Remark" rows={4} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}
