import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import moment from "moment";

dayjs.extend(relativeTime);

export const getFormattedDate = (date: string) => {
  const formattedDate =
    // moment(date).format("ddd") +
    // " " +
    moment(date).format("MMM") +
    " " +
    moment(date).format("DD") +
    " " +
    moment(date).format("YYYY");

  return formattedDate;
};

export const getFormattedDateAndTime = (datetime: string) => {
  if (!datetime) {
    return "";
  }
  // If date is before 22-03-2024, use it as utc input otherwise use it as local input
  const date = new Date(datetime);
  if (date < new Date("2024-03-22")) {
    return moment.utc(datetime).format("lll");
  } else {
    return moment(datetime).format("lll");
  }
  // if (datetime) {
  //   return date;
  //   // let date_number = date?.getDate();
  //   // let year = date?.getFullYear();
  //   // let month = date?.getMonth();
  //   // let hours: number = date.getHours();
  //   // let minutes: number = date.getMinutes();
  //   // const ampm = hours >= 12 ? "PM" : "AM";
  //   // hours = hours % 12;
  //   // hours = hours ? hours : 12; // the hour '0' should be '12'
  //   // // appending zero in the start if hours less than 10
  //   // return (
  //   //   date_number +
  //   //   " " +
  //   //   month +
  //   //   " " +
  //   //   year +
  //   //   " " +
  //   //   hours +
  //   //   ":" +
  //   //   minutes +
  //   //   " " +
  //   //   ampm
  //   // );
  // } else {
  //   return "";
  // }
};

export const getLeastRecentlyUpdatedCart = (carts: any[]) => {
  if (carts.length === 0) {
    return "N/A";
  }
  const sortedCarts = carts.sort((a, b) => {
    return new Date(b?.modified_at).getTime() - new Date(a?.modified_at).getTime();
  });

  return dayjs(sortedCarts[0]?.modified_at).fromNow();
};

export function daysInThisMonth() {
  var now = new Date();
  return new Date(now.getFullYear(), now.getMonth()+1, 0).getDate();
}
