import { Button, Divider, Dropdown, MenuProps, Table } from "antd";
import { getFormattedDate } from "../../../helpers/date_helper";
import TaxAmount1 from "../tax_amount1";
import styles from "./index.module.scss";
import { FC } from "react";
import { Client } from "../../../interfaces/client.interface";
import { convertNumberToWords } from "../../../helpers/num_to_word";

type ChildProps = {
  id: string;
  data: any;
  client_data: Client | null;
};

const ProformaInvoicePreview: FC<ChildProps> = ({ id, data, client_data }) => {
  let dataA = 0;
  const columns2 = [
    {
      title: "S.no.",
      key: "s.no.",
      width: "3rem",
    },
    {
      title: "Item",
      key: "boq_item",
      width: "14rem",
    },
    {
      title: "Item Image",
      key: "item_image",
      width: "7rem",
    },
    {
      title: "MRP",
      key: "mrp",
      width: "6rem",
    },
    {
      title: "Brand",
      key: "brand",
      width: "7rem",
      textAlign: "left",
    },

    {
      title: "Quantity",
      key: "quantity",
      width: "4rem",
    },
    {
      title: "Rate",
      key: "rate",
      width: "7rem",
    },
    {
      title: "Discount",
      key: "discount",
      width: "6rem",
    },
    {
      title: "Net Amount",
      key: "net amount",
      width: "4rem",
    },
  ];

  const leftCol = [
    ["Phone", data?.gst?.contact],
    ["Email", data?.gst?.email],
    ["GSTIN", data?.gst?.gst_no],
    ["PAN", data?.gst?.pan_no],
  ];

  const rightCol = [
    ["Date", getFormattedDate(data?.modified_at)],
    ["Lead ID", data?.lead_id + " (" + data?.estimate_no + ")"],
    ["Client ID", client_data?.f_name],
    [
      "Sales Person",
      data?.assigned_to?.name + ", " + data?.assigned_to?.contact,
    ],
  ];

  const paymentCol = [
    ["Net Banking(NEFT,IMPS,RTGS)", "Free"],
    ["UPI", "Free"],
    ["Debit Card", "0.8%"],
    ["Credit Card", "1.8%"],
    ["American Express", "2.26%"],
  ];

  //console.log("Main Data", data);
  // console.log(data?.estimate_items?.length);

  return (
    <div className={styles.table_content}>
      <div className="white-color-bg">
        <div className="m-1 mt-4 py-1 white-color-bg border border-2 border-dark overflow-hidden">
          <div className="row d-flex flex-row justify-content-between">
            <div className="col">
              <img
                alt="logo"
                src="/main_logo.svg"
                style={{ height: "80px" }}
                className="mt-2 px-2"
              />
            </div>
            <div
              className="col th-font-size-16 th-font-weight-700"
              style={{ textAlign: "right", paddingRight: "1.5rem" }}
            >
              PRO FORMA INVOICE
            </div>
          </div>
          <div className="row gap-2 my-4 px-2">
            <div>
              <div className={styles.address}>To be replaced</div>
              <div className={styles.address1}>{data?.gst.address}</div>
            </div>
            <div className="d-flex pt-4">
              <div className="col">
                {data &&
                  leftCol.map((value) => {
                    return (
                      <div className="d-flex flex-row" key={value[0]}>
                        <div className={styles.first}>{value[0]}</div>
                        <div className={styles.middle}>:</div>
                        <div className={styles.last}>{value[1]}</div>
                      </div>
                    );
                  })}
              </div>
              <div className="col">
                {data &&
                  rightCol.map((value) => {
                    return (
                      <div className="d-flex flex-row" key={value[0]}>
                        <div className={styles.first1}>{value[0]}</div>
                        <div className={styles.middle1}>:</div>
                        <div className={styles.last1}>{value[1]}</div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className={styles.line}></div>
          </div>
          <div>
            {/* <Table
                columns={columns}
                dataSource={data?.estimate_items}
                scroll={{ x: "max-content" }}
                rowKey={(record) => record.id}
                style={{}}
              /> */}

            <div
              className={
                "d-flex flex-row flex-nowrap justify-content-between " +
                styles.tTable
              }
            >
              <div className="d-flex flex-column">
                <div
                  className={
                    styles.tMainHead +
                    " d-flex flex-row justify-content-start align-items-center"
                  }
                >
                  {columns2.map((value) => {
                    let temp = value.key;
                    return (
                      <div
                        className={`${styles.tHead} ${styles[temp]} py-2`}
                        key={value.title}
                        style={{
                          width: value.width,
                          textAlign: "left",
                        }}
                      >
                        {value.title}
                      </div>
                    );
                  })}
                </div>
                {data?.estimate_items &&
                  data?.estimate_items.map((value: any, index: any) => {
                    dataA += Number(value["quantity"]);
                    return (
                      <div
                        key={`row${index}`}
                        className={
                          styles.tMainBody +
                          " d-flex flex-row align-items-center"
                        }
                      >
                        <div
                          className={styles.tBody}
                          style={{
                            width: columns2[0].width,
                            paddingLeft: "0.5rem",
                          }}
                        >
                          {index + 1}
                        </div>
                        <div
                          className={styles.tBody}
                          style={{
                            width: columns2[1].width,
                          }}
                        >
                          {value["boq_item"]}
                        </div>
                        <div
                          className={styles.tBody + " py-1 item_image"}
                          style={{
                            width: columns2[2].width,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            alt="logo"
                            src={value["item_image"]}
                            style={{ maxWidth: "5rem", maxHeight: "5rem" }}
                            className="my-1"
                          />
                        </div>
                        <div
                          className={styles.tBody}
                          style={{
                            width: columns2[3].width,
                            textAlign: "right",
                            paddingRight: "2.2rem",
                            fontSize: "0.8rem",
                          }}
                        >
                          {value["mrp"]}
                        </div>
                        <div
                          className={styles.tBody}
                          style={{ width: columns2[4].width }}
                        >
                          {value["brand"]}
                        </div>

                        <div
                          className={styles.tBody}
                          style={{
                            width: columns2[5].width,
                            textAlign: "right",
                            paddingRight: "1rem",
                          }}
                        >
                          {value["quantity"]}
                          <br />
                          {value["unit"]}
                        </div>
                        <div
                          className={styles.tBody}
                          style={{
                            width: columns2[6].width,
                            textAlign: "right",
                            paddingRight: "1rem",
                          }}
                        >
                          {value["unit_cost_price"]}
                          <br />
                          {"/"}
                          {value["unit"]}
                        </div>
                        <div
                          className={styles.tBody}
                          style={{
                            width: columns2[7].width,
                            textAlign: "center",
                            paddingRight: "1rem",
                          }}
                        >
                          {(
                            ((Number(value["mrp"]) -
                              (Number(value["unit_cost_price"]) +
                                (Number(value["unit_cost_price"]) *
                                  Number(value["tax_value"])) /
                                  100)) /
                              Number(value["mrp"])) *
                            100
                          ).toFixed(2)}
                          %
                        </div>
                        <div
                          className={styles.tBody}
                          style={{ width: columns2[8].width }}
                        >
                          {(
                            Number(value["unit_cost_price"]) *
                            Number(value["quantity"])
                          ).toFixed(2)}
                        </div>
                      </div>
                    );
                  })}

                <div
                  className={
                    styles.tSubtotal + " d-flex flex-row align-items-center"
                  }
                >
                  <div
                    className="my-2"
                    style={{ marginLeft: columns2[0].width }}
                  >
                    Sub Total
                  </div>
                  <div className="my-2" style={{ marginLeft: "32rem" }}>
                    {dataA.toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
            {/* <div>
                {data?.estimate_items.map((value: any) => {
                  return <div className="t-title">{value.title}</div>;
                })}
              </div> */}
          </div>
          <div className="row d-flex flex-col">
            <div className="col"></div>
            <div className="col m-1 mx-2" style={{ fontSize: "0.8rem" }}>
              <TaxAmount1 items={data?.estimate_items ?? []} />
              <div className="my-3 th-font-weight-600 px-1">
                {data?.estimate_additional_charges?.map(
                  (each: any, ind: number) => (
                    <>
                      <div className="d-flex justify-content-between" key={ind}>
                        <div className="">
                          {each?.charge_type}
                          {` (@${each?.tax_value + "%"} ${each?.tax_type})`}
                        </div>
                        <div className="">
                          {Number(
                            Number(each?.amount) +
                            Number(each?.amount) *
                              Number(each?.tax_value) *
                              0.01
                          ).toFixed(2)}
                        </div>
                      </div>
                      {each.remark && each.remark != "" && (
                        <div>Remark: {each.remark}</div>
                      )}
                    </>
                  )
                )}
              </div>
              <div
                className="d-flex justify-content-between px-2"
                style={{
                  fontWeight: 600,
                  fontSize: "0.8rem",
                  background: "rgba(255, 208, 0, 0.952)",
                }}
              >
                <div className="pt-1 ">
                  <h6 className="th-font-weight-700">Total Amount</h6>
                </div>
                <div className="pt-1 th-font-weight-700 th-font-size-16">
                  {"₹ "}
                  {Number(data?.total_estimate_amount)}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.line2}></div>
          <div className="my-2 px-2 th-font-size-14">
            Total Amount(in words):{"  "}
            <span className="th-font-weight-700">
              {convertNumberToWords(
                Math.round(Number(data?.total_estimate_amount || "0"))
              )}
              {" Rupees Only"}
            </span>
          </div>
          <div className={styles.line2}></div>
          <div className="row mt-5">
            <div>
              <div className="px-2">
                <h6 className={"th-font-weight-600 " + styles.terms}>
                  Payment Gateway Charges
                </h6>
                <div className="mt-1 mb-5 pl-2">
                  <div className="d-flex">
                    <div className="col">
                      {paymentCol.map((value) => {
                        return (
                          <div className="d-flex flex-row" key={value[0]}>
                            <div className={styles.first3}>{value[0]}</div>
                            <div className={styles.middle3}>:</div>
                            <div className={styles.last3}>{value[1]}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  {/* <div
                    className="mb-2 pl-1 d-flex flex-column"
                    style={{ fontSize: "0.7rem" }}
                  >
                    {data?.estimate_payment_terms?.map((value: any) => {
                      return <div>{value.term}</div>;
                    })}
                  </div> */}
                </div>
              </div>
              <div className={styles.line2}></div>

              <div className="px-2 my-2">
                <h6 className={"th-font-weight-600 my-2 " + styles.terms}>
                  Payment Terms
                </h6>
                <div>
                  {data?.estimate_payment_terms?.map(
                    (each: any, ind: number) => (
                      <div key={ind}>
                        {each?.term}:{each?.percentage}%
                      </div>
                    )
                  )}
                </div>
              </div>

              <div className={styles.line2}></div>

              <div className="px-2 mt-2">
                <h6 className={"th-font-weight-600 my-2 " + styles.terms}>
                  Terms and Conditions
                </h6>
                <div>{data?.terms_and_condition?.description}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {data?.old_attachment !=== "" &&
                    JSON.parse(data?.old_attachment ?? "[]")?.map(
                      (document) => {
                        return (
                          <div style={{ padding: 8 }}>
                            <div
                              style={{
                                border: "1px #D1D0CE solid",
                                padding: 4,
                                cursor: "pointer",
                                fontWeight: 500,
                              }}
                              onClick={() => {
                                const extension = document.split(".").pop();
                                handlePdfDownload(
                                  document.replace(`.${extension}`, ""),
                                  extension
                                );
                                // openInNewTab(
                                //   "https://mysite-powo-pdfs.s3.ap-south-1.amazonaws.com/" +
                                //     document
                                // );
                              }}
                            >
                              {document ?? ""}
                            </div>
                          </div>
                        );
                      }
                    )} */}
    </div>
  );
};

export default ProformaInvoicePreview;
