import { useCallback, useEffect, useState } from "react";
import { fetchClientCart } from "../../../apis/client.api";
import ClientCartTable from "../cart_table";

export function CartSidebar({
  userId,
  onClose,
}: {
  userId: string;
  onClose: () => unknown;
}) {
  const [cartData, setCartData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const getClientCart = useCallback(() => {
    if (!userId) return;

    setLoading(true);
    fetchClientCart(userId).then((res) => {
      setCartData(res);
    }).finally(() => setLoading(false));
  }, [userId]);

  useEffect(() => {
    getClientCart();
  }, [getClientCart]);

  return (
    <div>
      <ClientCartTable
        cart_items={cartData ?? []}
        createEstimate={() => null}
        loading={loading}
        setLoading={setLoading}
      />
    </div>
  );
}
