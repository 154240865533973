import { Input, Pagination, Space, Table } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getUserCartCount } from "../../apis/client.api";

dayjs.extend(relativeTime);

export function CartPage() {
  const [cartFetchParams, setCartFetchParams] = useState({
    page: 1,
    search: "",
  });
  const [cartData, setCartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cartTotal, setCartTotal] = useState(0);

  const cartColumns = useMemo(() => [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "Cart Count",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "Modified",
      dataIndex: "modified_at",
      key: "modified_at",
      render: (modified: string) => {
        return `${(new Date(modified)).toDateString()} (${dayjs(modified).fromNow()})`;
      }
    }
  ], []);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const params = {...cartFetchParams} as Record<string, string | number>;
    if (params.search === "") {
      delete params.search;
    }
    const response = await getUserCartCount(cartFetchParams);
    setCartData(response.data ?? []);
    setCartTotal(response.count ?? 0);
    setLoading(false);
  }, [cartFetchParams]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <section className="mx-3 my-3 px-3 py-2 white-color-bg">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="m-0">Cart</h2>
        <Space>
          <Input.Search
            placeholder="Search"
            onSearch={(value) => setCartFetchParams({ ...cartFetchParams, search: value })}
            style={{ width: 200 }}
          />
        </Space>
      </div>
      <Table
        columns={cartColumns}
        dataSource={cartData}
        pagination={false}
        loading={loading}
        className="mb-2"
      />
      <Pagination
        current={cartFetchParams.page}
        onChange={(page) => setCartFetchParams({ ...cartFetchParams, page })}
        total={cartTotal}
        disabled={loading}
      />
    </section>
  )
}