import { useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";

export interface CartConsolidationProps {
  cart_items: Array<any>;
}

const CartConsolidation = ({ cart_items }: CartConsolidationProps) => {
  const [mrp, setMrp] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  function calculateValues() {
    let total = 0;
    let mrpTotal = 0;
    cart_items.forEach((item) => {
      total += (item?.variant?.md_price_gst ?? 0) * item?.quantity;
      mrpTotal += (item?.variant?.mrp ?? 0) * item?.quantity;
    });
    setTotal(parseFloat(total?.toFixed(2)));
    setMrp(parseFloat(mrpTotal?.toFixed(2)));
  }

  useEffect(() => {
    calculateValues();
    return () => {};
  }, [cart_items]);

  useEffect(() => {
    return () => {};
  }, [total, mrp]);

  return (
    <section className="p-4 ">
      <div className="fw-bold">PRICE DETAILS</div>
      <div className="">
        <div className="d-flex justify-content-between mb-1 ">
          <span>Total MRP</span>
          <span>₹{mrp.toFixed(2)}</span>
        </div>
        <div className="d-flex justify-content-between mb-1 ">
          <span>Discount on MRP</span>
          <span>-₹{(mrp - total).toFixed(2)}</span>
        </div>
      </div>
      <hr className={`my-2 text-dark bg-dark ${styles.divider}`} />
      <div className="d-flex justify-content-between py-2 ">
        <span>Total Amount</span>
        <span>₹ {Number(total)}</span>
      </div>
    </section>
  );
};

export default CartConsolidation;
