import { ReactNode, useEffect, useState } from "react";
import { getFormattedDateAndTime } from "../../../helpers/date_helper";
import { booking_status_options } from "../../../pages/clients";

function TwoColGrid({ children }: { children: ReactNode }) {
  return (
    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 10 }}>
      {children}
    </div>
  );
}

export function BookingDetails({ details }: { details: any }) {
  const [parsedRemarks, setParsedRemarks] = useState<any[]>([]);

  useEffect(() => {
    if (details?.remarks) {
      try {
        setParsedRemarks(JSON.parse(details?.remarks));
      } catch (e) {
        //
      }
    }
  }, [details]);

  return (
    <>
      <div className="p-3 rounded shadow th-font-size-14">
        <TwoColGrid>
          <span className="fw-bold">Client Name:</span>
          {details?.user?.f_name ?? details?.name ?? "N/A"}
          <span className="fw-bold">Contact:</span>
          {details?.contact ?? "N/A"}
          <span className="fw-bold">Created At:</span>
          {getFormattedDateAndTime(details?.created_at) ?? "N/A"}
          <span className="fw-bold">Assigned To:</span>
          {details?.bda?.f_name ?? "N/A"}
          <span className="fw-bold">Booking Status:</span>
          {booking_status_options.find(
            (option) => option.value === details?.booking_status
          )?.label ?? "N/A"}
        </TwoColGrid>
      </div>
      <div className="p-3 mt-3 rounded shadow th-font-size-14">
        <TwoColGrid>
          <span className="fw-bold">Visit Date:</span>
          {details?.visit_date
            ? getFormattedDateAndTime(details?.visit_date)
            : "N/A"}
          <span className="fw-bold">Visit Initiate Time:</span>
          {details?.visit_initiated_time
            ? getFormattedDateAndTime(details?.visit_initiated_time)
            : "N/A"}
          <span className="fw-bold">Visit Complete Time:</span>
          {details?.visit_completed_time
            ? getFormattedDateAndTime(details?.visit_completed_time)
            : "N/A"}
        </TwoColGrid>
      </div>
      <div className="th-font-size-16 fw-bold my-3">
        Remarks
      </div>
      {parsedRemarks.map((remarks, idx) => {
        return (
          <div className="p-3 mt-3 rounded shadow th-font-size-14" key={`remark-${idx}`}>
            {(new Date(remarks?.date)).toDateString()} - {remarks?.status}
            <br />
            <p>
              {remarks?.value}
            </p>
          </div>
        );
      })}
    </>
  );
}
