import { useEffect, useState } from "react";
import { rocketBoxShipmentTracking } from "../../../apis/po.api";
import { useParams } from "react-router-dom";

export default function DeliveryTracking() {
  const { po_id } = useParams();
  const [data, setData] = useState<any>({});

  useEffect(() => {
    rocketBoxShipmentTracking(po_id).then((res) => {
      setData(res);
    });
  }, []);

  return (
    <main className="mx-3 my-3 px-3 py-2 white-color-bg">
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </main>
  );
}
