import { DatePicker, Pagination, Select } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { getPOMarginData } from "../../apis/po.api";
import { statusOptions } from "../dashboard";

export default function MarginDashboard() {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [data, setData] = useState<Array<any>>([]);
  const [total_entries, setTotalEntries] = useState<number>(0);
  const [created_at__gte, setCreatedAtGte] = useState<string>("");
  const [created_at__lte, setCreatedAtLte] = useState<string>("");
  const [filterStatuses, setFilterStatuses] = useState<string[]>([]);
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>();

  const getData = async (forcePage?: number) => {
    try {
      setLoading(true);
      getPOMarginData({
        page: forcePage ?? page,
        estimate_status: filterStatuses.join(','),
        created_at__gte,
        created_at__lte,
      }).then((res: any) => {
        setData(res?.data);
        setTotalEntries(res?.count);
        setLoading(false);
      });
    } catch (e) {
      // handle error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Debounced
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        getData();
      }, 500)
    );

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [page, created_at__gte, created_at__lte, filterStatuses]);

  const columns: ColumnsType<any> = [
    {
      key: "sl_no",
      title: "Sl No",
      render: (data, record, index) => <div>{page * 10 + index - 9}</div>,
    },
    {
      key: "po_date",
      dataIndex: "created_at",
      render: (data) => <div>{new Date(data).toLocaleString()}</div>,
      title: "PO Date",
    },
    {
      key: "lead_id",
      dataIndex: "lead_id",
      title: "Lead ID",
    },
    {
      key: "pi_amount",
      dataIndex: "total_estimate_amount",
      title: "PI Amount",
    },
    {
      key: "po_amount",
      dataIndex: "total_po_amount",
      title: "PO Amount",
      render: (data) => <div>{data ?? 0}</div>,
    },
    {
      key: "margin",
      title: "Margin",
      render: (_, record) => {
        const piAmount = record?.total_estimate_amount ?? 0;
        const poAmount = record?.total_po_amount ?? 0;
        return <div>{(piAmount - poAmount).toFixed(2)} ( {((piAmount - poAmount) / 100).toFixed(2)}% )</div>;
      }
    },
    {
      key: "status",
      dataIndex: "estimate_status",
      title: "Status",
      render: (data) => statusOptions.find((st) => st.value === data)?.label ?? data
    },
  ];
  return (
    <div className="mx-3 my-3 px-3 py-2 white-color-bg">
      <div className="d-flex justify-content-between">
        <h3>PO Vendor Dashboard</h3>
      </div>
      <div className="d-flex gap-2 py-2 justify-content-end align-items-center">
        <div>Start Date</div>
        <DatePicker
          onChange={(date, dateString) => {
            setCreatedAtGte(dateString);
          }}
        />
        <div>End Date</div>
        <DatePicker
          onChange={(date, dateString) => {
            setCreatedAtLte(dateString);
          }}
        />
        {/* Filter for statuses using a multi-select. Status options are from statusOptions */}
        <Select
          allowClear
          mode="multiple"
          placeholder="Status Filter"
          onChange={(values) => {
            setPage(1);
            setFilterStatuses(values);
          }}
          options={statusOptions}
          style={{ minWidth: 120 }}
        />
      </div>
      <Table
        dataSource={data}
        loading={loading}
        columns={columns}
        scroll={{ x: "100%" }}
        pagination={false}
      />
      <Pagination
        current={page}
        total={total_entries ?? 1}
        showSizeChanger={false}
        pageSize={10}
        className="mt-3"
        onChange={(p) => {
          setPage(p);
          // getReport({
          //   page: p,
          //   lead_id: search,
          // });
        }}
      />
    </div>
  );
}
