import {
  AutoComplete,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import type { FormInstance } from "antd/es/form";
import TextArea from "antd/es/input/TextArea";
import React, { useRef, useState } from "react";
import { mdCategories, uploadFile } from "../../../../apis/misc.api";
import styles from "./index.module.scss";
import dayjs from "dayjs";
import moment from "moment";
import { getFileSize } from "../../../../helpers/file_helper";
import { ImageUpload } from "../../pro_forma_invoice_create/pfi_product_image_upload";

interface Item {
  key: string;
  name: string;
  age: number;
  address: string;
}
interface EditableRowProps {
  index: number;
}

interface PFIItemTableProps {
  data: any;
  setData: Function;
  loading: boolean;
}
const { Option } = Select;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: Item;
  index: number;
  children: React.ReactNode;
}
const unit_status = [
  { id: "1", value: "Box", label: "Box" },
  { id: "2", value: "Piece", label: "Piece" },
  { id: "3", value: "Sheet", label: "Sheet" },
  { id: "4", value: "Roll", label: "Roll" },
  { id: "5", value: "Metre", label: "Metre" },
  { id: "6", value: "Sq. Ft.", label: "Sq. Ft." },
  { id: "7", value: "Coil", label: "Coil" },
  { id: "8", value: "Pair", label: "Pair" },
  { id: "9", value: "Slab", label: "Slab" },
  { id: "10", value: "Pack", label: "Pack" },
  { id: "11", value: "Litre", label: "Litre" },
  { id: "12", value: "Length", label: "Length" },
  { id: "13", value: "Feet", label: "Feet" },
  { id: "14", value: "Kg", label: "Kg" },
];
const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const PFIItemTable = ({ data, setData, loading }: PFIItemTableProps) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [categories, setCategories] = useState([]);
  const [missingFields, setMissingFields] = useState<Array<any>>([]);
  const isFirstRender = useRef(true);

  const categoryOptions = React.useMemo(
    () =>
      categories.map((category: any) => ({
        value: category?.category_name,
      })),
    [categories]
  );
  const getMissingFields = (fields: {
    boq_item: any;
    quantity: any;
    unit: any;
    brand: any;
    category_name: string;
    tax_value: any;
    unit_selling_price: any;
  }) => {
    const missingFields = [];
    console.log("debug>>> fields,", fields);
    if (!fields.boq_item) {
      missingFields.push("boq_item");
    }
    if (!fields.quantity) {
      missingFields.push("quantity");
    }
    if (!fields.unit) {
      missingFields.push("unit");
    }
    if (!fields.brand) {
      missingFields.push("brand");
    }
    if (!fields.category_name || fields?.category_name == "") {
      missingFields.push("category");
    }
    if (!fields.tax_value) {
      missingFields.push("tax_value");
    }
    if (!fields.unit_selling_price) {
      missingFields.push("unit_selling_price");
    }

    return missingFields;
  };

  const handleDelete = (id: string) => {
    const newData = data?.estimate_items.filter((item: any) => item.id !== id);
    setData("estimate_items", newData);
  };
  const defaultColumns = [
    {
      title: "S.no",
      dataIndex: "index",
      render: (value: any, data: any, index: number) => <>{index + 1}</>,
    },
    {
      title: "Item",
      dataIndex: "boq_item",
      render: (value: any, data: { boq_item: any }, index: any) => (
        <>
          <TextArea
            className="item-input-table w-100"
            style={{ minWidth: 160 }}
            disabled
            value={data?.boq_item ?? ""}
            onChange={(e: { target: { value: any } }) => {
              handleSave({ ...data, boq_item: e.target.value });
              console.log("categoryOptions", categoryOptions);
            }}
            // status={
            //   missingFields
            //     ?.find((item: { index: any }) => index === item?.index)
            //     ?.missingFields?.includes("boq_item")
            //     ? "error"
            //     : ""
            // }
          />
        </>
      ),
    },
    {
      title: "Category",
      dataIndex: "category_name",
      render: (value: any, data: { category_name: any }, index: any) => (
        <>
          {/* <Input
            className="item-input-table"
            value={data?.category_name}
            style={{ minWidth: 70 }}
            onChange={(e) => {
              handleSave({ ...data, category_name: e.target.value });
            }}
          /> */}
          <AutoComplete
            className="item-input-table"
            style={{ minWidth: 120 }}
            options={categoryOptions}
            disabled
            value={data?.category_name}
            onChange={(category) => {
              handleSave({ ...data, category_name: category });
            }}
            status={
              missingFields
                ?.find((item: any) => index === item?.index)
                ?.missingFields?.includes("category")
                ? "error"
                : ""
            }
            filterOption={(inputValue, option) =>
              option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
          />
          {missingFields
            ?.find((item) => index === item?.index)
            ?.missingFields?.includes("category") && (
            <div style={{ color: "red" }}>Invalid value</div>
          )}
        </>
      ),
    },
    {
      title: "Brand",
      dataIndex: "brand",
      render: (
        value: any,
        data: { brand: string | number | readonly string[] | undefined },
        index: any
      ) => (
        <>
          <Input
            className="item-input-table"
            value={data?.brand}
            style={{ minWidth: 100 }}
            disabled
            onChange={(e) => {
              handleSave({ ...data, brand: e.target.value });
            }}
            status={
              missingFields
                ?.find((item) => index === item?.index)
                ?.missingFields?.includes("brand")
                ? "error"
                : ""
            }
          />
          {missingFields
            ?.find((item) => index === item?.index)
            ?.missingFields?.includes("brand") && (
            <div style={{ color: "red" }}>Invalid value</div>
          )}
        </>
      ),
    },
    {
      title: "MRP",
      dataIndex: "mrp",
      render: (
        value: any,
        data: {
          mrp: string | number;
          disabled: boolean | undefined;
        },
        index: any
      ) => (
        <>
          <InputNumber
            className="item-input-table"
            value={data?.mrp}
            style={{ minWidth: 70 }}
            required
            onWheel={(event) => event.currentTarget.blur()}
            disabled={data?.disabled}
            onChange={(e) => {
              if (Number(e) || e == "") {
                handleSave({
                  ...data,
                  mrp: e == "" ? 0 : Number(e),
                });
              } else {
                handleSave({
                  ...data,
                  mrp: 0,
                });
              }
            }}
          />
        </>
      ),
    },
    // {
    //   title: "Discount (by vendor)",
    //   render: (
    //     value: { mrp: string | number; unit_selling_price: any },
    //     data: any,
    //     index: any
    //   ) => {
    //     var total =
    //       (((value?.mrp ? Number(value?.mrp) : 0) -
    //         (value?.unit_selling_price ?? 0) * 1.18) *
    //         100) /
    //       (value?.mrp ? Number(value?.mrp) : 1);
    //     return (
    //       <span className="item-input-table" style={{ minWidth: 100 }}>
    //         {value?.mrp != 0 && value?.mrp != "" && value?.mrp
    //           ? isNaN(total)
    //             ? "-"
    //             : total.toFixed(2) + "%"
    //           : "-"}
    //       </span>
    //     );
    //   },
    // },
    // {
    //   title: "Landing Price(w/o gst)",
    //   dataIndex: "unit_selling_price",
    //   render: (value: any, data: any, index: any) => (
    //     <>
    //       <InputNumber
    //         className="item-input-table"
    //         style={{ minWidth: 100 }}
    //         onWheel={(event) => event.currentTarget.blur()}
    //         value={data?.unit_selling_price}
    //         disabled={data?.disabled}
    //         onChange={(e) => {
    //           let landing_price = data?.landing_price ?? 0;
    //           if (!data?.product_handle || !data?.variant_handle) {
    //             landing_price = Number(e);
    //           }
    //           if (Number(e) || e == "") {
    //             handleSave({
    //               ...data,
    //               unit_selling_price: e == "" ? 0 : Number(e),
    //               landing_price: landing_price,
    //             });
    //           } else {
    //             handleSave({
    //               ...data,
    //               unit_selling_price: 0,
    //               landing_price: landing_price,
    //             });
    //           }
    //         }}
    //         status={
    //           missingFields
    //             ?.find((item) => index === item?.index)
    //             ?.missingFields?.includes("unit_selling_price")
    //             ? "error"
    //             : ""
    //         }
    //       />
    //       {missingFields
    //         ?.find((item) => index === item?.index)
    //         ?.missingFields?.includes("unit_selling_price") && (
    //         <div style={{ color: "red" }}>Invalid value</div>
    //       )}
    //     </>
    //   ),
    // },
    {
      title: "Quantity",
      dataIndex: "quantity",
      render: (value: any, data: { quantity: string | number }, index: any) => (
        <>
          <InputNumber
            className="item-input-table"
            value={data?.quantity}
            style={{ minWidth: 50 }}
            onWheel={(event) => event.currentTarget.blur()}
            onChange={(e) => {
              if (Number(e) || e == "") {
                handleSave({
                  ...data,
                  quantity: e == "" ? 0 : Number(e),
                });
              } else {
                handleSave({
                  ...data,
                  quantity: 0,
                });
              }
            }}
            status={
              missingFields
                ?.find((item) => index === item?.index)
                ?.missingFields?.includes("quantity")
                ? "error"
                : ""
            }
          />
          {missingFields
            ?.find((item) => index === item?.index)
            ?.missingFields?.includes("quantity") && (
            <div style={{ color: "red" }}>Invalid value</div>
          )}
        </>
      ),
    },
    {
      title: "Unit",
      dataIndex: "unit",
      render: (
        value: any,
        data: { unit: string | number | readonly string[] | undefined },
        index: any
      ) => (
        <>
          <Select
            style={{ width: 100 }}
            onChange={(e) => {
              handleSave({ ...data, unit: e });
            }}
            value={data?.unit ?? ""}
            disabled
          >
            {unit_status?.map((each) => (
              <Option key={each.id} value={each.value}>
                {each.label}
              </Option>
            ))}
          </Select>
          {/* <Input
            className="item-input-table"
            value={data?.unit}
            style={{ minWidth: 60 }}
            onChange={(e) => {
              handleSave({ ...data, unit: e.target.value });
            }}
            status={
              missingFields
                ?.find((item) => index === item?.index)
                ?.missingFields?.includes("unit")
                ? "error"
                : ""
            }
          /> */}
        </>
      ),
    },
    // {
    //   title: "GM %",
    //   render: (
    //     value: any,
    //     data: { gross_margin: string; disabled: any },
    //     index: any
    //   ) => {
    //     return (
    //       <InputNumber
    //         className="item-input-table"
    //         value={parseFloat(data?.gross_margin)?.toFixed(2)}
    //         style={{ minWidth: 60 }}
    //         onWheel={(event) => event.currentTarget.blur()}
    //         disabled={data?.disabled}
    //         onChange={(e) => {
    //           console.log(e);
    //           var gross_margin = e;
    //           var margin: string | number = 0;
    //           if (!isNaN(Number(gross_margin))) {
    //             margin =
    //               Number(gross_margin) / (1 - Number(gross_margin) / 100);
    //           } else {
    //             margin = "";
    //           }
    //           if (Number(margin) || margin == "") {
    //             handleSave({
    //               ...data,
    //               margin: margin ?? 0,
    //               gross_margin: gross_margin,
    //             });
    //           } else {
    //             handleSave({
    //               ...data,
    //               margin: 0,
    //               gross_margin: 0,
    //             });
    //           }
    //         }}
    //       />
    //     );
    //   },
    // },
    {
      title: "Cost Price (w/o GST)",
      width: 180,
      render: (
        value: { unit_selling_price: number; margin: string },
        data: any,
        index: any
      ) => {
        let total: number =
          Number(value?.unit_selling_price) +
          (Number(value?.unit_selling_price) * Number(value?.margin)) / 100;
        return (
          <span className="item-input-table" style={{ minWidth: 140 }}>
            {total.toFixed(2)}
          </span>
        );
      },
    },
    {
      title: "Tax",
      key: "tax",
      render: (value: any, data: { tax_value: any }, index: any) => (
        <Space>
          <Select
            className="item-input-table"
            onChange={(e: any) => {
              console.log("value", value, data, index);
              // data.unit_selling_price = parseFloat(
              //   data?.md_price_gst / (1 + e / 100)
              // ).toFixed(2);
              // console.log("data.unit_selling_price", data.unit_selling_price);

              handleSave({
                ...data,
                tax_value: e,
                // unit_selling_price: data.unit_selling_price,
              });
              // onChange(e, data, "tax_value");
            }}
            value={data?.tax_value}
            status={
              missingFields
                ?.find((item) => index === item?.index)
                ?.missingFields?.includes("tax_value")
                ? "error"
                : ""
            }
          >
            {[0, 5, 12, 18, 28]?.map((each, index) => (
              <Option key={index} value={each}>
                {each}
              </Option>
            ))}
          </Select>
          {/* <Select
            className="item-input-table"
            onChange={(e) => {
              // onChange(e, data, "tax_type");
              handleSave({ ...data, tax_type: e });
            }}
            value={data?.tax_type ?? "cgst_sgst"}
          >
            {taxTypes?.map((each) => (
              <Option key={each.id} value={each.value}>
                {each.label}
              </Option>
            ))}
          </Select> */}
          {/* <span>Budget : {data?.budget_price}</span> */}
        </Space>
      ),
    },
    {
      title: "Rate (w/ gst)",
      // dataIndex: "total",
      width: 140,
      render: (data: {
        unit_selling_price: number;
        margin: number;
        tax_value: number;
      }) => {
        var total =
          (Number(data?.unit_selling_price) +
            Number(data?.unit_selling_price) * (Number(data?.margin) / 100)) *
          ((Number(data?.tax_value) + 100) / 100);
        return (
          <span className="item-input-table" style={{ minWidth: 140 }}>
            {total?.toFixed(2)}
          </span>
        );
      },
    },
    {
      title: "Amount (w/ gst)",
      // dataIndex: "total",
      render: (data: {
        quantity: string;
        unit_selling_price: string;
        margin: string;
        tax_value: string;
      }) => {
        var total =
          parseFloat(data?.quantity) *
          ((parseFloat(data?.unit_selling_price) +
            parseFloat(data?.unit_selling_price) *
              (parseFloat(data?.margin) / 100)) *
            ((parseFloat(data?.tax_value) + 100) / 100));
        console.log("total", total, data?.quantity, parseFloat(data?.quantity));
        return (
          <span className="item-input-table" style={{ minWidth: 80 }}>
            {total?.toFixed(2)}
          </span>
        );
      },
    },
    // {
    //   title: "Lead Time(days)",
    //   dataIndex: "lead_time",
    //   render: (value: any, data: any, index: any) => (
    //     <>
    //       <Input
    //         type="number"
    //         style={{ minWidth: 70 }}
    //         placeholder="Select lead time"
    //         value={data?.lead_time}
    //         onChange={(e) => {
    //           handleSave({
    //             ...data,
    //             lead_time: e.target.value,
    //           });
    //         }}
    //       />
    //     </>
    //   ),
    // },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (value: any, data: { remarks: string }, index: any) => (
        <>
          <TextArea
            className="item-input-table"
            value={data?.remarks ?? ""}
            style={{ minWidth: 220 }}
            onChange={(e) => {
              handleSave({ ...data, remarks: e.target.value });
            }}
            status={
              missingFields
                ?.find((item) => index === item?.index)
                ?.missingFields?.includes("lead_time")
                ? "error"
                : ""
            }
          />
        </>
      ),
    },

    {
      title: "Image",
      // dataIndex: "total",
      render: (
        value: any,
        data: { item_image: string | undefined },
        index: any
      ) => (
        <>
          {data?.item_image && data?.item_image != "" && (
            <img
              src={data?.item_image}
              alt="item"
              className={styles.pi_item_img}
            />
          )}
        </>
      ),
    },
    {
      title: "Action",
      // dataIndex: "action",
      render: (_: any, record: { id: any }, index: any) =>
        data?.estimate_items.length >= 1 ? (
          <div style={{ minWidth: 150 }}>
            <ImageUpload
              key={record?.id ?? index}
              onDocumentAdd={onDocumentAdd}
              index={index}
              dataSource={data}
              setDataSource={setData}
              record={record}
              onImageRemove={onImageRemove}
            />
            <Tag
              color="red"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                handleDelete(record.id);
              }}
            >
              Delete
            </Tag>
          </div>
        ) : null,
    },
  ];

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      mdCategories().then((res) => {
        setCategories(res);
      });
      return;
    }
  }, []);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const handleSave = (row: any) => {
    console.log("handleSave", row);
    const newData = [...data.estimate_items];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setData("estimate_items", newData);
  };
  const columns = defaultColumns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const onDocumentAdd = async (index: number, file: any) => {
    try {
      const result = await Promise.all(
        Object.keys(file).map(async (key) => {
          const formData = new FormData();
          formData.append("file", file[key], file[key].name);
          formData.append("file_name", file[key].name);
          formData.append("client", data?.client);
          formData.append("file_size", getFileSize(file[key]?.size));

          const uploaded_file = await uploadFile(formData);
          let newData = [...data.estimate_items];
          newData = [...data?.estimate_items];
          newData[index].item_image = uploaded_file;
          setData("estimate_items", newData);
        })
      );
    } catch (e) {
      console.log("i-log> ", e);
    } finally {
    }
  };

  const onImageRemove = async (index: number) => {
    try {
      console.log("onImageRemove", index);
      let newData = [...data.estimate_items];
      newData = [...data?.estimate_items];
      newData[index].item_image = null;
      setData("estimate_items", newData);
    } catch (e) {
      console.log("i-log> ", e);
    } finally {
    }
  };

  return (
    <Table
      components={components}
      bordered
      loading={loading}
      rowKey={(record) => record.id}
      scroll={{ x: "100%" }}
      dataSource={data?.estimate_items}
      columns={columns}
      pagination={false}
    />
  );
};

export default PFIItemTable;
