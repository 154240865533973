import { useCallback } from "react";

export function StatusDot({ status }: { status: string }) {
  const getColorFromStatus = useCallback(() => {
    switch (status) {
      case "cancelled":
        return "rgb(232, 59, 70)";
      case "active":
        return "rgb(255, 193, 7)";
      case "confirmed":
      case "visit_initiated":
      case "visit_completed":
        return "rgb(40, 167, 69)";
      default:
        return "rgb(108, 117, 125)";
    }
  }, [status]);
  return (
    <div
      style={{
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: getColorFromStatus(),
        display: "inline-block",
        marginRight: 8,
      }}
    />
  );
}