import axios from "axios";
import { errorActions } from "../helpers/auth_helper";

export const fetchUserDetails = async () => {
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/procurement-current-user/`)
      .then((res) => {
        const user = res.data;
        return user;
      })
      .catch((error) => {
        errorActions(error, "Error fetching details. Please try again.");
        if (error.response?.status !== 200) {
          return window.location.replace("/login");
        }
        return;
      });
  } catch (error) {}
};

export const fetchBookings = async (params: any) => {
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/book-a-slot/`, {
        ...params,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((error) => {
        errorActions(error, "Error fetching details. Please try again.");
        if (error.response?.status == 403) {
          return window.location.replace("/login");
        }
        return;
      });
  } catch (error) {}
};

export const getUserCumulativeSum = async (params: any) => {
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/user-order-sum/`, {
        ...params,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((error) => {
        errorActions(error, "Error fetching details. Please try again.");
        if (error.response?.status == 403) {
          return window.location.replace("/login");
        }
        return;
      });
  } catch (error) {}
};
