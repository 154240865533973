import { Button, Form, FormInstance, Input, Select, Space } from "antd";
import React, { useEffect } from "react";
import { editClientAlternateContact } from "../../../apis/client.api";
interface EditClientAlternateContactFormProps {
  contact: any;
  fetchAlternateContacts: Function;
  onClientEditAlternateContactDrawerClose: Function;
}
const { TextArea } = Input;
const { Option } = Select;

const EditClientAlternateContactForm = ({
  contact,
  onClientEditAlternateContactDrawerClose,
  fetchAlternateContacts,
}: EditClientAlternateContactFormProps) => {
  const [form] = Form.useForm();

  const onFinish = (updatedValues: any) => {
    console.log("updatedValues", updatedValues);
    editClientAlternateContact(updatedValues, contact?.id).then((res) => {
      fetchAlternateContacts();
      onClientEditAlternateContactDrawerClose();
    });
  };
  const SubmitButton = ({ form }: { form: FormInstance }) => {
    const [submittable, setSubmittable] = React.useState(false);

    // Watch all values
    const values = Form.useWatch([], form);

    React.useEffect(() => {
      form.validateFields({ validateOnly: true }).then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
    }, [values]);

    return (
      <Button type="primary" htmlType="submit" disabled={!submittable}>
        Submit
      </Button>
    );
  };

  useEffect(() => {
    Object.keys(contact).map((key: any) => {
      form.setFieldValue(key, contact[key]);
    });
  }, [contact]);

  return (
    <div>
      <Form
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
      >
        <Form.Item
          name="contact_person_name"
          rules={[
            {
              required: true,
            },
            {
              pattern: /^[a-zA-Z ]*$/gi,
              message: "Enter a valid name",
            },
          ]}
          label="Contact person name"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="contact_person_contact"
          rules={[
            {
              required: true,
              pattern: /^[1-9]\d{9}$/gi,
              message: "Enter a valid Phone",
            },
          ]}
          label="Contact person phone"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="contact_person_email"
          label="Contact person email"
          rules={[
            {
              type: "email",
              message: "Enter a valid email",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Space>
            <SubmitButton form={form} />
            {/* <Button htmlType="reset">Reset</Button> */}
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditClientAlternateContactForm;
