import { Form, Input, Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchBranchTerms } from "../../../../apis/branch.api";

interface POTermsAndConditionsSelectProps {
  branch_data: any;
  setData: Function;
  data: any;
}

const POTermsAndConditionsSelect = ({
  branch_data,
  setData,
  data,
}: POTermsAndConditionsSelectProps) => {
  // const { defaultTC, defaultTCDesc } = props;
  const { Option } = Select;
  const [terms, setTerms] = useState<Array<any>>([]);
  const [selected_term, setSelectedTerm] = useState<any>(null);
  useEffect(() => {
    if (branch_data?.id) {
      fetchBranchTerms(branch_data?.id).then((res) => {
        setTerms(res);
        if (res?.length > 0) {
          setSelectedTerm(res[0]);
        }
      });
    }
  }, [branch_data]);
  // useEffect(() => {
  //   console.log("defaultTC", defaultTC, defaultTCDesc);
  //   if (terms.length > 0 && !defaultTC) {
  //     props.handlePOTermsandConditionsSelect({
  //       id: terms[0]?.id,
  //       terms_and_conditions_description: terms[0]?.description,
  //     });
  //   }
  // }, [defaultTC, defaultTCDesc, terms]);

  const termsOptions = terms?.map((each) => (
    <Option key={each.id} value={each.id}>
      {each?.title}
    </Option>
  ));

  useEffect(() => {
    if (selected_term?.id != data?.terms_and_condition) {
      setData("terms_and_condition", selected_term?.id);
      setData("terms_and_conditions_description", selected_term?.description);
    }
  }, [selected_term]);

  useEffect(() => {
    if (data?.terms_and_condition !== selected_term?.id && terms?.length > 0) {
      setSelectedTerm(
        terms?.find((element: any) => {
          return data?.terms_and_condition == element?.id;
        })
      );
    }
  }, [data?.terms_and_condition, terms]);

  return (
    <React.Fragment>
      <div className="">
        <h6>Terms and Conditions</h6>
        <Form.Item>
          <Select
            className="w-100"
            // key={defaultTC}
            value={selected_term?.id}
            onChange={(e) => {
              const termSelected = terms?.find((term) => term?.id == e);
              setSelectedTerm(termSelected);
            }}
          >
            {termsOptions}
          </Select>
          <div className="mt-2">
            <Input.TextArea
              autoSize={{
                minRows: 4,
                maxRows: 20,
              }}
              key="description_terms"
              placeholder="Add Terms and Conditions here"
              value={data?.terms_and_conditions_description}
              onChange={(e) => {
                setData("terms_and_conditions_description", e.target.value);
              }}
            />
          </div>
        </Form.Item>
      </div>
    </React.Fragment>
  );
};

export default POTermsAndConditionsSelect;
