import axios from "axios";

export const estimateDashboardData = () => {
  return axios
    .get("https://api-dev2.materialdepot.in/apiV1/user-dashboard/")
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return null;
    });
};

export const userPoReport = (date_gte: string, date_lte: string) => {
  return axios
    .get(
      "https://api-dev2.materialdepot.in/apiV1/user-po-report/?date_gte=" +
        date_gte +
        "&date_lte=" +
        date_lte
    )
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return null;
    });
};

export const getSalesData = () => {
  return axios
    .get("https://api-dev2.materialdepot.in/apiV1/sales-dashboard/")
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return {};
    });
};
