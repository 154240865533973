import { message } from "antd";
import { AxiosError } from "axios";

export function errorActions(errors: AxiosError, msg: string) {
  if (errors?.response?.status == 403) {
    return window.location.replace("/login");
  } else {
    const responseBody = errors?.response?.data as any;
    if (responseBody?.message) {
      return message.error(responseBody.message);
    }
    return message.error(msg);
  }
}
