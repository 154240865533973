import { ConfigProvider } from "antd";
import React, { useEffect } from "react";
import Routers from "./routes";
import "./styles/theme/_mixins.scss";
import { fetchUserDetails } from "./apis/user.api";

const App: React.FC = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#24AAB7",
        },
        components: {
          Layout: {
            colorBgHeader: "#fff",
          },
          Menu: {
            itemSelectedBg: "#24AAB7",
            itemSelectedColor: "#fff",
            fontWeightStrong: 700,
          },
        },
      }}
    >
      <Routers />
    </ConfigProvider>
  );
};

export default App;
