import { Row, Tag } from "antd";
import React, { useEffect } from "react";

interface ImageUploadProps {
  onDocumentAdd: Function;
  dataSource: Array<any>;
  setDataSource: Function;
  record: any;
  index: number;
  onImageRemove: Function;
}

export const ImageUpload = ({
  onDocumentAdd,
  dataSource,
  setDataSource,
  record,
  index,
  onImageRemove,
}: ImageUploadProps) => {
  const fileInputRef = React.useRef<any>();
  useEffect(() => {}, [record]);

  return (
    <>
      <input
        type="file"
        id="file"
        onChange={(e) => {
          console.log(e.target);
          console.log(e.target.files);
          onDocumentAdd(index, e.target.files);
        }}
        ref={fileInputRef}
        style={{ width: 1, height: 1, opacity: 0, overflow: "hidden" }}
      />
      {!record?.item_image ? (
        <Tag
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            fileInputRef.current?.click();
          }}
        >
          Add Image
        </Tag>
      ) : (
        <Row className="p-2">
          <Tag
            className="cursor-pointer"
            closable
            onClick={(e) => {
              e.preventDefault();
              onImageRemove(index);
            }}
          >
            Remove Image
          </Tag>
        </Row>
      )}
    </>
  );
};
