import { Button, Divider, Form, Input, Spin, Tabs, message } from "antd";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import {
  getVendor,
  postVendorOptionalContact,
  putVendorOptionalContact,
} from "../../../apis/vendor.api";
import { IVendor } from "../../../interfaces/vendor.interface";

export function VendorOptionalContactForm({
  vendor,
  onClose,
}: {
  vendor: IVendor;
  onClose: () => unknown;
}) {
  const [vendorData, setVendorData] = useState<IVendor | null>(null);
  const [activeTab, setActiveTab] = useState<"add" | "edit">("edit");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!vendor?.id) return;
    setLoading(true);
    getVendor(vendor?.id)
      .then((vendorDetails) => vendorDetails && setVendorData(vendorDetails))
      .finally(() => setLoading(false));
  }, [vendor?.id]);

  const onEditSubmit = useCallback((warehouseId: number) => {
    return async (values: any) => {
      await putVendorOptionalContact({
        id: warehouseId,
        ...values,
      });
      message.success("Updated vendor warehouse");
    };
  }, []);

  const onAddWarehouse = useCallback(
    async (values: any) => {
      await postVendorOptionalContact({
        ...values,
        vendor_id: vendorData?.id,
      });
      message.success("Added vendor warehouse");
    },
    [vendorData?.id]
  );

  const view = useMemo(() => {
    if (!vendorData) return null;

    if (loading) return <Spin />;

    if (activeTab === "edit") {
      return (
        <Fragment>
          {vendorData.contacts.map((contact) => (
            <Form
              key={contact.id}
              initialValues={contact}
              onFinish={onEditSubmit(contact.id)}
              layout="vertical"
            >
              <Form.Item
                name="contact_name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="contact"
                label="Contact Number"
                rules={[
                  {
                    required: true,
                    message: "Pincode is required",
                  },
                  {
                    pattern: /^[1-9]\d{9}$/gi,
                    message: "Enter a valid phone number",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Email is required",
                  },
                  {
                    type: "email",
                    message: "Enter a valid email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="designation"
                label="Designation"
              >
                <Input />
              </Form.Item>
              <Button className="mt-4" type="primary" htmlType="submit">
                Submit
              </Button>
              <Divider />
            </Form>
          ))}
        </Fragment>
      );
    } else {
      return (
        <Form onFinish={onAddWarehouse} layout="vertical">
          <Form.Item
            name="contact_name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Name is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="contact"
            label="Contact Number"
            rules={[
              {
                required: true,
                message: "Pincode is required",
              },
              {
                pattern: /^[1-9]\d{9}$/gi,
                message: "Enter a valid phone number",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Email is required",
              },
              {
                type: "email",
                message: "Enter a valid email",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="designation"
            label="Designation"
          >
            <Input />
          </Form.Item>
          <Button className="mt-4" type="primary" htmlType="submit">
            Submit
          </Button>
          <Divider />
        </Form>
      );
    }
  }, [vendorData, loading, activeTab, onEditSubmit, onAddWarehouse]);

  return (
    <div>
      <Tabs
        items={[
          {
            key: "edit",
            label: "Edit",
          },
          {
            key: "add",
            label: "Add",
          },
        ]}
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key as "add" | "edit")}
      />
      {view}
    </div>
  );
}
