import { Navigate, Route } from "react-router-dom";
import { usePermissions } from "../custom_hooks/permission/permission_hook";
import UserProfile from "../pages/user_profile/user_profile_page";

export interface ProtectedRouteProps {
  permission: any;
  children: any;
}

const ProtectedRoute = ({ permission, children }: ProtectedRouteProps) => {
  const { hasPermission } = usePermissions();
  if (!hasPermission(permission)) {
    return <Navigate to={"/user-profile"} />;
  }
  return children;
};

export default ProtectedRoute;
