import axios from "axios";
import { errorActions } from "../helpers/auth_helper";

export const fetchBnplActivationUrlForClient = async (client_id: any) => {
  try {
    return axios
      .get(
        `https://api-dev2.materialdepot.in/apiV1/pi-payment-rupifi-bnpl-link/?client_id=` +
          client_id
      )
      .then((res) => {
        return res?.data[0] ?? null;
      })
      .catch((error) => {
        errorActions(error, "Error fetching URL. Please check and try again");
        return;
      });
  } catch (error) {}
};

export const fetchEstimatePaymentUrl = async (estimate_id: any) => {
  try {
    return axios
      .get(
        `https://api-dev2.materialdepot.in/apiV1/pi-payment-rupifi-link/?estimate_id=` +
          estimate_id
      )
      .then((res) => {
        return res?.data ?? null;
      })
      .catch((error) => {
        errorActions(error, "Error fetching URL. Please check and try again");
        return;
      });
  } catch (error) {}
};
