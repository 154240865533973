import { Button, Drawer, Space, Table, Input } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { fetchBranchMembers } from "../../../apis/branch.api";
import { BranchMember } from "../../../interfaces/branch_member.interface";
import BranchMemberCreateForm from "./branch_member_create_form";
import BranchMemberEditForm from "./branch_member_edit_form";
import { usePermissions } from "../../../custom_hooks/permission/permission_hook";

export interface BranchMemberProps {}

const BranchMemberList = ({}: BranchMemberProps) => {
  const { hasPermission } = usePermissions();

  const [members, setMembers] = useState<Array<any>>([]);
  const [branch_member_add_form, setBranchMemberAddForm] = useState(false);
  const [branch_member_edit_form, setBranchMemberEditForm] = useState(false);
  const [selected_member, setSelectedMember] = useState<any>(null);
  const [search, setSearch] = useState("");
  const [update, setUpdate] = useState(true);

  const showBranchMemberAddDrawer = () => {
    setBranchMemberAddForm(true);
  };
  const showBranchMemberEditDrawer = () => {
    setBranchMemberEditForm(true);
  };

  const onMemberAddDrawerClose = () => {
    setBranchMemberAddForm(false);
    setUpdate(!update);
  };
  const onMemberEditDrawerClose = () => {
    setBranchMemberEditForm(false);
    setUpdate(!update);
  };

  useEffect(() => {
    fetchBranchMembers({ search: search }).then((res) => {
      const list: Array<any> = [];
      res?.map((member: any) => {
        list.push({
          ...member?.user,
          user_permission: member?.user_permission,
          id: member.id,
          category_name: member?.category_name,
        });
      });
      setMembers(list);
    });
  }, [update]);

  const columns: ColumnsType<BranchMember> = [
    {
      title: "Name",
      dataIndex: "f_name",
      key: "f_name",
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
    },
    {
      title: "Phone Number ",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      ellipsis: true,
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      width: 300,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            size="small"
            className="fw-bold"
            type="primary"
            disabled={!hasPermission("branch_details.update")}
            onClick={() => {
              console.log(_, record);
              showBranchMemberEditDrawer();
              setSelectedMember(record);
            }}
          >
            Edit Details
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className=" p-3 py-2">
      <div className="d-flex justify-content-between align-items-center pb-3">
        <div className="th-font-size-18 th-font-weight-600 primary-color-text align-middle">
          <div>Members</div>
        </div>
        <div className="d-flex gap-2">
          <Input.Search
            type="text"
            style={{ maxWidth: 180 }}
            placeholder="Search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onSearch={(value) => {
              setUpdate(!update);
            }}
          />
          <Button
            size="middle"
            className="fw-bold"
            type="primary"
            disabled={!hasPermission("branch_details.create")}
            onClick={() => {
              showBranchMemberAddDrawer();
            }}
          >
            Add Member
          </Button>
        </div>
      </div>
      <div className="row gap-3">
        <Table
          columns={columns}
          dataSource={members}
          pagination={false}
          // loading={loading}
          rowKey={"id"}
          scroll={{
            x: "100%",
          }}
        />
      </div>
      <Drawer
        title="Add Member"
        placement="right"
        closable={false}
        onClose={onMemberAddDrawerClose}
        open={branch_member_add_form}
      >
        <BranchMemberCreateForm
          onMemberAddDrawerClose={onMemberAddDrawerClose}
        />
      </Drawer>
      <Drawer
        title="Edit Member"
        placement="right"
        closable={false}
        onClose={onMemberEditDrawerClose}
        open={branch_member_edit_form}
      >
        <BranchMemberEditForm
          onMemberEditDrawerClose={onMemberEditDrawerClose}
          selected_member={selected_member}
        />
      </Drawer>
    </div>
  );
};

export default BranchMemberList;
