import { DatePicker, Input, Pagination, Tag } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getPOVendorDashboardData } from "../../apis/po.api";
import { status_type } from "../order/order_details";

export default function Finance() {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [data, setData] = useState<Array<any>>([]);
  const [total_entries, setTotalEntries] = useState<number>(0);
  const [created_at__gte, setCreatedAtGte] = useState<string>("");
  const [created_at__lte, setCreatedAtLte] = useState<string>("");
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>();

  const getData = async (forcePage?: number) => {
    try {
      setLoading(true);
      getPOVendorDashboardData({
        page: forcePage ?? page,
        vendor_name: search,
        created_at__gte,
        created_at__lte,
      }).then((res: any) => {
        setData(res?.data);
        setTotalEntries(res?.count);
        setLoading(false);
      });
    } catch (e) {
      // handle error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Debounced
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        getData();
      }, 500)
    );

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [page, created_at__gte, created_at__lte]);

  const onSearch = () => {
    setPage(1);
    getData(1);
  };

  const columns: ColumnsType<any> = [
    {
      key: "sl_no",
      title: "Sl No",
      render: (data, record, index) => <div>{page * 10 + index - 9}</div>,
    },
    {
      key: "vendor_name",
      dataIndex: "vendor__name",
      title: "Vendor Name",
    },
    {
      key: "po_date",
      dataIndex: "created_at",
      render: (data) => <div>{new Date(data).toLocaleString()}</div>,
      title: "PO Date",
    },
    {
      key: "lead_id",
      dataIndex: "estimate__lead_id",
      title: "Lead ID",
    },
    {
      key: "po_number",
      dataIndex: "po_number",
      title: "PO Number",
    },
    {
      key: "taxable_amount",
      dataIndex: "total_po_raised_amount",
      title: "Taxable Amount",
    },
    {
      key: "status",
      dataIndex: "po_delivery_status",
      title: "Status",
      render: (data) => status_type.find((st) => st.value === data)?.label,
    },
    {
      key: "actions",
      title: "Actions",
      render: (data, record) => (
        <div>
          <Link to={`/po-details/${record?.id}`}>
            <Tag color="green">View</Tag>
          </Link>
        </div>
      ),
    }
  ];
  return (
    <div className="mx-3 my-3 px-3 py-2 white-color-bg">
      <div className="d-flex justify-content-between">
        <h3>PO Vendor Dashboard</h3>
      </div>
      <div className="d-flex gap-2 py-2 justify-content-end align-items-center">
        <div>Start Date</div>
        <DatePicker
          onChange={(date, dateString) => {
            setCreatedAtGte(dateString);
          }}
        />
        <div>End Date</div>
        <DatePicker
          onChange={(date, dateString) => {
            setCreatedAtLte(dateString);
          }}
        />
        <Input.Search
          type="text"
          style={{ maxWidth: 180 }}
          placeholder="Search"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          onSearch={(value) => {
            onSearch();
          }}
        />
      </div>
      <Table
        dataSource={data}
        loading={loading}
        columns={columns}
        scroll={{ x: "100%" }}
        pagination={false}
      />
      <Pagination
        current={page}
        total={total_entries ?? 1}
        showSizeChanger={false}
        pageSize={10}
        className="mt-3"
        onChange={(p) => {
          setPage(p);
          // getReport({
          //   page: p,
          //   lead_id: search,
          // });
        }}
      />
    </div>
  );
}
