import { useEffect, useState } from "react";
import { formatDecimal } from "../../../helpers/number_helper";

export interface TaxAmountProps {
  items: Array<any>;
}

const TaxAmount = ({ items }: TaxAmountProps) => {
  const [igstTaxes, setIgstTaxes]: any = useState({});
  const [cgstSgstTaxes, setCgstSgstTaxes]: any = useState({});

  const getTotalTaxableAmount = () => {
    let amount = 0;
    items?.forEach((item) => {
      amount +=
        (Number(item?.unit_selling_price) +
          (Number(item?.unit_selling_price) * Number(item?.margin)) / 100) *
        item?.quantity;
    });
    return amount;
  };
  useEffect(() => {
    let igst: any = {};
    let cgst_sgst: any = {};
    items?.forEach((item: any) => {
      let tax_value = parseInt(item?.tax_value);
      if (item?.tax_type == "igst") {
        if (!igst[tax_value]) {
          igst[tax_value] = 0;
        }
        igst[tax_value] +=
          (Number(item.quantity) *
            (Number(item.unit_cost_price) > 0
              ? Number(item.unit_cost_price)
              : Number(item?.unit_selling_price) +
                (Number(item?.unit_selling_price) * Number(item?.margin)) /
                  100) *
            Number(item?.tax_value)) /
          100;
      }
      console.log("i-log> item from table", item);
      if (item?.tax_type == "cgst_sgst") {
        if (!cgst_sgst[tax_value]) {
          cgst_sgst[tax_value] = 0;
        }
        cgst_sgst[tax_value] +=
          (Number(item.quantity) *
            (Number(item.unit_cost_price) > 0
              ? Number(item.unit_cost_price)
              : Number(item?.unit_selling_price) +
                (Number(item?.unit_selling_price) * Number(item?.margin)) /
                  100) *
            Number(item?.tax_value)) /
          100;
      }
      console.log("cgst_sgst, igst ", cgst_sgst, igst);
    });
    setIgstTaxes({ ...igst });
    setCgstSgstTaxes({ ...cgst_sgst });
  }, [items]);

  const getDiv = () => {
    const divs: Array<any> = [];
    Object.keys(igstTaxes).forEach((key, index) => {
      divs.push(
        <div
          className="d-flex justify-content-between"
          key={"igstTaxes" + index}
        >
          <div>IGST {key}% </div>
          <div>₹ {formatDecimal(igstTaxes[key])}</div>
        </div>
      );
    });

    Object.keys(cgstSgstTaxes).forEach((key: any, index: number) => {
      divs.push(
        <div key={index + 1 + "cgstSgstTaxes"}>
          <div className="d-flex justify-content-between" key={"CGST" + index}>
            <div>CGST {key / 2}% </div>
            <div>₹ {(Number(cgstSgstTaxes[key]) / 2)?.toFixed(2)}</div>
          </div>
          <div className="d-flex justify-content-between" key={"SGST" + index}>
            <div>SGST {key / 2}% </div>
            <div>₹ {(cgstSgstTaxes[key] / 2)?.toFixed(2)}</div>
          </div>
        </div>
      );
    });

    return divs;
  };
  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="">Taxable Amount</div>
        <div className="">₹ {Number(getTotalTaxableAmount()).toFixed(2)}</div>
      </div>
      {getDiv()}
    </>
  );
};

export default TaxAmount;
