import { Button, Drawer } from "antd";
import BranchGSTCard from "./branch_gst_card";
import BranchGstCreateForm from "./branch_gst_create_form";
import { useState, useEffect } from "react";
import { fetchBranchGst } from "../../../apis/branch.api";
import { usePermissions } from "../../../custom_hooks/permission/permission_hook";

const BranchGST = () => {
  const [gsts1, setGsts1] = useState<Array<any>>([]);
  const [branch_gst_add_form, setBranchGstAddForm] = useState(false);
  const [update, setUpdate] = useState(true);
  const { hasPermission } = usePermissions();

  const letUpdate = () => {
    setUpdate(!update);
  };

  const showBranchGstAddDrawer = () => {
    setBranchGstAddForm(true);
  };

  const onGstAddDrawerClose = () => {
    setBranchGstAddForm(false);
  };

  useEffect(() => {
    fetchBranchGst().then((res) => {
      setGsts1(res);
    });
  }, [branch_gst_add_form, update]);

  return (
    <div className=" p-3 py-2">
      <div className="d-flex justify-content-between align-items-center pb-3">
        <div className="th-font-size-18 th-font-weight-600 primary-color-text align-middle">
          <div> GST</div>
        </div>
        <div>
          <Button
            size="middle"
            className="fw-bold"
            type="primary"
            disabled={!hasPermission("branch_details.create")}
            onClick={() => {
              showBranchGstAddDrawer();
            }}
          >
            Add GST
          </Button>
        </div>
      </div>
      <div className="row gap-3">
        {gsts1?.map((gst, index) => {
          return (
            <>
              <BranchGSTCard key={gst.id} gst={gst} letUpdate={letUpdate} />
            </>
          );
        })}
      </div>
      <Drawer
        title="Add Gst"
        placement="right"
        closable={false}
        onClose={onGstAddDrawerClose}
        open={branch_gst_add_form}
      >
        <BranchGstCreateForm
          closeDrawer={onGstAddDrawerClose}
          letUpdate={letUpdate}
        />
      </Drawer>
    </div>
  );
};

export default BranchGST;
