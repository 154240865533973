import { useState } from "react";

export interface BranchProps {
  id: number;
  branch_name: string;
  address: string;
  branch_head_designation: any;
  branch_head_name: any;
  branch_head_signature: any;
  bank_account_holder: string;
  bank_account_number: string;
  bank_ifsc: string;
  branch_upi: string;
  project_code_initials: string;
  project_code_format: string;
  project_code_seprator: string;
  po_number_initials: string;
  po_number_format: string;
  po_number_seprator: string;
  bid_number_initials: string;
  bid_number_format: string;
  bid_number_seprator: string;
  po_counter: number;
  bid_counter: number;
  estimate_number_initials: string;
  estimate_number_format: string;
  estimate_number_seprator: string;
  estimate_counter: number;
  estimate_trail_zero: number;
  lead_number_initials: string;
  lead_number_format: string;
  lead_number_seprator: string;
  lead_counter: number;
  inventory_number_initials: string;
  inventory_number_format: string;
  inventory_counter: number;
  qr_code: any;
  status: boolean;
  organisation: number;
  currency: number;
}

export interface BranchDetailsProps {
  branch: BranchProps;
}

const BranchDetails = ({ branch }: BranchDetailsProps) => {
  const currentDate = new Date();
  const [initials, setInitials] = useState("YYYY");
  const [poInitials, setPoInitials] = useState("YYYY");

  const initalOptions = [
    {
      label: "YYYY",
      value: currentDate.getFullYear().toString(),
    },
    {
      label: "MM-YYYY",
      value:
        (currentDate.getMonth() + 1 <= 9
          ? "0" + (currentDate.getMonth() + 1)
          : currentDate.getMonth() + 1) +
        "-" +
        currentDate.getFullYear(),
    },
    {
      label: "YYYYYY",
      value:
        currentDate.getFullYear().toString() +
        currentDate.getFullYear().toString().substring(2),
    },
    {
      label: "MM-YY",
      value:
        (currentDate.getMonth() + 1 <= 9
          ? "0" + (currentDate.getMonth() + 1)
          : currentDate.getMonth() + 1) +
        "-" +
        currentDate.getFullYear().toString().substring(2),
    },
    {
      label: "YY",
      value: currentDate.getFullYear().toString().substring(2),
    },
  ];
  const getExampleDateFrom = (format: string) => {
    const date = initalOptions.find((option) => {
      return option.label == format;
    });
    return date?.value;
  };
  return (
    <div className=" p-3 py-2 row mb-2">
      <div className="th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
        Billing Details
      </div>
      <div className="col">
        <div className="row">
          <div className="col-4 th-font-weight-500">Name </div>
          <div className="col-7">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{branch?.branch_name}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4 th-font-weight-500">Address </div>
          <div className="col-7">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{branch?.address}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4 th-font-weight-500">Bank Account Number </div>
          <div className="col-7">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{branch?.bank_account_number}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4 th-font-weight-500">Ifsc code</div>
          <div className="col-7">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{branch?.bank_ifsc}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4 th-font-weight-500">UPI</div>
          <div className="col-7">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{branch?.branch_upi}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4 th-font-weight-500">Project Code Initials</div>
          <div className="col-7">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>
                {branch?.project_code_initials ?? "PRO"}-
                {getExampleDateFrom(initials)}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4 th-font-weight-500">PO Number Initials:</div>
          <div className="col-7">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>
                {branch?.po_number_initials ?? ""}-
                {getExampleDateFrom(poInitials)}
                {"-" + (branch?.po_counter ?? 0) ?? 0}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BranchDetails;
