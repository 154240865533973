import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, FormInstance, Input, Select, Space } from "antd";
import React, { useState } from "react";
import { addClient } from "../../../apis/client.api";
const { Option } = Select;

const AddClientForm = () => {
  const [form] = Form.useForm();
  const [isStudio, setIsStudio] = useState(false);

  const onFinish = (updatedValues: any) => {
    console.log("updatedValues", updatedValues);
    addClient(updatedValues);
  };

  const SubmitButton = ({ form }: { form: FormInstance }) => {
    const [submittable, setSubmittable] = React.useState(false);

    // Watch all values
    const values = Form.useWatch([], form);

    React.useEffect(() => {
      form.validateFields({ validateOnly: true }).then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
    }, [values]);

    return (
      <Button type="primary" htmlType="submit" disabled={!submittable}>
        Submit
      </Button>
    );
  };

  return (
    <Form
      form={form}
      name="validateOnly"
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
    >
      <Form.Item
        name="contact"
        label="Phone"
        rules={[
          {
            required: true,
            pattern: /^[1-9]\d{9}$/gi,
            message: "Enter a valid phone number",
          },
        ]}
      >
        <Input type="number" maxLength={10} />
      </Form.Item>

      <Form.List name="alternate_contact">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                label={index === 0 ? "Alternate phone" : ""}
                required={false}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    {
                      pattern: /^[1-9]\d{9}$/gi,
                      message: "Enter a valid phone number",
                    },
                  ]}
                  noStyle
                >
                  <Input placeholder="Phone" style={{ width: "80%" }} />
                </Form.Item>
                {fields.length > 0 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button mx-2"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: "60%" }}
                icon={<PlusOutlined />}
              >
                Add alternate phone
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Enter a valid salutation",
          },
        ]}
        name="salutation"
        label="Salutation"
      >
        <Select placeholder="Select salutation">
          <Option value="Mr">Mr</Option>
          <Option value="Mrs">Ms</Option>
          <Option value="Mx">Mx</Option>
        </Select>
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Enter a valid name",
          },
          {
            message: "No spaces allowed",
            pattern: /^[a-zA-Z]*$/gi,
          },
        ]}
        name="f_name"
        label="First Name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Enter a valid name",
          },
          {
            message: "No spaces allowed",
            pattern: /^[a-zA-Z]*$/gi,
          },
          // {
          //   pattern: /^[a-zA-Z\s]*$/gi,
          //   message: "Enter a valid name",
          // }
        ]}
        name="l_name"
        label="Last Name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            type: "email",
            message: "Enter a valid email",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.List name="alternate_email">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                label={index === 0 ? "Alternate email" : ""}
                required={false}
                key={field.key}
                rules={[
                  {
                    type: "email",
                    message: "Enter a valid email",
                  },
                ]}
              >
                <Form.Item
                  {...field}
                  validateTrigger={["onChange", "onBlur"]}
                  noStyle
                >
                  <Input placeholder="Email" style={{ width: "80%" }} />
                </Form.Item>
                {fields.length > 0 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button mx-2"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: "60%" }}
                icon={<PlusOutlined />}
              >
                Add alternate email
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item
        label="User Type"
        name={"roles"}
        rules={[
          {
            required: true,
            message: "Enter a valid user type",
          },
        ]}
      >
        <Select
          placeholder="Select user type"
          onChange={(val) => {
            if (val === 8) {
              setIsStudio(true);
            } else {
              setIsStudio(false);
            }
          }}
        >
          <Option value={10}>Public</Option>
          <Option value={8}>Studio</Option>
        </Select>
      </Form.Item>
      {
        <>
          <Form.Item
            label="GST Number"
            name="gst_number"
            rules={[
              {
                required: isStudio,
                message: "Enter a valid gst number",
              },
              {
                pattern:
                  /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/g,
                message: "Enter a valid GST Number",
              },
            ]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item label="COA Reg. Number" name="coa_reg_no">
            <Input />
          </Form.Item> */}
          <Form.Item label="Company name" name="company_name">
            <Input required={isStudio} />
          </Form.Item>
          <Form.Item
            required={isStudio}
            label="Whatsapp group link"
            name="whatsapp_group_link"
            rules={[
              {
                type: "url",
                message: "Enter a valid url",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </>
      }

      <Form.Item
        label="Source"
        rules={[
          {
            required: true,
            message: "Enter user source",
          },
        ]}
        name={"channel"}
      >
        <Select placeholder="Select user source">
          <Option value="whatsapp">Whatsapp/DMs</Option>
          <Option value="call">Call</Option>
          <Option value="store_walk_in">Store Walk In</Option>
          <Option value="email">Email</Option>
          <Option value="website_order">Website Orders</Option>
        </Select>
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Enter user source",
          },
        ]}
        label="Medium"
        name={"medium"}
      >
        <Select placeholder="Select user medium">
          <Option value="Fbig">Fbig</Option>
          <Option value="SEO">SEO</Option>
          <Option value="SEM">SEM</Option>
          <Option value="SMM">SMM</Option>
          <Option value="Referral">Referral</Option>
          <Option value="Offline">Offline</Option>
          <Option value="Newsletter">Email Marketing/Newsletter</Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Space>
          <SubmitButton form={form} />
          <Button htmlType="reset">Reset</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default AddClientForm;
