import { Button, DatePicker, Form } from "antd";
import { range } from "../add_client_booking_form";

export interface BookingVisitDatePickerProps {
  type: "initiated" | "visited" | null;
  onFinish: (values: any) => void;
}

export function BookingVisitDatePicker({ type, onFinish }: BookingVisitDatePickerProps) {
  if (!type) return null;

  const onSubmit = (values: any) => {
    onFinish(values);
  };

  return (
    <section>
      <Form layout="vertical" onFinish={onSubmit}>
        <Form.Item
          label={type === "initiated" ? "Initiated Time" : "Visited Time"}
          name={type === "initiated" ? "visit_initiated_date" : "visit_completed_date"}
          required
        >
          <DatePicker
            showSecond={false}
            showTime={{
              format: "HH:mm",
              hideDisabledOptions: true,
            }}
            disabledTime={(current: any) => {
              return {
                disabledHours: () => range(0, 10).concat(range(21, 24)),
              };
            }}
            format="YYYY-MM-DD HH:mm:ss"
            placeholder="Select Time"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </section>
  );
}
