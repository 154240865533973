import { Drawer, Select } from "antd";
import { useEffect, useRef, useState } from "react";
import { fetchBranchMembers } from "../../../../apis/branch.api";
import { fetchOpenSearchProducts } from "../../../../apis/product.api";
import EditClientForm from "../../../client/edit_client_form";
const { Option } = Select;

export interface BankDetails {
  bank_account_number: string;
  bank_ifsc: string;
  branch_upi: string;
}
const pricing_options = [
  { id: "1", value: "public", label: "Public" },
  {
    id: "2",
    value: "studio",
    label: "Studio",
  },
];

export interface PFIEditOrderDetailsProps {
  client_data: any;
  setData: Function;
  setClientPricing: Function;
  setClientStudioDrawerOpen: Function;
  client_studio_drawer_open: boolean;
  branch_data: any;
  data: any;
  fetchBasicDetails: () => unknown;
}

const PFIEditOrderDetails = ({
  client_data,
  setData,
  setClientPricing,
  branch_data,
  data,
  setClientStudioDrawerOpen,
  client_studio_drawer_open,
  fetchBasicDetails,
}: PFIEditOrderDetailsProps) => {
  // const [PIStatus, setPIStatus] = useState();
  const [members, setMembers] = useState<Array<any>>([]);
  const [pricing, setPricing] = useState("public");
  const [variant_handles, setVariantHandles] = useState<Array<any>>([]);
  const [openClientDrawer, setOpenClientDrawer] = useState(false);
  const isFirstRender = useRef(true);

  // useEffect(() => {
  //   if (data?.estimate_status !== PIStatus) {
  //     setData("estimate_status", PIStatus);
  //   }
  // }, [PIStatus]);

  useEffect(() => {
    fetchBranchMembers().then((res) => {
      const list: Array<any> = [];
      res?.map((member: any) => {
        list.push(member?.user);
      });
      setMembers(list);
    });
  }, []);

  useEffect(() => {
    let handles: Array<any> = [];
    data?.estimate_items?.map((item: any) => {
      if (item?.variant_handle) {
        handles.push(item.variant_handle);
      }
    });
    setVariantHandles(handles);
  }, [data]);

  useEffect(() => {
    if (client_data?.roles == 8) {
      setClientPricing("studio");
      setPricing("studio");
      // changePricesOnPricingChange("studio");
    } else {
      setClientPricing("public");
      setPricing("public");
      // changePricesOnPricingChange("public");
    }
  }, [client_data]);

  const changePricesOnPricingChange = (pricing_of_products: string) => {
    fetchOpenSearchProducts(
      "",
      {
        variant_handle: variant_handles,
      },
      0,
      variant_handles?.length,
      "relevance",
      pricing_of_products,
      true
    ).then((res) => {
      let estimate_items = [...data?.estimate_items];
      res.product?.map((item: any) => {
        let index_of_item = estimate_items.findIndex(
          (e) => e.variant_handle == item?.variant_handle
        );
        var gross_margin = 0;
        var margin = 0;
        if (
          item.md_price_gst_public &&
          item.md_price_gst_public > 0 &&
          pricing_of_products == "public"
        ) {
          gross_margin =
            ((parseFloat(item.md_price_gst_public ?? 0) -
              parseFloat(item.md_price_gst_msp ?? 0)) /
              parseFloat(item.md_price_gst_public ?? 1)) *
            100;
          margin = gross_margin / (1 - gross_margin / 100);
        }
        if (
          (item.md_price_gst_studio &&
            item.md_price_gst_studio > 0 &&
            pricing_of_products == "studio") ||
          !item.md_price_gst_public
        ) {
          gross_margin =
            ((parseFloat(item.md_price_gst_studio ?? 0) -
              parseFloat(item.md_price_gst_msp ?? 0)) /
              parseFloat(item.md_price_gst_studio ?? 1)) *
            100;
          margin = gross_margin / (1 - gross_margin / 100);
        }
        estimate_items[index_of_item] = {
          ...estimate_items[index_of_item],
          margin: margin,
          gross_margin: gross_margin,
        };
      });
      console.log(
        "estimate_items pricing",
        pricing_of_products,
        estimate_items
      );
      setData("estimate_items", estimate_items);
    });
  };

  return (
    <div className="p-3 py-2 row mb-2">
      <div className="th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
        Order Details
      </div>
      <div className="col">
        <div className="row">
          <div className="col-4 th-font-weight-500">Created By</div>
          <div className="col-8 pb-1">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{data?.created_by}</div>
            </div>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-4 th-font-weight-500">Client </div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{client_data?.f_name}</div>
            </div>
          </div>
        </div>
        {/* <div className="row py-2">
          <div className="col-4 th-font-weight-500">Status</div>
          <div className="col-8">
            <div className="d-flex row">
              <div className="col-11">
                <Select
                  className="w-100"
                  key={"estimate_status"}
                  value={data?.estimate_status}
                  onChange={(e) => {
                    setPIStatus(e);
                  }}
                >
                  {statusOptions?.map((each, index) => (
                    <Option value={each?.value} key={each?.id}>
                      {each?.label}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row py-2">
          <div className="col-4 th-font-weight-500">Assign to</div>
          <div className="col-8">
            <div className="d-flex row">
              <div className="col-11">
                <Select
                  className="w-100"
                  key={"assin_to"}
                  value={data?.assigned_to}
                  onChange={(e) => {
                    setData("assigned_to", e);
                  }}
                >
                  {members?.map((each, index) => (
                    <Option value={each?.id} key={each?.id}>
                      {each?.f_name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-4 th-font-weight-500">User type</div>
          <div className="col-8">
            <div className="d-flex row">
              <div className="col-11">
                <Select
                  className="w-100"
                  key={"pricing"}
                  value={pricing}
                  onChange={(e) => {
                    console.log("pricing", e);
                    if (e == "studio") {
                      if (
                        !client_data?.gst_number ||
                        !client_data?.company_name ||
                        !client_data?.whatsapp_group_link
                      ) {
                        setOpenClientDrawer(true);
                      } else {
                        setClientPricing(e);
                        setPricing(e);
                        changePricesOnPricingChange(e);
                      }
                    } else {
                      setClientPricing(e);
                      setPricing(e);
                      changePricesOnPricingChange(e);
                    }
                  }}
                >
                  {pricing_options?.map((each, index) => (
                    <Option value={each?.value} key={each?.value}>
                      {each?.label}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row py-2">
          <div className="col-4 th-font-weight-500">Bank Details</div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div className="">
                <div>Account Number ({branch_data?.bank_account_number})</div>
                <div>IFSC Code ({branch_data?.bank_ifsc})</div>
                <div>UPI ({branch_data?.branch_upi})</div>
              </div>
            </div>
          </div>
        </div> */}
        <Drawer
          open={openClientDrawer}
          title="Edit client details"
          onClose={() => {
            setOpenClientDrawer(false);
            setClientStudioDrawerOpen(false);
          }}
        >
          <EditClientForm
            client={
              client_studio_drawer_open
                ? {
                    ...client_data,
                    role: 8,
                  }
                : {
                    ...client_data,
                  }
            }
            fetchBasicDetails={fetchBasicDetails}
            onClientEditDrawerClose={() => {
              setOpenClientDrawer(false);
            }}
          />
        </Drawer>
      </div>
    </div>
  );
};

export default PFIEditOrderDetails;
