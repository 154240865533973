import { Button, DatePicker, Input, message } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import ReactStars from "react-rating-star-with-type";
import {
  createEstimateFeedback,
  EstimateFeedbackCreate,
  EstimateFeedbackRead,
  getEstimateFeedback,
  updateEstimateFeedback,
} from "../../../apis/pro_forma_invoice.api";

export interface OrderFeedbackProps {
  estimateId: string;
  onClose: () => void;
  shown?: boolean;
}

export function OrderFeedback({
  estimateId,
  onClose,
  shown,
}: OrderFeedbackProps) {
  const [estimateFeedback, setEstimateFeedback] = useState<
    EstimateFeedbackRead[]
  >([]);

  const [currentFeedback, setCurrentFeedback] =
    useState<EstimateFeedbackCreate>({
      estimate_id: estimateId,
      remarks: "",
      delivery_rating: 0,
      in_store_exp_rating: 0,
      sales_interaction_rating: 0,
      photoshoot_date: null,
    });

  useEffect(() => {
    setEstimateFeedback([]);
    setCurrentFeedback({
      estimate_id: estimateId,
      remarks: "",
      delivery_rating: 0,
      in_store_exp_rating: 0,
      sales_interaction_rating: 0,
      photoshoot_date: null,
    });
    getEstimateFeedback(estimateId)
      .then((response) => {
        setEstimateFeedback(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [estimateId]);

  useEffect(() => {
    if (estimateFeedback.length > 0) {
      setCurrentFeedback({
        estimate_id: estimateFeedback[0]?.estimate?.id,
        remarks: estimateFeedback[0]?.remarks,
        delivery_rating: estimateFeedback[0]?.delivery_rating,
        in_store_exp_rating: estimateFeedback[0]?.in_store_exp_rating,
        sales_interaction_rating: estimateFeedback[0]?.sales_interaction_rating,
        photoshoot_date: estimateFeedback[0].photoshoot_date,
      });
    }
  }, [estimateFeedback]);

  const submit = async () => {
    if (estimateFeedback.length > 0) {
      // Update
      const feedbackId = estimateFeedback[0].id;
      await updateEstimateFeedback(currentFeedback, feedbackId);
      message.success("Feedback updated successfully");
      onClose();
    } else {
      // Create
      await createEstimateFeedback(currentFeedback);
      message.success("Feedback submitted successfully");
      onClose();
    }
  };

  return (
    <section>
      <div className="text-muted mb-2">Delivery Rating</div>
      <ReactStars
        value={currentFeedback.delivery_rating}
        onChange={(value: number) =>
          setCurrentFeedback({ ...currentFeedback, delivery_rating: value })
        }
        isEdit={true}
      />
      <br />
      <div className="text-muted my-2">In Store Experience Rating</div>
      <ReactStars
        value={currentFeedback.in_store_exp_rating}
        onChange={(value: number) =>
          setCurrentFeedback({ ...currentFeedback, in_store_exp_rating: value })
        }
        isEdit={true}
      />
      <br />
      <div className="text-muted my-2">Sales Interaction Rating</div>
      <ReactStars
        value={currentFeedback.sales_interaction_rating}
        onChange={(value: number) =>
          setCurrentFeedback({
            ...currentFeedback,
            sales_interaction_rating: value,
          })
        }
        isEdit={true}
      />
      <div className="text-muted mb-2 mt-4">Feedback Remarks</div>
      <Input.TextArea
        placeholder="Remarks"
        value={currentFeedback.remarks}
        onChange={(e) =>
          setCurrentFeedback({ ...currentFeedback, remarks: e.target.value })
        }
      />
      <br />
      <div className="text-muted mb-2 mt-4">Photoshoot Date</div>
      <DatePicker
        value={
          currentFeedback.photoshoot_date
            ? dayjs(currentFeedback.photoshoot_date)
            : null
        }
        onChange={(date) => {
          if (!date) {
            setCurrentFeedback({
              ...currentFeedback,
              photoshoot_date: null,
            });
            return;
          }

          // Format to save: YYYY-MM-DD
          setCurrentFeedback({
            ...currentFeedback,
            photoshoot_date: date.format("YYYY-MM-DD"),
          });
        }}
      />
      <div className="mt-4">
        <Button type="primary" onClick={submit}>
          Submit
        </Button>
      </div>
      {estimateFeedback.length > 0 && (
        <div className="mt-5 text-muted">
          <small>
            Last Updated on{" "}
            {dayjs(estimateFeedback[0].modified_at).format(
              "DD MMM YYYY HH:mm:ss"
            )}
          </small>
        </div>
      )}
    </section>
  );
}
