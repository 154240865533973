import { Route, Routes } from "react-router-dom";
import WebsiteLayout from "../components/layout";
import { routes } from "./routes";
import ProtectedRoute from "./protected_route";

const LayoutRoutes = () => {
  return (
    <Routes>
      {routes.map(({ path, Component, permission_needed, permission }, i) => (
        <Route element={<WebsiteLayout />} key={i}>
          {permission_needed ? (
            <Route
              path={path}
              element={
                <ProtectedRoute permission={permission}>
                  {Component}
                </ProtectedRoute>
              }
            />
          ) : (
            <Route path={path} element={Component} />
          )}
        </Route>
      ))}
    </Routes>
  );
};

export default LayoutRoutes;
