import { Button, Form, FormInstance, Input, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import {
  editBranchGst,
  fetchUserPermissions,
} from "../../../../apis/branch.api";
const { Option } = Select;

export interface BranchGSTEditProps {
  gst: any;
  closeDrawer: Function;
  letUpdate: Function;
}

const BranchGstEditForm = ({
  gst,
  closeDrawer,
  letUpdate,
}: BranchGSTEditProps) => {
  const [form] = Form.useForm();
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    fetchUserPermissions().then((result: any) => {
      setPermissions(result);
    });
  }, []);
  const onFinish = (updatedValues: any) => {
    editBranchGst(gst?.id, updatedValues).then(() => {
      letUpdate();
      closeDrawer();
    });
  };

  const SubmitButton = ({ form }: { form: FormInstance }) => {
    const [submittable, setSubmittable] = React.useState(false);

    const values = Form.useWatch([], form);

    React.useEffect(() => {
      form.validateFields({ validateOnly: true }).then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
    }, [values]);

    return (
      <Button type="primary" htmlType="submit" disabled={!submittable}>
        Submit
      </Button>
    );
  };

  useEffect(() => {
    Object.keys(gst).map((key: any) => {
      form.setFieldValue(key, gst[key]);
    });
  }, [gst]);

  return (
    <Form
      form={form}
      name="validateOnly"
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
    >
      <Form.Item
        name="label"
        label="Label"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="city"
        label="City"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="pincode"
        label="Pincode"
        rules={[
          {
            required: true,
            pattern: /^[1-9]\d{5}$/gi,
            message: "Enter a valid pincode",
          },
        ]}
      >
        <Input type="number" maxLength={6} />
      </Form.Item>
      <Form.Item
        name="contact"
        label="Phone"
        rules={[
          {
            required: true,
            pattern: /^[1-9]\d{9}$/gi,
            message: "Enter a valid phone number",
          },
        ]}
      >
        <Input type="number" maxLength={10} />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="address"
        label="Address"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="gst_no"
        label="Gst No."
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="pan_no"
        label="Pan Card"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Space>
          <SubmitButton form={form} />
          <Button htmlType="reset">Reset</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default BranchGstEditForm;
