import React from "react";
import styles from "./index.module.scss";
import { Button, Input, Tooltip } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { getImage } from "../../../../helpers/image_helper";

interface RateContractProductCardProps {
  product: any;
  userType: string;
  mdView: boolean;
  onClick: Function;
  addedArr: Array<{
    handle: string;
    quantity: string;
  }>;
  onAdd: (product: any) => unknown;
  onQuantityChange: (handle: string, quantity: string) => unknown;
}

export const RateContractProductCard = ({
  product,
  userType,
  mdView,
  onClick = () => {},
  addedArr = [],
  onAdd = () => {},
  onQuantityChange,
}: RateContractProductCardProps) => {
  const price = React.useMemo(() => {
    if (!mdView) {
      return product?.md_price_gst;
    }
    if (userType === "studio" || mdView) return product?.md_price_gst_studio;
    if (userType === "enterprise") return product?.md_price_gst_msp;
  }, [
    mdView,
    product?.md_price_gst,
    product?.md_price_gst_msp,
    product?.md_price_gst_studio,
    userType,
  ]);

  const landingPrice = React.useMemo(() => {
    if (!mdView) {
      return product?.md_landing_price_gst;
    }
    if (userType === "studio" || mdView)
      return product?.md_landing_price_gst_studio;
    if (userType === "enterprise") return product?.md_landing_price_gst_msp;
  }, [
    mdView,
    product?.md_landing_price_gst,
    product?.md_landing_price_gst_msp,
    product?.md_landing_price_gst_studio,
    userType,
  ]);

  const added = React.useMemo(() => {
    return addedArr.map((i) => i.handle).includes(product?.variant_handle);
  }, [addedArr, product?.variant_handle]);

  return (
    <div
      className={styles.card}
      onClick={(event) => {
        onClick(product);
      }}
    >
      <img
        src={getImage(
          "product-tile",
          "tile-image",
          product.variant_image[0]?.image
            ? product.variant_image[0]?.image
            : product.variant_image[0]?.image_url,
          false
        )}
        alt={product?.product_name}
        className={styles.image}
      />
      <div className={styles.details}>
        <section className={styles.text}>
          <div className={styles.brandName}>{product?.brand?.brand_name}</div>
          <div className={styles.productName}>
            <Tooltip title={product?.product_name}>
              {product.product_name.length > 40
                ? `${product.product_name.slice(
                    0,
                    24
                  )}... ${product.product_name.slice(-10)}`
                : product.product_name}
            </Tooltip>
          </div>
        </section>
      </div>
      {/* {product.md_price_gst &&
        product.mrp &&
        product.md_price_gst?.toString() !== "" && (
          <div className={styles.priceContainer}>
            <div className={styles.price}>
              <div className="primary-color-text th-font-weight-500 ">
                Studio Price
              </div>
              <div>
                ₹{price}
                {""}
                <span className={styles.priceUnitContainer}>
                  {product?.price_unit && "/"}
                  {product?.price_unit ?? ""}
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                {landingPrice && (
                  <div
                    style={{
                      display: "flex",
                      fontSize: "75%",
                      color: "#535353",
                    }}
                  >
                    <span>
                      ₹{product?.md_landing_price_gst_studio}/
                      {product.md_landing_price_unit}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )} */}

      {product.md_price_gst &&
        product.mrp &&
        product.md_price_gst?.toString() !== "" && (
          <div className={styles.priceContainer}>
            <div className={styles.price}>
              <div className="primary-color-text th-font-weight-500">
                Public Price
              </div>
              <div>
                ₹{product?.md_price_gst_public}
                {""}
                <span className={styles.priceUnitContainer}>
                  {product?.price_unit && "/"}
                  {product?.price_unit ?? ""}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                {product?.md_landing_price_gst_public && (
                  <div
                    style={{
                      display: "flex",
                      fontSize: "75%",
                      color: "#535353",
                    }}
                  >
                    <span>
                      ₹{product?.md_landing_price_gst_public}/
                      {product.md_landing_price_unit}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

      <section className={styles.actions}>
        {added && (
          <Input
            className={styles.quantityChangeInput}
            onClick={(ev) => ev.stopPropagation()}
            value={
              addedArr.find((item) => item.handle === product?.variant_handle)
                ?.quantity
            }
            onChange={(ev) =>
              onQuantityChange(product?.variant_handle, ev.target.value)
            }
          />
        )}
        <Button
          shape="default"
          type="primary"
          className={`${styles.button} ${added && styles.added}`}
          icon={added ? <MinusOutlined /> : <PlusOutlined />}
          onClick={(event) => {
            event.stopPropagation();
            onAdd(product);
          }}
          size="middle"
        >
          {added ? "Remove" : "Add"}
        </Button>
      </section>
    </div>
  );
};
