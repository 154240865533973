import { Button, InputNumber, Tooltip } from "antd";
import { useState } from "react";

export function TileView({ coverage_area }: { coverage_area: string }) {
  const [requiredSquareFeet, setRequiredSquareFeet] = useState<number>(0);
  const [additionalBox, setAdditionalBox] = useState<boolean>(false);

  return (
    <section>
      <h6>Tile Calculator</h6>
      <div className="d-flex align-items-center mb-2">
        <div className="flex-grow-1">
          <span className="me-2">One Box:</span>
          <span>{parseFloat(coverage_area)} sq. ft.</span>
        </div>
        <div className="d-flex align-items-center">
          <span className="me-2">Required Sq. Ft.:</span>
          <InputNumber
            placeholder="Required Sq.Ft."
            value={requiredSquareFeet}
            onChange={(n) => n && setRequiredSquareFeet(n)}
          />
        </div>
      </div>
      <div className="d-flex align-items-center">
        Required Box(es):
        <span className="ms-2 me-2">
          {(
            requiredSquareFeet / parseFloat(coverage_area) +
            (additionalBox ? 1 : 0)
          ).toFixed(2)}
        </span>
        <Tooltip
          title={`${additionalBox ? "Remove" : "Add"} an additional box`}
        >
          <Button
            size="small"
            onClick={() => {
              setAdditionalBox(!additionalBox);
            }}
          >
            {additionalBox ? "-" : "+"}
          </Button>
        </Tooltip>
      </div>
    </section>
  );
}
