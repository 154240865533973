import { Button, DatePicker, Form, Input, InputNumber, Typography } from "antd";
import { useCallback, useMemo, useRef, useState } from "react";
import { addInvoiceToPFI } from "../../../apis/pro_forma_invoice.api";

export interface PFIInvoiceFormProps {
  pfi_id: string;
  onClose: () => unknown;
}

export function PFIInvoiceForm({ pfi_id, onClose }: PFIInvoiceFormProps) {
  const [form] = Form.useForm();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [addedFile, setAddedFile] = useState<File | null>(null);

  const onFinish = useCallback(() => {
    const date = form.getFieldValue("invoice_date")?.format("YYYY-MM-DD");
    const { invoice_number, invoice_value } = form.getFieldsValue();
    const invoice_file = addedFile;

    const formData = new FormData();
    formData.append("invoice_number", invoice_number);
    formData.append("invoice_date", date ?? "");
    formData.append("invoice_value", invoice_value);
    if (invoice_file) {
      formData.append("invoice_file", invoice_file);
    }
    formData.append("estimate", pfi_id);

    addInvoiceToPFI(formData)
      .then(() => {
        form.resetFields();
        setAddedFile(null);
      })
      .finally(() => {
        onClose();
      });

    // editPurchaseOrder(poId, formData)
    //   .then(() => {
    //     form.resetFields();
    //     setAddedFile(null);
    //   })
    //   .finally(() => {
    //     onClose();
    //   });
  }, [addedFile, form, onClose, pfi_id]);

  const fileItem = useMemo(() => {
    let innerContent = null;
    if (addedFile) {
      innerContent = (
        <div>
          <Typography.Text strong>
            Selected file: {addedFile.name}
          </Typography.Text>
        </div>
      );
    }

    return (
      <Form.Item name="invoice_file" label="Invoice File">
        <input
          type="file"
          style={{ width: 1, height: 1, overflow: "hidden" }}
          ref={inputFileRef}
          onChange={(e) => setAddedFile(e.target.files?.[0] ?? null)}
        />
        <Button onClick={() => inputFileRef.current?.click()}>
          Add invoice file
        </Button>
        {innerContent}
      </Form.Item>
    );
  }, [addedFile]);

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item name="invoice_number" label="Invoice Number">
        <Input />
      </Form.Item>
      <Form.Item name="invoice_date" label="Invoice Date">
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="invoice_value" label="Invoice Value">
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>
      {fileItem}
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}
