import POBillingDetails1 from "./po_billing_details_modal";
import POOrderDetails1 from "./po_order_details_modal";
import styles from "./index.module.scss";
import { FC } from "react";
import { Client } from "../../../interfaces/client.interface";
import { convertNumberToWords } from "../../../helpers/num_to_word";

type ChildProps = {
  id: string;
  data: any;
  client_data: Client | null;
  branch_data: any;
};

const DetailsPreview: FC<ChildProps> = ({
  id,
  data,
  client_data,
  branch_data,
}) => {
  let totalTaxableAmount = 0;
  let totalTax = { igst: 0, cgst_sgst: 0 };
  let taxValue = { igst: 0, cgst_sgst: 0 };
  let totalPrice = 0;
  const columns2 = [
    {
      title: "#",
      key: "#",
      width: "2rem",
    },
    {
      title: "Name",
      key: "name", //change accordiingly
      width: "9rem",
    },
    {
      title: "Category",
      key: "category",
      width: "6rem",
    },
    {
      title: "Description",
      key: "description", // change accordingly
      width: "8rem",
    },
    {
      title: "Unit",
      key: "unit", // change accordingly
      width: "4rem",
    },

    {
      title: "Make",
      key: "make", // change accordingly
      width: "7rem",
    },
    {
      title: "Qty",
      key: "qty", // change accordingly
      width: "4rem",
    },
    {
      title: "Price",
      key: "discount", // change accordingly
      width: "7rem",
    },
    {
      title: "Tax",
      key: "net amount", // change accordingly
      width: "5rem",
    },

    {
      title: "Total",
      key: "Total amount", // change accordingly
      width: "5.5rem",
    },
  ];

  console.log("this", data);
  console.log("client", client_data);
  console.log(data?.po_items?.length);

  return (
    <div className={styles.table_content}>
      <div className="p-0 m-2 white-color-bg">
        <div className="m-0  white-color-bg overflow-hidden">
          <div className="row d-flex flex-row justify-content-between">
            <div className="col">
              <img
                alt="logo"
                src="/main_logo.svg"
                style={{ height: "80px" }}
                className="my-5 px-2 pb-2"
              />

              <POBillingDetails1
                name={data?.estimate?.billing_address}
                address={data?.estimate?.bill_address_description}
                gst_no="GST: 29AARCM7928H1ZO"
              />
            </div>
            <div className="col th-font-size-16 th-font-weight-700">
              <POOrderDetails1
                order_number={data?.po_number}
                delivery_date={data?.delivery_date}
                created_by={data?.created_by}
                client={client_data?.f_name ?? ""}
                client_contact={client_data?.contact ?? 0}
                order_date={data?.created_at}
                lead_id={data?.estimate?.lead_id}
                quotation_no={data?.estimate?.estimate_no}
                status={data?.estimate?.estimate_status}
                bank_details={{
                  bank_account_number: branch_data?.bank_account_number,
                  bank_ifsc: branch_data?.bank_ifsc,
                  branch_upi: branch_data?.branch_upi,
                }}
              />
              {/* {data?.vendor && ( */}
              <div className="col my-5 th-font-weight-500">
                <div className=" p-3 py-2 row mb-2">
                  <div className="th-font-size-16 th-font-weight-700 pb-1 black-color-text">
                    Vendor details
                  </div>

                  <div className="row">
                    <div>{data?.vendor?.name}</div>
                  </div>

                  <div className="row">
                    <div>GST- {data?.vendor?.gst}</div>
                  </div>
                  <div className="row">
                    <div>PAN-{data?.vendor?.pan_number}</div>
                  </div>
                </div>
              </div>
              {/* )} */}
              {/* {data?.address_description && ( */}
              <div className="col th-font-weight-500">
                <div className=" p-3 py-2 row mb-5">
                  <div className=" th-font-size-16 th-font-weight-700 pb-1 black-color-text">
                    Shipping Address
                  </div>

                  <div className="row">
                    <div>{data?.address_description}</div>
                  </div>
                </div>
              </div>
              {/* )} */}
            </div>
          </div>
          <div className="row ">
            <div
              className={
                styles.t1Table +
                " d-flex flex-row flex-nowrap justify-content-between"
              }
            >
              <div className="d-flex flex-column">
                <div
                  className={
                    styles.t1MainHead +
                    " th-font-weight-700 d-flex flex-row justify-content-start align-items-center"
                  }
                >
                  {columns2.map((value) => {
                    return (
                      <div
                        className={styles.t1Head + " py-2"}
                        key={value.title}
                        id={value.title}
                        style={{ width: value.width }}
                      >
                        {value.title}
                      </div>
                    );
                  })}
                </div>
                {data?.po_items &&
                  data?.po_items.map((value: any, index: any) => {
                    const rowTotal = (
                      (Number(value["quantity"]) *
                        Number(value["unit_po_price"]) *
                        (100 + Number(value["tax_value"]))) /
                      100
                    ).toFixed(2);

                    totalTaxableAmount += Number(
                      (
                        Number(value.quantity) * Number(value.unit_po_price)
                      ).toFixed(2)
                    );

                    totalPrice += Number(rowTotal);
                    if (value?.tax_type === "igst") {
                      taxValue["igst"] = value.tax_value;
                      totalTax["igst"] +=
                        (value.quantity *
                          ((value.unit_cost_price * 100) /
                            (100 + Number(value.margin))) *
                          value?.tax_value) /
                        100;
                    }
                    if (value?.tax_type === "cgst_sgst") {
                      taxValue["cgst_sgst"] = value.tax_value;
                      totalTax["cgst_sgst"] +=
                        (value.quantity *
                          ((value.unit_cost_price * 100) /
                            (100 + Number(value.margin))) *
                          value?.tax_value) /
                        100;
                    }

                    return (
                      <div
                        className={
                          styles.t1MainBody +
                          " d-flex flex-row align-items-center"
                        }
                        key={index}
                      >
                        <div
                          className={styles.t1Body}
                          style={{
                            width: columns2[0].width,
                          }}
                        >
                          {index + 1}
                        </div>
                        <div
                          className={styles.t1Body}
                          style={{
                            width: columns2[1].width,
                            paddingRight: "0.5rem",
                          }}
                        >
                          {value["boq_item"]}
                        </div>
                        <div
                          className={styles.t1Body}
                          style={{ width: columns2[2].width }}
                        >
                          {value["category_name"]}
                          {/* <img
                      alt="logo"
                      src={value["item_image"]}
                      style={{ height: "2rem" }}
                      className="my-1 px-4"
                    /> */}
                        </div>
                        <div
                          className={styles.t1Body}
                          style={{
                            width: columns2[3].width,
                          }}
                        >
                          {value["item_description"]}
                        </div>
                        <div
                          className={styles.t1Body}
                          style={{ width: columns2[4].width }}
                        >
                          {value["unit"]}
                        </div>
                        <div
                          className={styles.t1Body}
                          style={{
                            width: columns2[5].width,
                            paddingLeft: "0.25rem",
                          }}
                        >
                          {value["brand"]}
                        </div>
                        <div
                          className={styles.t1Body}
                          style={{
                            width: columns2[6].width,
                          }}
                        >
                          {value["quantity"]}
                        </div>
                        <div
                          className={styles.t1Body}
                          style={{
                            width: columns2[7].width,
                          }}
                        >
                          {value["unit_po_price"]}
                          {/* {(100 - value["unit_cost_price"] / value["mrp"])
                      .toString()
                      .slice(0, 5)}
                    % */}
                        </div>
                        <div
                          className={styles.t1Body}
                          style={{ width: columns2[8].width }}
                        >
                          {value["tax_value"]}% <br />
                          {value["tax_type"] === "igst"
                            ? " IGST"
                            : " CGST & SGST"}
                        </div>
                        <div
                          className={styles.t1Body}
                          style={{ width: columns2[9].width }}
                        >
                          {rowTotal}
                          {/* {value["quantity"] * value["unit_cost_price"]} */}
                        </div>
                      </div>
                    );
                  })}
                <div></div>
                <div
                  className={
                    styles.t1Subtotal + " d-flex flex-row align-items-center"
                  }
                  style={{ marginLeft: "40rem" }}
                >
                  <div
                    className="my-2 th-font-size-16 th-font-weight-700"
                    style={{ width: "12rem" }}
                  >
                    Total Taxable Amount
                  </div>
                  <div
                    className="my-2 th-font-size-16 th-font-weight-600"
                    style={{ marginLeft: "0" }}
                  >
                    {totalTaxableAmount.toFixed(2)}
                  </div>
                </div>
                <div
                  className={styles.line4}
                  style={{ marginLeft: "40rem" }}
                ></div>

                {totalTax.cgst_sgst > 0 && (
                  <div>
                    {" "}
                    <div
                      className={
                        styles.t1Subtotal +
                        " d-flex flex-row align-items-center"
                      }
                      style={{ marginLeft: "46rem" }}
                    >
                      <div
                        className="my-2 th-font-size-16 th-font-weight-700"
                        style={{ width: "6rem" }}
                      >
                        CGST {taxValue.cgst_sgst / 2}%
                      </div>
                      <div
                        className="my-2 th-font-size-16 th-font-weight-600"
                        style={{ marginLeft: "0" }}
                      >
                        {(totalTax.cgst_sgst / 2).toFixed(2)}
                      </div>
                    </div>
                    <div
                      className={styles.line4}
                      style={{ marginLeft: "40rem" }}
                    ></div>
                    <div
                      className={
                        styles.t1Subtotal +
                        " d-flex flex-row align-items-center"
                      }
                      style={{ marginLeft: "46rem" }}
                    >
                      <div
                        className="my-2 th-font-size-16 th-font-weight-700"
                        style={{ width: "6rem" }}
                      >
                        SGST {taxValue.cgst_sgst / 2}%
                      </div>
                      <div
                        className="my-2 th-font-size-16 th-font-weight-600"
                        style={{ marginLeft: "0" }}
                      >
                        {(totalTax.cgst_sgst / 2).toFixed(2)}
                      </div>
                    </div>
                    <div
                      className={styles.line4}
                      style={{ marginLeft: "40rem" }}
                    ></div>
                  </div>
                )}

                {totalTax.igst > 0 && (
                  <div>
                    {" "}
                    <div
                      className={
                        styles.t1Subtotal +
                        " d-flex flex-row align-items-center"
                      }
                      style={{ marginLeft: "46rem" }}
                    >
                      <div
                        className="my-2 th-font-size-16 th-font-weight-700"
                        style={{ width: "6rem" }}
                      >
                        IGST {taxValue.igst}%
                      </div>
                      <div
                        className="my-2 th-font-size-16 th-font-weight-600"
                        style={{ marginLeft: "0" }}
                      >
                        {(totalTax.cgst_sgst / 2).toFixed(2)}
                      </div>
                    </div>
                    <div
                      className={styles.line4}
                      style={{ marginLeft: "40rem" }}
                    ></div>
                  </div>
                )}

                <div
                  className={
                    styles.t1Subtotal + " d-flex flex-row align-items-center "
                  }
                  style={{ marginLeft: "44rem" }}
                >
                  <div
                    className="my-2 th-font-size-16 th-font-weight-700"
                    style={{ width: "8rem" }}
                  >
                    Total Amount
                  </div>
                  <div
                    className="my-2 th-font-size-16 th-font-weight-600"
                    style={{ marginLeft: "0" }}
                  >
                    {totalPrice}
                  </div>
                </div>
                <div
                  className={styles.line4}
                  style={{ marginLeft: "40rem", marginBottom: "3rem" }}
                ></div>
              </div>
            </div>
          </div>

          <div className={styles.line3}></div>

          <div className="row">
            <div
              className={
                styles.t1Table2 +
                " d-flex flex-row flex-nowrap justify-content-between "
              }
            >
              <div className="d-flex flex-column">
                <div
                  className={
                    styles.t1MainHead2 +
                    "  th-font-weight-700 d-flex flex-row justify-content-start align-items-center"
                  }
                >
                  {/* {columns2.map((value) => {
                    return ( */}
                  <div
                    className={styles.t1Head + " py-2"}
                    style={{ width: "30rem" }}
                  >
                    Term
                  </div>
                  <div
                    className={styles.t1Head + "  py-2 px-1"}
                    style={{ width: "30rem" }}
                  >
                    Percent
                  </div>
                  {/* );
                  })} */}
                </div>

                {data?.po_payment_terms?.map((value: any) => {
                  return (
                    <div
                      key={value.term}
                      className={
                        styles.t1MainBody1 +
                        " d-flex flex-row align-items-center"
                      }
                    >
                      <div
                        className={styles.t1Body1 + " py-2"}
                        style={{
                          width: "30rem",
                        }}
                      >
                        {value.term}
                      </div>
                      <div
                        className={styles.t1Body1 + " py-2 px-1"}
                        style={{
                          width: "27.5rem",
                        }}
                      >
                        {value.percentage}%
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div
            className="row mt-5 mx-1 p-2 th-font-size-16 th-font-weight-700"
            style={{ backgroundColor: "rgb(204, 204, 204)" }}
          >
            Please read the terms and conditions in this document and other
            attachments that may accompany the communication. <br />
            This is a system-generated Purchase order. Signature not necessary
          </div>

          <div
            className="row mt-5 mx-1 p-2 th-font-size-16 th-font-weight-700"
            style={{ backgroundColor: "rgb(204, 204, 204)" }}
          >
            Terms and Conditions
          </div>

          <div
            className="row mx-1"
            style={{
              border: "1px solid rgb(204, 204, 204)",
            }}
          >
            <div className="px-2 mt-2">
              <div>
                <div>{data?.terms_and_condition?.description}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsPreview;
