import { useEffect, useState } from "react";
import { fetchBranchGst } from "../../../../apis/branch.api";
import { Select } from "antd";

export interface PFISelectBillingDetailsProps {
  setData: Function;
  data: any;
}
const { Option } = Select;

const PFISelectBillingDetails = ({
  setData,
  data,
}: PFISelectBillingDetailsProps) => {
  const [gsts, setGsts] = useState<Array<any>>([]);
  const [selected_gst, setSelectedGst] = useState<any>(null);
  useEffect(() => {
    fetchBranchGst().then((res) => {
      setGsts(res);
    });
  }, []);

  useEffect(() => {
    if (selected_gst?.id !== data?.gst && selected_gst) {
      setData(selected_gst?.id);
    }
  }, [selected_gst]);

  useEffect(() => {
    if (selected_gst?.id !== data?.gst) {
      setSelectedGst(
        gsts?.find((gst) => {
          return gst?.id == data?.gst;
        })
      );
    }
  }, [data, gsts]);

  return (
    <div className=" p-3 py-2 row mb-2">
      <div className="th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
        Billing Details
      </div>
      <div className="col">
        <div className="row mb-3">
          <div className="col-4 th-font-weight-500">Address </div>
          <div className="col-7">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{selected_gst?.label}</div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-4 th-font-weight-500">Pincode </div>
          <div className="col-7">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{selected_gst?.pincode}</div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-4 th-font-weight-500">GST No. </div>
          <div className="col-7">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{selected_gst?.gst_no}</div>
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-4 th-font-weight-500">Select Address</div>
          <div className="col-7">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>
                <Select
                  placeholder="Select address"
                  value={data?.gst ?? null}
                  onChange={(e) => {
                    setSelectedGst(gsts.find((item) => item.id == e));
                  }}
                >
                  {gsts?.map((each: any, index) => (
                    <Option key={index} value={each?.id}>
                      {each?.label}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-4 th-font-weight-500">Address. </div>
          <div className="col-7">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{selected_gst?.address}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PFISelectBillingDetails;
