import { FilterOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Drawer,
  Input,
  Modal,
  Pagination,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  editPurchaseOrder,
  getPurchaseOrders,
  pushPOInvoiceToZoho,
  pushPOPurchaseOrderToZoho,
} from "../../../apis/po.api";
import { getFormattedDateAndTime } from "../../../helpers/date_helper";

import { fetchBranchMembers } from "../../../apis/branch.api";
import { getPurchaseOrderDetails } from "../../../apis/po.api";

import { baseURL } from "../../..";
import { fetchBranches } from "../../../apis/branch.api";
import { fetchClientDetails } from "../../../apis/client.api";
import { PowoPdfOpenApi } from "../../../apis/routes";
import { fetchVendors } from "../../../apis/vendor.api";
import DeliveryLocationDrawer from "../../../components/delivery/delivery_location_drawer";
import { PoInvoiceForm } from "../../../components/po/po_details/po_invoice_form";
import DetailsPreview from "../../../components/po/po_details_modal";
import { usePermissions } from "../../../custom_hooks/permission/permission_hook";
import { Client } from "../../../interfaces/client.interface";

const { RangePicker } = DatePicker;

export interface DataType {
  id: number;
  created_by: string;
  type_of_order: string;
  delivery_date: string;
  organisation: number;
  project: Project;
  vendor: Vendor;
  terms_and_condition: TermsAndCondition;
  total_po_raised_amount: number;
  created_at: string;
  modified_at: string;
  po_payment_terms: PoPaymentTerm[];
  documents: any[];
  terms_and_conditions_description: string;
  is_deleted: boolean;
  deleted_by: any;
  gst: Gst;
  address: Address;
  address_description: string;
  migration_id: any;
  is_approved: boolean;
  is_pending: boolean;
  is_decline: boolean;
  approval_flow: any[];
  po_items: PoItem[];
  po_additional_charges: any[];
  po_number: string;
  old_attachment: any;
  lead_id: string;
}

interface FilterType {
  po_delivery_status?: string | undefined;
  created_at__gte?: string | undefined;
  created_at__lte?: string | undefined;
  created_by?: string | undefined;
}

export interface Project {
  id: number;
  project_name: string;
  project_code: any;
  project_address: string;
  pincode: string;
  city: string;
  contact_phone: number;
  contact_email: string;
  contact_person: string;
  start_date: string;
  end_date: string;
  migration_id: any;
  created_at: string;
  project_created_at: string;
  modified_at: string;
  is_archived: boolean;
  is_deleted: boolean;
  organisation: number;
  branch: number;
  currency: number;
  project_created_by: string;
  client: any;
}

export interface Vendor {
  id: number;
  name: string;
  vendor_code: string;
  contact: string;
  vendor_type: any;
  email: string;
  contact_person: string;
  comment: any;
  designation: any;
  pan_number: string;
  gst: string;
  bank_account_holder: string;
  bank_account_number: string;
  bank_ifsc: string;
  billing_address: string;
  pincode: string;
  city: any;
  optional_contact1: any;
  optional_contact_name1: any;
  optional_contact2: any;
  optional_contact_name2: any;
  optional_email1: any;
  optional_email2: any;
  map1: any;
  map2: any;
  map3: any;
  is_verified: boolean;
  status: boolean;
  organisation: number;
  branch: number;
  category: number[];
  brand: any[];
}

export interface TermsAndCondition {
  id: number;
  title: string;
  description: string;
  status: boolean;
  organisation: number;
}

export interface PoPaymentTerm {
  id: number;
  term: string;
  percentage: number;
  deadline: string;
  is_deleted: boolean;
  po: number;
  deleted_by: any;
}

export interface Gst {
  id: number;
  label: string;
  city: string;
  pincode: string;
  address: string;
  gst_no: string;
  contact: string;
  email: string;
  pan_no: string;
  organisation: any;
  branch: number;
}

export interface Address {
  id: number;
  label: string;
  city: string;
  pincode: string;
  contact_person: string;
  contact_phone: number;
  contact_email: string;
  address: string;
  google_map_address: string;
  gst_no: string;
  pan_no: string;
  project: number;
}

export interface PoItem {
  id: number;
  item: Item;
  sub_item: any;
  unit_cost_price: number;
  unit_selling_price: number;
  unit_po_price: number;
  total_price: number;
  quantity: number;
  discount: number;
  tax_value: number;
  tax_type: string;
  is_deleted: boolean;
  po: number;
  deleted_by: any;
}

export interface Item {
  id: number;
  boq_item: string;
  item_description: string;
  item_image: any;
  unit: string;
  quantity: number;
  brand: string;
  identifier: string;
  lead_id: string;
  budget_price: number;
  boq_price: number;
  tax_value: number;
  tax_type: string;
  timeline: any;
  remark: any;
  status: boolean;
  is_deleted: boolean;
  is_selected: boolean;
  project: number;
  category_name: number;
  assigned_to: any;
}

const sortOptions = [
  { id: "1", value: "created_at", label: "Sort by Date Ascending" },
  { id: "2", value: "-created_at", label: "Sort by Date Descending" },
  {
    id: "3",
    value: "total_po_raised_amount",
    label: "Sort by Total Ascending",
  },
  {
    id: "4",
    value: "-total_po_raised_amount",
    label: "Sort by Total Descending",
  },
  // removed delivery_by as it is not in ordering fields
  // { id: "5", value: "delivery_by", label: "Delivery Date Ascending" },
  // { id: "6", value: "-delivery_by", label: "Delivery Date Descending" },
];

const status_type: Array<any> = [
  // {
  //   id: 1,
  //   label: "Order Raised",
  //   value: "order_raised",
  // },
  {
    id: 2,
    label: "Dispatch Pending",
    value: "dispatch_pending",
  },
  {
    id: 5,
    label: "In Store",
    value: "in_store",
  },
  {
    id: 3,
    label: "Dispatched",
    value: "dispatched",
  },
  {
    id: 4,
    label: "Delivered",
    value: "delivered",
  },
];
const { Option } = Select;

const POList = () => {
  const { hasPermission } = usePermissions();

  const [data, setData] = useState<Array<DataType>>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total_pos, setTotalPos] = useState<number>(0);
  const [search, setSearch] = useState("");
  const [po_data, setPOData] = useState<any>(null);
  const [delivery_location_drawer_visible, setDeliveryLocationDrawerVisible] =
    useState(false);
  const [members, setMembers] = useState([]);

  const [vendor_name, setVendor_name] = useState<string>();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [invoice_drawer_visible, setInvoiceDrawerVisible] = useState(false);

  const handleButtonClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const [filter, setFilter] = useState<FilterType>();

  const handleReset = () => {
    setFilter(undefined);
    setVendorId(null);
  };
  const [data1, setData1] = useState<any>({});
  const [branch_data, setBranchData] = useState<any>({});
  const [client_data, setClientData] = useState<Client | null>(null);

  const [basicModal, setBasicModal] = useState(false);
  const [id1, setId1] = useState("");
  const toggleShow = (id: any) => {
    setBasicModal(!basicModal);
  };
  const [vendors, setVendors] = useState<Array<any>>([]);
  const [vendor_id, setVendorId] = useState<any>(null);

  const [activePoId, setActivePoId] = useState<string>("");

  const [sortVal, setSortVal] = useState<string>();
  const [pushToZohoInProgress, setPushToZohoInProgress] = useState(false);

  const columns: ColumnsType<DataType> = [
    {
      title: "Po No.",
      key: "po_number",
      render: (data) => {
        const transport = data?.estimate?.estimate_additional_charges?.find(
          (charge: any) => {
            return (
              charge?.amount > 0 && charge?.charge_type === "Transport Charges"
            );
          }
        );
        const unloading = data?.estimate?.estimate_additional_charges?.find(
          (charge: any) => {
            return (
              charge?.amount > 0 && charge?.charge_type === "Unloading Charges"
            );
          }
        );
        return (
          <div>
            <div style={{ minWidth: 150 }}>{data?.po_number}</div>
            {data?.estimate?.is_outside_delivery && (
              <Tag color="orange" className="mt-1">
                Outstation Delivery
              </Tag>
            )}{" "}
            {data?.estimate?.is_store_pickup && (
              <Tag color="green" className="mt-1">
                Store pickup
              </Tag>
            )}
            {transport && (
              <Tag color="blue" className="mt-1">
                Transport Charges:{" "}
                {(
                  parseFloat(transport?.amount) +
                  (parseFloat(transport?.tax_value ?? 0) / 100) *
                    parseFloat(transport?.amount)
                ).toFixed(1)}
              </Tag>
            )}
            {unloading && (
              <Tag color="blue" className="mt-1">
                Unloading Charges:{" "}
                {(
                  parseFloat(unloading?.amount) +
                  (parseFloat(unloading?.tax_value ?? 0) / 100) *
                    parseFloat(unloading?.amount)
                ).toFixed(1)}
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Lead ID.",
      key: "id",
      render: (data) => (
        <span style={{ minWidth: 150 }}>{data?.estimate?.lead_id}</span>
      ),
    },
    // {
    //   title: "Quote No.",
    //   key: "po_number",
    //   render: (data) => (
    //     <span style={{ minWidth: 150 }}>{data?.estimate?.estimate_no}</span>
    //   ),
    // },
    {
      title: "Vendor Name",
      key: "vendor",
      render: (data) => <div style={{ width: 170 }}>{data?.vendor?.name}</div>,
    },
    {
      title: "Created At",
      key: "created_at",
      render: (data) => (
        <div style={{ width: 110 }}>
          {getFormattedDateAndTime(data?.created_at)}
        </div>
      ),
    },
    {
      title: "Dispatch Time",
      key: "dispatch_time",
      render: (data) => (
        <div style={{ width: 110 }}>
          {getFormattedDateAndTime(data?.dispatch_time)}
        </div>
      ),
    },
    {
      title: "PI Assigned to",
      key: "assigned-to",
      render: (data) => (
        <div style={{ width: 110 }}>{data?.estimate?.assigned_to?.f_name}</div>
      ),
    },
    {
      title: "PI Created by",
      key: "ppi-created-by",
      render: (data) => (
        <div style={{ width: 110 }}>{data?.estimate?.created_by}</div>
      ),
    },
    {
      title: "PO Created by",
      key: "created_by",
      render: (data) => <div style={{ width: 110 }}>{data?.created_by}</div>,
    },
    {
      title: "Delivery Status",
      key: "po_delivery_status",

      render: (data) => (
        <>
          {data?.is_deleted ? (
            <div style={{ width: 130 }}>PO cancelled</div>
          ) : (
            <Select
              style={{ width: 170 }}
              onChange={(e) => {
                if (
                  data?.live_location_link?.length > 0 ||
                  e == "in_store" ||
                  e == "dispatch_pending"
                ) {
                  editPO(data?.id, { po_delivery_status: e });
                } else {
                  setPOData({
                    ...data,
                  });
                  setDeliveryLocationDrawerVisible(true);
                }
              }}
              disabled={!hasPermission("po.update")}
              placeholder="Select status"
              value={data?.po_delivery_status}
            >
              {status_type?.map((obj: any) => (
                <Option key={obj?.id} value={obj?.value}>
                  {obj?.label}
                </Option>
              ))}
            </Select>
          )}
        </>
      ),
    },
    {
      title: "Total",
      key: "total_po_raised_amount",
      render: (data) => (
        <>{parseFloat(data?.total_po_raised_amount ?? 0)?.toFixed(2)}</>
      ),
    },
    {
      title: "No. of invoices",
      key: "no_of_invoices",
      render: (data) => (
        <>{parseFloat(data?.po_invoice_data?.invoice_count ?? 0)?.toFixed(2)}</>
      ),
    },
    {
      title: "Total Invoice Amount",
      key: "total_invoice_amount",
      render: (data) => (
        <>
          {parseFloat(data?.po_invoice_data?.total_invoice_value ?? 0)?.toFixed(
            2
          )}
        </>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (data) => (
        <Space>
          <div
            onClick={() => {
              window.open(PowoPdfOpenApi + data?.id + "?image=true", "_blank");
            }}
          >
            <Tag
              onClick={() => {}}
              color={"blue"}
              style={{ cursor: "pointer" }}
            >
              PDF
            </Tag>
          </div>
          <Tag
            onClick={() => {
              setInvoiceDrawerVisible(true);
              setActivePoId(data?.id);
            }}
            color={"green"}
            style={{ cursor: "pointer" }}
          >
            Add Invoice
          </Tag>

          <Link to={"/po-details/" + data?.id}>
            <Tag
              onClick={() => {}}
              color={"volcano"}
              style={{ cursor: "pointer" }}
            >
              See Details
            </Tag>
          </Link>
          {/* <EyeOutlined
            onClick={() => {
              setId1(data?.id.toString());
              setBasicModal(true);
            }}
          /> */}
          {data?.po_zoho_invoice_count === 0 &&
            hasPermission("invoice.push") && (
              <>
                <Tag
                  onClick={async () => {
                    if (pushToZohoInProgress) {
                      return;
                    }
                    setPushToZohoInProgress(true);
                    await pushPOInvoiceToZoho(data?.id, data?.estimate?.id);
                    getPOs({
                      page: page,
                      ...filter,
                      search,
                      ordering: sortVal,
                      vendor: vendor_id,
                    });
                    setPushToZohoInProgress(false);
                    if (
                      Array.isArray(data?.estimate?.estimate_additional_charges)
                    ) {
                      const negativeAdditionalCharges =
                        data?.estimate?.estimate_additional_charges?.filter(
                          (charge: any) =>
                            parseFloat(String(charge?.amount)) < 0
                        );

                      if (negativeAdditionalCharges.length > 0) {
                        message.info(
                          "Negative additional charges are not pushed to Zoho. Please adjust manually.",
                          10
                        );
                      }
                    }
                  }}
                  color={"blue"}
                  style={{ cursor: "pointer" }}
                >
                  Push Invoice to Zoho
                </Tag>
                <Tag
                  onClick={async () => {
                    if (pushToZohoInProgress) {
                      return;
                    }
                    setPushToZohoInProgress(true);
                    await pushPOInvoiceToZoho(
                      data?.id,
                      data?.estimate?.id,
                      true
                    );
                    getPOs({
                      page: page,
                      ...filter,
                      search,
                      ordering: sortVal,
                      vendor: vendor_id,
                    });
                    setPushToZohoInProgress(false);
                    if (
                      Array.isArray(data?.estimate?.estimate_additional_charges)
                    ) {
                      const negativeAdditionalCharges =
                        data?.estimate?.estimate_additional_charges?.filter(
                          (charge: any) =>
                            parseFloat(String(charge?.amount)) < 0
                        );

                      if (negativeAdditionalCharges.length > 0) {
                        message.info(
                          "Negative additional charges are not pushed to Zoho. Please adjust manually.",
                          10
                        );
                      }
                    }
                  }}
                  color={"blue"}
                  style={{ cursor: "pointer" }}
                >
                  Push Invoice (Stratos) Zoho
                </Tag>
              </>
            )}
          {data?.po_zoho_purchase_order_count === 0 &&
            hasPermission("invoice.push") && (
              <Tag
                onClick={async () => {
                  if (pushToZohoInProgress) {
                    return;
                  }
                  setPushToZohoInProgress(true);
                  await pushPOPurchaseOrderToZoho(data?.id);
                  getPOs({
                    page: page,
                    ...filter,
                    search,
                    ordering: sortVal,
                    vendor: vendor_id,
                  });
                  setPushToZohoInProgress(false);
                }}
                color={"blue"}
                style={{ cursor: "pointer" }}
              >
                Push PO to Zoho
              </Tag>
            )}
        </Space>
      ),
    },
  ];
  const getPOs = (params: any) => {
    setLoading(true);
    setPage(params?.page ?? 1);
    getPurchaseOrders({ page: params?.page ?? 1, ...params }).then((res) => {
      setData(res?.results);
      setTotalPos(res?.count);
      setLoading(false);
    });
  };

  const getVendors = useCallback(() => {
    fetchVendors({ search }).then((res) => {
      setVendors(res);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    fetchBranchMembers().then((res) => {
      setMembers(res);
    });
    getVendors();
  }, []);

  useEffect(() => {
    getPurchaseOrderDetails(id1).then((res) => {
      let dataRes = res;
      console.log("dataRes", dataRes);
      dataRes.po_items = dataRes.po_items?.map((item: any) => {
        return {
          ...item,
          ...item?.estimate_item,
        };
      });
      console.log("dataRes2", dataRes);

      setData1({ ...dataRes });
    });
    fetchBranches().then((res) => {
      setBranchData(res);
    });
  }, [id1]);

  const fetchBasicDetails = (client_id: string) => {
    fetchClientDetails(client_id).then((res) => {
      setClientData(res);
    });
  };

  useEffect(() => {
    if (data1?.estimate?.client) {
      fetchBasicDetails(data1?.estimate?.client);
    }
  }, [data1]);

  useEffect(() => {
    getPOs({
      page: 1,
      ...filter,
      search,
      ordering: sortVal,
      vendor: vendor_id,
    });
  }, [filter, sortVal, vendor_id]);

  // useEffect(() => {
  //   getPOs(page);
  // }, []);

  const editPO = (po_id: any, params: any) => {
    editPurchaseOrder(po_id, params).then((res) => {
      setPage(1);
      getPOs(1);
    });
  };

  const onDeliveryDetailsFinish = (values: any) => {
    setDeliveryLocationDrawerVisible(false);
    editPO(po_data?.id, {
      ...values,
      po_delivery_status: "dispatched",
    });
  };

  const onDownloadClick = async () => {
    const params = {
      // po_delivery_status: filter?.po_delivery_status,
      created_at_start_date: filter?.created_at__gte,
      created_at_end_date: filter?.created_at__lte,
      ignore_dispatch_check: "True",
      ignore_po_delivery_status_check: "True",
      ...(vendor_id ? { vendor: vendor_id } : {}),
    } as Record<string, string>;

    const url = new URL(`${baseURL}apiV1/po-export-csv/`);
    url.search = new URLSearchParams(params).toString();

    message.loading({ content: "Downloading...", key: "download" });
    try {
      // Download from this url
      const response = await fetch(url.toString(), {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      });
      const blob = await response.blob();
      const file = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = file;
      a.download = "POs.csv";

      a.click();

      message.success({ content: "Downloaded", key: "download" });
    } catch (e) {
      message.error({ content: "Error downloading", key: "download" });
    }
  };

  return (
    <div className="mx-3 my-3 px-3 py-2 white-color-bg">
      <div>
        <div className="d-flex justify-content-between">
          <h3>Purchase Orders</h3>

          <div className="d-flex gap-2">
            <div>
              <Select
                placeholder="Sort by"
                className="mt-0"
                style={{ minWidth: "200px" }}
                showArrow={true}
                value={sortVal}
                onChange={setSortVal}
                options={sortOptions.map((item: any) => ({
                  value: item?.value,
                  label: item?.label,
                }))}
              ></Select>
            </div>
            {dropdownVisible && <Button onClick={handleReset}>Reset</Button>}
            <Button onClick={handleButtonClick} className="pt-0">
              <FilterOutlined style={{ fontSize: 16 }} />
            </Button>
            <Input.Search
              type="text"
              style={{ maxWidth: "180px" }}
              placeholder="Search"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onSearch={(value) => {
                setSortVal(undefined);
                setFilter(undefined);
                getPOs({ page: 1, search });
              }}
            />
            <Tooltip
              title={
                !filter?.created_at__gte || !filter?.created_at__lte
                  ? "Please provide range for created at"
                  : "Download CSV file of POs"
              }
            >
              <Button
                type="primary"
                disabled={!filter?.created_at__gte || !filter?.created_at__lte}
                onClick={onDownloadClick}
              >
                Export CSV
              </Button>
            </Tooltip>
          </div>
        </div>
        {dropdownVisible && (
          <div className="row">
            <div className="d-flex flex-row justify-content-between">
              <div className="mr-2 col ">
                <RangePicker
                  style={{ width: "95%" }}
                  onChange={(event) => {
                    if (event) {
                      setFilter({
                        ...filter,
                        created_at__lte: event[1]
                          ?.add(1, "day")
                          ?.format("YYYY-MM-DD"),
                        created_at__gte: event[0]?.format("YYYY-MM-DD"),
                      });
                    } else {
                      setFilter({
                        ...filter,
                        created_at__gte: undefined,
                        created_at__lte: undefined,
                      });
                    }
                  }}
                />
                <div className="th-font-size-12 px-1">Choose Created Date</div>
              </div>
              <div className="mr-2 col">
                <Select
                  placeholder="Select Created by"
                  style={{ width: "95%" }}
                  value={filter?.created_by}
                  onChange={(e) => {
                    setFilter({ ...filter, created_by: e });
                  }}
                  options={members.map((item: any) => ({
                    value: item?.user?.id,
                    label: item?.user?.f_name,
                  }))}
                ></Select>
                <div className="th-font-size-12 px-1">Created by</div>
              </div>
              <div className="col">
                <Select
                  placeholder="Select PO Status"
                  style={{ width: "95%" }}
                  value={filter?.po_delivery_status}
                  onChange={(e) => {
                    setFilter({ ...filter, po_delivery_status: e });
                  }}
                  options={status_type.map((item: any) => ({
                    value: item?.value,
                    label: item?.label,
                  }))}
                ></Select>
                <div className="th-font-size-12 px-1">PO Status</div>
              </div>{" "}
              <div className="col-4">
                <Select
                  placeholder="Select Vendor"
                  style={{ width: "95%" }}
                  value={vendor_id}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLowerCase() ?? "").includes(
                      input?.toLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  onChange={(e) => {
                    setVendorId(e);
                  }}
                  options={vendors.map((item: any) => ({
                    value: item?.id,
                    label: item?.name,
                  }))}
                ></Select>
                <div className="th-font-size-12 px-1">Vendor</div>
              </div>
            </div>
          </div>
        )}
        <div className="my-2">
          <Table
            loading={loading}
            rowKey={"id"}
            dataSource={data}
            columns={columns}
            pagination={false}
            scroll={{
              x: "100%",
            }}
          />
          <Pagination
            current={page}
            total={total_pos ?? 1}
            showSizeChanger={false}
            pageSize={10}
            className="mt-3"
            onChange={(p) => {
              if (vendor_id) {
                getPOs({
                  page: p,
                  ...filter,
                  search,
                  ordering: sortVal,
                  vendor: vendor_id,
                });
              } else {
                getPOs({
                  page: p,
                  ...filter,
                  search,
                  ordering: sortVal,
                });
              }
            }}
          />
          <Modal
            open={basicModal}
            width={1100}
            footer={null}
            style={{ padding: "0" }}
            onOk={() => setBasicModal(false)}
            onCancel={() => setBasicModal(false)}
          >
            <DetailsPreview
              id={id1}
              data={data1}
              client_data={client_data}
              branch_data={branch_data}
            />
          </Modal>
          <DeliveryLocationDrawer
            visible={delivery_location_drawer_visible}
            onFinish={onDeliveryDetailsFinish}
            onClose={() => {
              setDeliveryLocationDrawerVisible(false);
            }}
          />
        </div>
      </div>
      <div>
        <Drawer
          title="Add Invoice"
          placement="right"
          closable={false}
          onClose={() => {
            setInvoiceDrawerVisible(false);
          }}
          open={invoice_drawer_visible}
        >
          <PoInvoiceForm
            poId={activePoId}
            onClose={() => setInvoiceDrawerVisible(false)}
          />
        </Drawer>
      </div>
    </div>
  );
};

export default POList;
