import { getImageUrl } from "../../../helpers/image_helper";
import styles from "./index.module.scss";

export interface CartItemCardProps {
  cart_item: any;
}

const CartItemCard = ({ cart_item }: CartItemCardProps) => {
  return (
    <div
      className="border medium-grey-color-border border-1 p-3 row mb-2"
      key={cart_item?.variant?.id}
    >
      <div className="col-3">
        <img
          width={"100%"}
          src={getImageUrl(
            cart_item?.variant?.variant_image[0]?.image_url,
            "400"
          )}
          alt=""
          className={styles.cart_item_image}
        />
      </div>
      <section className="col-9">
        <div className="th-fontSize-18 th-fontWeight-400">
          {cart_item?.variant?.product_name}
        </div>
        <div className="th-fontSize-14 th-fontWeight-500 mt-1">
          {cart_item?.variant?.product?.brand?.brand_name}
        </div>
        <div className="row my-2">
          <div className="col-4">Quantity:</div>
          <div className="col-8 fw-bold">{cart_item?.quantity}</div>
        </div>
        <div className=" th-fontWeight-600 my-2 row">
          <div className="col-4">Price:</div>
          <div className="col-6 fw-bold">
            ₹ {Number(cart_item?.variant?.md_price_gst)} x{" "}
            {Number(cart_item?.quantity)}
          </div>
        </div>
        <div className=" th-fontWeight-600 my-2 row">
          <div className="col-4">Total Price:</div>
          <div className="col-6 fw-bold">
            ₹{" "}
            {Number(
              cart_item?.variant?.md_price_gst * cart_item?.quantity
            )?.toFixed(2)}
          </div>
        </div>
      </section>
    </div>
  );
};

export default CartItemCard;
