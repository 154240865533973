import { Button, Form, FormInstance, Input, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import {
  addBranchTerms,
  fetchUserPermissions,
} from "../../../../apis/branch.api";
const { Option } = Select;

export interface BranchTermsCreateProps {
  closeDrawer: Function;
  letUpdate: Function;
}
const BranchTermsCreateForm = ({
  closeDrawer,
  letUpdate,
}: BranchTermsCreateProps) => {
  const [form] = Form.useForm();
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    fetchUserPermissions().then((result: any) => {
      setPermissions(result);
    });
  }, []);

  const onFinish = (updatedValues: any) => {
    addBranchTerms(updatedValues).then(() => {
      closeDrawer();
      letUpdate();
    });
  };

  const SubmitButton = ({ form }: { form: FormInstance }) => {
    const [submittable, setSubmittable] = React.useState(false);

    const values = Form.useWatch([], form);

    React.useEffect(() => {
      form.validateFields({ validateOnly: true }).then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
    }, [values]);

    return (
      <Button type="primary" htmlType="submit" disabled={!submittable}>
        Submit
      </Button>
    );
  };
  return (
    <Form
      form={form}
      name="validateOnly"
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
    >
      <Form.Item
        name="title"
        label="Title"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="description"
        label="Description"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Space>
          <SubmitButton form={form} />
          <Button htmlType="reset">Reset</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default BranchTermsCreateForm;
