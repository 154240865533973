import {
  Button,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { editClientAddress } from "../../../apis/client.api";
import {
  getAddressFromPincode,
  getLatLongFromGooGlLink,
} from "../../../apis/misc.api";
interface EditClientAddressFormProps {
  address: any;
  fetchAddresses: Function;
  onClientEditAddressClose: Function;
}
const { TextArea } = Input;
const { Option } = Select;

const EditClientAddressForm = ({
  address,
  fetchAddresses,
  onClientEditAddressClose,
}: EditClientAddressFormProps) => {
  const [form] = Form.useForm();

  const [is_billing_address_true, setBillingAddressTrue] = useState(false);
  const [mapParseLatLongDebounce, setMapParseLatLongDebounce] = useState<any>();
  const [validGoogleMapLink, setValidGoogleMapLink] = useState(false);
  const [latLongLoading, setLatLongLoading] = useState(false);
  const [disabledStateCountryCity, setDisabledStateCountryCity] =
    useState(false);

  const onFinish = (updatedValues: any) => {
    console.log("updatedValues", updatedValues);
    editClientAddress(updatedValues, address?.id).then((res) => {
      fetchAddresses();
      onClientEditAddressClose();
    });
  };
  const SubmitButton = ({ form }: { form: FormInstance }) => {
    const [submittable, setSubmittable] = React.useState(false);

    // Watch all values
    const values = Form.useWatch([], form);

    React.useEffect(() => {
      form.validateFields({ validateOnly: true }).then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
    }, [values]);

    return (
      <Button
        type="primary"
        htmlType="submit"
        disabled={!submittable || !validGoogleMapLink}
        loading={latLongLoading}
      >
        Submit
      </Button>
    );
  };

  useEffect(() => {
    Object.keys(address).map((key: any) => {
      form.setFieldValue(key, address[key]);
    });

    if (
      address?.lat &&
      address?.long &&
      String(address?.lat)?.length !== 0 &&
      String(address?.long)?.length !== 0
    ) {
      setValidGoogleMapLink(true);
    }
  }, [address]);

  const setAddressFromPincode = (pincode: any) => {
    if (pincode?.length == 6) {
      getAddressFromPincode(pincode).then((res) => {
        if (res) {
          // Get currentFieldValues
          const currentFieldValues = form.getFieldsValue();
          setDisabledStateCountryCity(true);
          setTimeout(() => {
            // Reapply the field values
            form.setFieldsValue(currentFieldValues);

            form.setFieldValue("name", res?.name);
            form.setFieldValue("state", res?.state);
            form.setFieldValue("country", res?.country);
            form.setFieldValue("city", res?.city);
          }, 500);
        } else {
          message.error("Pincode doesn't exist");
        }
      });
    }
  };
  const gstChange = (e: any) => {
    let gst = e?.target?.value;
    if (gst?.length == 15) {
      form.setFieldValue("pan_no", gst?.slice(2, 12));
    }
  };
  return (
    <div>
      <Form
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
      >
        <Form.Item label="" name="is_billing" valuePropName="checked">
          <Checkbox
            onChange={() => {
              setBillingAddressTrue(true);
            }}
          >
            Billing address
          </Checkbox>
        </Form.Item>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
          label="Address Label"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="contact_person_name"
          rules={[
            {
              required: true,
            },
          ]}
          label="Contact person name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="contact_person_contact"
          rules={[
            {
              required: true,
              pattern: /^[1-9]\d{9}$/gi,
              message: "Enter a valid Phone",
            },
          ]}
          label="Contact person phone"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="gst_no"
          rules={[
            {
              pattern: /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/g,
              message: "Enter a valid GST Number",
            },
          ]}
          label="GST"
        >
          <Input onChange={gstChange} />
        </Form.Item>
        <Form.Item
          name="pan_no"
          label="PAN No."
          rules={[
            {
              pattern: /[A-Z]{5}\d{4}[A-Z]{1}/g,
              message: "Enter a valid PAN Number",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="flat_no"
          rules={[
            {
              required: true,
            },
          ]}
          label="Flat No / House No / Plot No"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="address"
          rules={[
            {
              required: true,
            },
          ]}
          label="Address"
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="pincode"
          rules={[
            {
              required: true,
              pattern: /^[1-9]\d{5}$/gi,
              message: "Enter a valid pincode",
            },
          ]}
          label="Pincode"
        >
          <Input
            onChange={(e) => {
              setAddressFromPincode(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item
          name="city"
          rules={[
            {
              required: true,
            },
          ]}
          label="City"
        >
          <Input disabled={disabledStateCountryCity} />
        </Form.Item>
        <Form.Item
          name="state"
          rules={[
            {
              required: true,
            },
          ]}
          label="State"
        >
          <Input disabled={disabledStateCountryCity} />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
            },
            {
              type: "url",
              message: "Enter a valid url",
            },
          ]}
          name="google_map_address"
          label="Google map location"
        >
          <Input
            onChange={(e) => {
              clearTimeout(mapParseLatLongDebounce);
              setMapParseLatLongDebounce(
                setTimeout(async () => {
                  setLatLongLoading(true);
                  const response = await getLatLongFromGooGlLink(
                    e.target.value
                  );
                  setLatLongLoading(false);
                  if (!response) {
                    message.error("Enter a valid google map link");
                    setValidGoogleMapLink(false);
                    return;
                  }

                  form.setFieldValue("lat", response?.lat);
                  form.setFieldValue("long", response?.long);
                  setValidGoogleMapLink(true);
                }, 500)
              );
            }}
          />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="lat"
              rules={[
                {
                  required: true,
                },
              ]}
              label="Latitude"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="long"
              rules={[
                {
                  required: true,
                },
              ]}
              label="Longitude"
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          label="Address type"
          name={"address_type"}
        >
          <Select placeholder="Select address type" defaultValue={"home"}>
            <Option value="home">Home</Option>
            <Option value="office">Office</Option>
            <Option value="construction-site">Construction Site</Option>
            <Option value="other">Other</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Space>
            <SubmitButton form={form} />
            {/* <Button htmlType="reset">Reset</Button> */}
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditClientAddressForm;
