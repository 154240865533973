import {
  Col,
  DatePicker,
  Input,
  Pagination,
  Progress,
  Row,
  Statistic,
} from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import {
  fetchFinanceReport,
  fetchFinanceReportMetrics,
} from "../../apis/misc.api";
import { IProcurementItem } from "../../apis/procurement.api";

export default function FinanceReport() {
  const [data, setData] = useState<Array<any>>([]);
  const [graph_data, setGraphData] = useState<any>({
    total_estimate_invoice_value: 0,
    total_estimate_amounts: 0,
    total_po_value: 0,
    total_po_invoice_value: 0,
  });
  const [page, setPage] = useState(0);
  const [total_entries, setTotalEntries] = useState<number>(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [searchLeadId, setSearchLeadId] = useState("");

  const columns: ColumnsType<IProcurementItem> = [
    {
      key: "lead_id",
      dataIndex: "lead_id",
      title: "Lead ID",
      render: (data) => <div>{data}</div>,
    },
    {
      title: "Total Order Amount",
      key: "total_estimate_amount",
      render: (data) => <div>{data?.total_estimate_amount}</div>,
    },
    {
      key: "total_estimate_invoice_value",
      dataIndex: "total_estimate_invoice_value",
      title: "Invoiced Amount",
      render: (data) => <div>{data ?? 0}</div>,
    },
    {
      key: "total_po_value",
      dataIndex: "total_po_value",
      title: "PO Amount",
      render: (data) => <div>{data ?? 0}</div>,
    },
    {
      key: "total_po_invoice_value",
      dataIndex: "total_po_invoice_value",
      title: "Invoiced PO Amount",
      render: (data) => <div>{data ?? 0}</div>,
    },
  ];

  const getFinanceMetric = (params: any) => {
    fetchFinanceReportMetrics({ ...params }).then((res) => {
      setGraphData(res);
    });
  };

  const getReport = (params: any) => {
    console.log("getReport", params);
    setLoading(true);
    setPage(params?.page ?? 0);
    fetchFinanceReport({
      page: params?.page ?? 0,
      ...params,
    }).then((res) => {
      setData(res?.data);
      setTotalEntries(res?.count);
      setLoading(false);
    });
    getFinanceMetric({});
  };

  useEffect(() => {
    getReport({});
  }, []);

  return (
    <div className="mx-3 my-3 px-3 py-2 white-color-bg">
      {" "}
      <div className="d-flex justify-content-between">
        <h3>Finance Report</h3>

        <div className="d-flex gap-4 py-2">
          <div className="d-flex gap-4">
            {" "}
            <div>Start Date</div>
            <DatePicker
              onChange={(date, dateString) => {
                console.log("dateString", dateString);
                getFinanceMetric({ created_at__gte: dateString });
              }}
            />
          </div>
          <div className="d-flex gap-4">
            <div>End Date</div>
            <DatePicker
              onChange={(date, dateString) => {
                console.log("dateString", dateString);
                getFinanceMetric({ created_at__lte: dateString });
              }}
            />
          </div>
        </div>
      </div>
      <div className="py-2">
        <Row>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={12}>
                <Statistic
                  title="Total PI Invoiced Amount"
                  value={graph_data?.total_estimate_invoice_value}
                  precision={2}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Total PI Amount"
                  value={graph_data?.total_estimate_amounts}
                  precision={2}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={12}>
                <Statistic
                  title="Total PO Invoiced Amount"
                  value={graph_data?.total_po_invoice_value}
                  precision={2}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Total PO Amount"
                  value={graph_data?.total_po_value}
                  precision={2}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="d-flex gap-2 py-2 justify-content-end">
        <Input.Search
          type="text"
          style={{ maxWidth: 180 }}
          placeholder="Search Lead ID"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          onSearch={(value) => {
            getReport({ page: 0, lead_id: search });
          }}
        />
      </div>
      <Table
        dataSource={data.filter((item) =>
          item?.lead_id?.includes(searchLeadId)
        )}
        loading={loading}
        columns={columns}
        scroll={{ x: "100%" }}
      />
      <Pagination
        current={page + 1}
        total={total_entries ?? 1}
        showSizeChanger={false}
        pageSize={10}
        className="mt-3"
        onChange={(p) => {
          setPage(p - 1);
          getReport({
            page: p,
            lead_id: search,
          });
        }}
      />
    </div>
  );
}
