import { DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Drawer,
  InputNumber,
  Popconfirm,
  Select,
  Space,
  Table,
  Tag
} from "antd";
import { Header } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  ReactPortal,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { fetchBranches } from "../../../apis/branch.api";
import { fetchClientDetails } from "../../../apis/client.api";
import { editPurchaseOrder, getPurchaseOrders } from "../../../apis/po.api";
import {
  fetchEstimateLogs,
  fetchPIfromId,
  getPFIInvoices,
  refundOrder,
  removeInvoiceFromPI
} from "../../../apis/pro_forma_invoice.api";
import { fetchVendors } from "../../../apis/vendor.api";
import PFIBillingDetails from "../../../components/pro_forma_invoice/pro_forma_invoice_details/pfi_billing_details";
import PFIOrderDetails from "../../../components/pro_forma_invoice/pro_forma_invoice_details/pfi_order_details";
import ProFormaLogCard from "../../../components/pro_forma_invoice/pro_forma_log_card";
import TaxAmount from "../../../components/pro_forma_invoice/tax_amount";
import {
  getFormattedDate,
  getFormattedDateAndTime,
} from "../../../helpers/date_helper";
import { Client } from "../../../interfaces/client.interface";

const status_type: Array<any> = [
  // {
  //   id: 1,
  //   label: "Order Raised",
  //   value: "order_raised",
  // },
  {
    id: 2,
    label: "Dispatch Pending",
    value: "dispatch_pending",
  },
  {
    id: 5,
    label: "In Store",
    value: "in_store",
  },
  {
    id: 3,
    label: "Dispatched",
    value: "dispatched",
  },
  {
    id: 4,
    label: "Delivered",
    value: "delivered",
  },
];
const { Option } = Select;

const OrderRefund = () => {
  const navigate = useNavigate();
  const { pi_id } = useParams();
  const [vendors, setVendors] = useState<Array<any>>([]);
  const [data, setData] = useState<any>({});
  const [other_vendor_select, setOtherVendorSelect] = useState(false);
  const [po_list, setPoList] = useState<Array<any>>([]);
  const [branch_data, setBranchData] = useState<any>({});
  const [po_vendors, setPOVendors] = useState<Array<any>>([]);
  const [client_data, setClientData] = useState<Client | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [invoices, setInvoices] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [see_logs, setSeeLogs] = useState(false);
  const [logs, setLogs] = useState<Array<any>>([]);
  const [refund_items, setRefundItems] = useState<Array<any>>([]);

  const columns = [
    {
      title: "S.no",
      dataIndex: "index",
      render: (value: any, data: any, index: number) => <>{index + 1}</>,
    },
    {
      title: "BOQ Item",
      key: "private_label_product_name",
      width: 200,
      render: (value: any, data: any, index: number) => (
        <>
          <div style={{ minWidth: 180 }}>
            {" "}
            {data?.private_label_product_name ?? data?.boq_item}
          </div>
        </>
      ),
    },
    {
      title: "MRP",
      key: "mrp",
      dataIndex: "mrp",
      width: 100,
    },
    {
      title: "Category",
      key: "category_name",
      dataIndex: "category_name",
      width: 100,
    },
    {
      title: "Quantity",
      key: "quantity",
      dataIndex: "quantity",
      width: 50,
    },
    {
      title: "PO Raised Qty.",
      key: "po_raised",
      dataIndex: "po_raised",
      width: 110,
    },
    {
      title: "Previously Refunded Qty.",
      key: "refund_quantity",
      dataIndex: "refund_quantity",
      width: 160,
    },
    {
      title: "Refund Qty.",
      key: "refund_qty",
      dataIndex: "refund_qty",
      render: (value: any, data: any, index: number) => {
        return (
          <div>
            <InputNumber
              style={{ minWidth: 100 }}
              type="number"
              max={
                parseFloat(data?.quantity) - parseFloat(data?.refund_quantity)
              }
              min={0}
              onChange={(e) => {
                let items = [...refund_items];
                let itemIndex = refund_items.findIndex(
                  (item: any) => item.id == data?.id
                );
                console.log(value, data);
                if (itemIndex > -1) {
                  items[itemIndex].refund_qty = Number(e);
                } else {
                  items.push({
                    id: data?.id,
                    qty: data?.quantity,
                    refund_qty: Number(e),
                  });
                }
                setRefundItems(items);
              }}
            />
            <div className="mt-1">
              Max quantity :{" "}
              {parseFloat(data?.quantity) - parseFloat(data?.refund_quantity)}
            </div>
          </div>
        );
      },
    },
    {
      title: "Unit",
      key: "unit",
      dataIndex: "unit",
      width: 60,
    },
    {
      title: "Brand",
      key: "brand",
      width: 130,
      render: (value: any, data: any, index: number) => (
        <>
          <div style={{ minWidth: 180 }}>
            {" "}
            {data?.private_label_brand_name ?? data?.brand}
          </div>
        </>
      ),
    },
    // {
    //   title: "Landing Price",
    //   key: "unit_selling_price",
    //   width: 100,
    //   render: (data: { [x: string]: string }) =>
    //     parseFloat(data["unit_selling_price"]),
    // },
    {
      title: "Selling Price",
      key: "unit_cost_price",
      width: 100,
      render: (data: { [x: string]: string }) =>
        parseFloat(data["unit_cost_price"]),
    },
    // {
    //   title: "Margin",
    //   key: "margin",
    //   width: 100,
    //   render: (data: { margin: number }) =>
    //     (data.margin / (1 + data.margin / 100)).toFixed(2),
    // },
    {
      title: "Tax",
      key: "tax",
      render: (data: { tax_value: any }) => {
        var tax = Number(data?.tax_value);
        return (
          <>
            <div>{tax}%</div>
          </>
        );
      },
    },
    {
      title: "Tax Type",
      key: "tax_type",
      render: (data: { tax_type: string }) => (
        <>
          {data?.tax_type === "igst" && "IGST"}
          {data?.tax_type === "cgst_sgst" && "CGST & SGST"}
          {data?.tax_type === "none" && "None"}
        </>
      ),
    },
    {
      title: "Lead time",
      key: "lead_time",
      render: (data: any) => {
        return (
          <div style={{ maxWidth: 60 }}>
            {parseFloat(data["lead_time"]) > -1 ? (
              <>{data["lead_time"]} days</>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
    {
      title: "Remarks",
      key: "remarks",
      render: (data: any) => {
        return <div style={{ maxWidth: 200 }}> {data["remarks"]}</div>;
      },
    },
    {
      title: "Amount",
      key: "amount",
      render: (data: { [x: string]: number }) => {
        return data["total_price"];
      },
    },
  ];

  const po_columns: ColumnsType<any> = [
    {
      title: "Po No.",
      key: "po_number",
      dataIndex: "po_number",
      fixed: "left",
    },
    {
      title: "Vendor Name",
      key: "vendor",
      width: 170,
      render: (data: {
        vendor: {
          name:
            | string
            | number
            | boolean
            | ReactElement<any, string | JSXElementConstructor<any>>
            | Iterable<ReactNode>
            | ReactPortal
            | null
            | undefined;
        };
      }) => <span>{data?.vendor?.name}</span>,
    },
    {
      title: "Dispatch Time",
      key: "dispatch_time",
      render: (data: { dispatch_time: any }) => (
        <span>{getFormattedDateAndTime(data?.dispatch_time)}</span>
      ),
    },
    {
      title: "Creation Date",
      key: "created_at",
      render: (data: { created_at: any }) => (
        <span>{getFormattedDate(data?.created_at)}</span>
      ),
    },
    {
      title: "Created By",
      key: "created_by",
      dataIndex: "created_by",
    },
    {
      title: "Status",
      key: "po_delivery_status",
      width: 200,
      render: (data: any) => (
        <>
          {data?.is_deleted ? (
            <>PO Cancelled</>
          ) : (
            <>
              <Select
                className="w-100"
                onChange={(e) => {
                  editPO(data?.id, {
                    po_delivery_status: e,
                  });
                }}
                placeholder="Select tax type"
                value={data?.po_delivery_status}
              >
                {status_type?.map((obj: any) => (
                  <Option key={obj?.id} value={obj?.value}>
                    {obj?.label}
                  </Option>
                ))}
              </Select>
            </>
          )}
        </>
      ),
    },
    {
      title: "Total",
      key: "total_po_raised_amount",
      render: (data: { total_po_raised_amount: any }) => (
        <>{parseFloat(data?.total_po_raised_amount ?? 0)?.toFixed(2)}</>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (data: { id: string }) => (
        <Space>
          <Link to={"/po-details/" + data?.id}>
            <Tag
              onClick={() => {}}
              color={"green"}
              style={{ cursor: "pointer" }}
            >
              See Details
            </Tag>
          </Link>
        </Space>
      ),
    },
  ];

  const getVendors = () => {
    fetchVendors({}).then((res) => {
      setVendors(res);
    });
  };

  const editPO = (po_id: any, params: any) => {
    editPurchaseOrder(po_id, params).then((res) => {
      fetchPOs();
    });
  };

  const getPIData = () => {
    fetchPIfromId(pi_id ?? "").then((res) => {
      setData({ ...res, po_items: res?.estimate_items });
    });
  };

  useEffect(() => {
    getPIData();
    fetchBranches().then((res) => {
      setBranchData(res);
    });
    fetchEstimateLogs(pi_id ?? "").then((res) => {
      setLogs(res);
    });
    fetchPOs();
    getVendors();
    fetchInvoicesOfPI();
  }, []);

  const fetchInvoicesOfPI = () => {
    setLoading(true);
    getPFIInvoices(pi_id).then((res) => {
      setInvoices(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (vendors?.length > 0 && data?.vendors?.length > 0) {
      const result = vendors.filter(({ id }) => data?.vendors.includes(id));
      setPOVendors(result);
    }
  }, [vendors, data]);

  const fetchBasicDetails = (client_id: string) => {
    fetchClientDetails(client_id).then((res) => {
      setClientData(res);
    });
  };

  const fetchPOs = () => {
    getPurchaseOrders({ estimate: pi_id }).then((res) => {
      setPoList(res?.results);
    });
  };

  useEffect(() => {
    if (data?.client) {
      fetchBasicDetails(data?.client);
    }
  }, [data]);

  const confirm = async (invoice_id: any) => {
    if (!loading) {
      setLoading(true);
      await removeInvoiceFromPI(invoice_id);
      setLoading(false);
    }
  };

  const cancel = () => {};

  const refundOrderPartially = () => {
    console.log("refundOrderPartially");
    let items = refund_items.filter((item) => item.refund_qty > 0);
    console.log("refundOrderPartially", refund_items, items);

    setLoading(true);
    refundOrder({
      estimate: pi_id,
      is_full_refund: false,
      estimate_item: items,
    }).then((res) => {
      setLoading(false);

      if (res) {
        navigate("/order-details/" + pi_id);
      }
    });
  };

  return (
    <section className={`lighter-grey-color-bg`}>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
        }}
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-4 align-items-center">
            <div className="primary-color-text th-font-size-20 th-font-weight-700">
              Order Details
            </div>
            <Link
              className="text-decoration-none"
              to={"/client/" + client_data?.id}
              target="_blank"
            >
              <div className=" th-font-weight-600 clover-green-color-text">
                Client :
                <span className=" th-font-weight-400">
                  {" "}
                  {client_data?.f_name}
                </span>
              </div>
            </Link>
            {/* <div className=" th-font-weight-600">
              Quotation No. :
              <span className=" th-font-weight-400"> {data?.estimate_no}</span>
            </div> */}
            <div className=" th-font-weight-600">
              Lead ID :
              <span className=" th-font-weight-400"> {data?.lead_id}</span>
            </div>
          </div>
          <div className="d-flex gap-4 align-items-center">
            <div>
              <Tag color="green">Order Confirmed</Tag>
            </div>
            <Button
              loading={loading}
              onClick={refundOrderPartially}
              type="primary"
            >
              Save
            </Button>
          </div>
        </div>
      </Header>

      <div>
        <div className="m-4 p-4 px-5 white-color-bg">
          <div className="d-flex justify-content-between">
            <img
              alt="logo"
              src="/main_logo.svg"
              style={{ height: "80px" }}
              className="mt-2"
            />
            <Button onClick={() => setSeeLogs(true)}>See logs</Button>
          </div>
          <div className="row gap-2 my-4">
            <div className="col">
              <PFIBillingDetails
                name="MDEPOT RETAIL TECHNOLOGIES PRIVATE LIMITED"
                address="Site No. 15, Bannerghatta Main Road, Sarakki Industrial layout, Jp Nagar phase 3, Bengaluru 560076"
                gst_no="29AARCM7928H1ZO"
              />
            </div>
            <div className="col">
              <PFIOrderDetails
                created_by={data?.created_by}
                estimate_data={data}
                client={client_data?.f_name ?? ""}
                lead_id={data?.lead_id}
                quotation_no={data?.estimate_no}
                status={data?.estimate_status}
                stage={data?.stage}
                bank_details={{
                  bank_account_number: branch_data?.bank_account_number,
                  bank_ifsc: branch_data?.bank_ifsc,
                  branch_upi: branch_data?.branch_upi,
                }}
                setDeliveryAddressDrawerVisible={() => {}}
                getPIData={getPIData}
              />
            </div>
          </div>

          <Divider />

          <div className=" row gap-2 my-4 text-wrap">
            {data?.bill_address && (
              <div className="col">
                <div className=" p-3 py-2 row mb-2">
                  <div className="th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
                    Bill To
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">Name </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.bill_address?.name}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">Address </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.bill_address?.address}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">Pincode </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.bill_address?.pincode}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Contact person{" "}
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.bill_address?.contact_person_name}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Contact number
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.bill_address?.contact_person_contact}</div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 row">
                    <div className="col-4">GST No.</div>
                    <div className="col-8 d-flex">
                      <div>:</div>
                      <div className="w-100 px-2">
                        {data?.bill_address?.gst_no}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {data?.ship_address && (
              <div className="col text-truncate">
                <div className="p-3 py-2 row mb-2">
                  <div className=" th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
                    Ship To
                  </div>
                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Address Label{" "}
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.ship_address?.name}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">Address </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.ship_address?.address}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">Pincode </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.ship_address?.pincode}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Contact person{" "}
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.ship_address?.contact_person_name}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Contact number
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.ship_address?.contact_person_contact}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Selected address
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>
                          {data?.ship_address?.address},{" "}
                          {data?.ship_address?.city}{" "}
                          {data?.ship_address?.pincode}
                        </div>
                      </div>
                    </div>
                  </div>
                  {data?.ship_address?.google_map_address && (
                    <div className="row">
                      <div className="col-4 th-font-weight-500">
                        Google Map Address
                      </div>
                      <div className="col-7">
                        <div className="d-flex">
                          <div className="pe-1">:</div>
                          <div>
                            <div
                              className="text-decoration-underline blue-color-text"
                              onClick={() => {
                                window.open(
                                  data?.ship_address?.google_map_address,
                                  "_blank"
                                );
                              }}
                            >
                              {data?.ship_address?.google_map_address}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div>
            <Table
              columns={columns}
              dataSource={data?.estimate_items}
              scroll={{ x: "max-content" }}
              rowKey={(record) => record.id}
              onRow={(record, index) => ({
                style: {
                  background:
                    Number(record?.quantity) === Number(record?.po_raised)
                      ? "#84FACF"
                      : Number(record?.quantity) > Number(record?.po_raised)
                      ? "#FFFFC0"
                      : "#FFB4A3",
                },
              })}
            />
          </div>
          <div className="row">
            <div className="col">
              <div>
                <h6 className="th-font-weight-600">Payment Terms</h6>
                <div>
                  {data?.estimate_payment_terms?.map(
                    (each: any, ind: number) => (
                      <div key={ind}>
                        {each?.term}:{each?.percentage}%
                      </div>
                    )
                  )}
                </div>
              </div>
              <Divider />
              <div>
                <div>
                  <h6 className="th-font-weight-600">Terms and Conditions</h6>
                  <div>
                    <div className="mb-2">
                      {data?.terms_and_condition?.description}
                    </div>
                  </div>
                </div>
              </div>
              <Divider />

              {/* <div>
                <div className="">
                  <div>
                    <h6 className="th-font-weight-600">Documents</h6>{" "}
                    {"(Total files size should be less than 25MB)"}
                  </div>
                  {data?.documents?.map((document: any, index: any) => {
                    return (
                      <div style={{ padding: 8 }}>
                        <div
                          style={{
                            border: "1px #D1D0CE solid",
                            padding: 4,
                            cursor: "pointer",
                            fontWeight: 500,
                          }}
                          onClick={() => {
                            // openInNewTab(document?.file);
                          }}
                        >
                          {document?.file_name ??
                            document?.file?.replace(
                              "https://mysite-powo-pdfs.s3.ap-south-1.amazonaws.com/media/porject_files/",
                              ""
                            )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div> */}
            </div>
            <div className="col">
              <div>
                <div className="d-flex justify-content-between">
                  {/* <div className="">Taxable Amount</div> */}
                  <div className="">
                    {/* ₹ {Number(getTotalTaxableAmount()).toFixed(2)} */}
                  </div>
                </div>
                <TaxAmount items={data?.estimate_items ?? []} />
              </div>
              <Divider />
              <div>
                {data?.estimate_additional_charges?.map(
                  (each: any, ind: number) => (
                    <>
                      <div className="d-flex justify-content-between" key={ind}>
                        <div className="">
                          {each?.charge_type}
                          {` (@${each?.tax_value + "%"} ${each?.tax_type})`}
                        </div>
                        <div className="">
                          {Number(
                            Number(each?.amount) +
                              Number(each?.amount) *
                                Number(each?.tax_value) *
                                0.01
                          ).toFixed(2)}
                        </div>
                      </div>
                      {each.remark && each.remark != "" && (
                        <div>Remark: {each.remark}</div>
                      )}
                    </>
                  )
                )}
              </div>
              <Divider />
              <div>
                <div className="d-flex justify-content-between">
                  <div className="">
                    <h6>Total Amount</h6>
                  </div>
                  <div className="">
                    {Number(data?.total_estimate_amount)}
                    (Rounded off)
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="th-font-size-18 th-font-weight-500">Invoices</div>
            <div className="d-flex gap-2 py-4 flex-wrap">
              {invoices?.map((invoice) => {
                return (
                  <div
                    onClick={() => {
                      window.open(
                        invoice?.invoice_file ?? invoice?.invoice_file_link,
                        "_blank"
                      );
                    }}
                    className="p-3 px-4 border rounded-3 cursor-pointer"
                  >
                    <div className="th-font-size-16">
                      Invoice number : {invoice?.invoice_number}
                    </div>
                    <div className="th-font-size-16">
                      Invoice amount : {invoice?.invoice_value}
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <Popconfirm
                        title="Delete the task"
                        description="Are you sure to delete this invoice?"
                        onConfirm={() => {
                          confirm(invoice?.id);
                        }}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          className="d-flex align-items-center"
                          danger
                          loading={loading}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                      <Button
                        className=""
                        loading={loading}
                        onClick={() => {
                          window.open(
                            String(
                              invoice?.invoice_file ??
                                invoice?.invoice_file_link
                            ).replaceAll(
                              "mysite-powo-pdfs",
                              "materialdepotimages"
                            ),
                            "_blank"
                          );
                        }}
                      >
                        Open
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="m-4 p-4 px-5 white-color-bg">
        <div className="primary-color-text th-font-size-18 th-font-weight-700">
          POs Raised
        </div>
        <div className="py-3">
          <Table
            dataSource={po_list}
            columns={po_columns}
            rowKey={(record) => record.id}
            scroll={{
              x: "100%",
            }}
          />
        </div>
      </div>

      <Drawer
        title="Basic Drawer"
        placement="right"
        onClose={() => {
          setSeeLogs(false);
        }}
        open={see_logs}
      >
        {logs?.map((log) => {
          return <ProFormaLogCard log={log} />;
        })}
      </Drawer>
    </section>
  );
};

export default OrderRefund;
