import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { getCouponList } from "../../../../apis/pro_forma_invoice.api";
import { getUserCumulativeSum } from "../../../../apis/user.api";

export interface PFICouponSectionProps {
  setCouponCodeAfterApply: Function;
  setCouponCodeId: Function;
  setCouponDiscount: Function;
  coupon_discount: any;
  total_md_price_gst: number;
  coupon_code_after_apply: string;
  user_id: string;
}

const PFICouponSection = ({
  setCouponCodeAfterApply,
  setCouponCodeId,
  coupon_discount,
  total_md_price_gst,
  setCouponDiscount,
  coupon_code_after_apply,
  user_id,
}: PFICouponSectionProps) => {
  const [coupon_code, setCouponCode] = useState<string>("");
  const [coupon_id, setCouponId] = useState<any>(null);
  const [coupon_list, setCouponList] = useState<Array<any>>([]);
  const [cumulative_sum, setUserCumulativeSum] = useState<number>(0);
  const [discount_error, setDiscountError] = useState<boolean>(false);

  useEffect(() => {
    if (user_id) {
      getCouponList().then((res) => {
        setCouponList(res);
      });
      getUserCumulativeSum({
        params: {
          user_id: user_id,
        },
      }).then((res) => {
        setUserCumulativeSum(res?.total_estimate_amount_sum);
      });
    }
  }, [user_id]);

  useEffect(() => {}, [total_md_price_gst]);

  const cumulativeCouponAvailable = (total_required: number) => {
    if (total_required - cumulative_sum - total_md_price_gst > 0) {
      return {
        available: false,
        difference: total_required - cumulative_sum - total_md_price_gst,
      };
    } else {
      return {
        available: true,
      };
    }
  };

  const getRemainingAmountNeededTOApplyOffer = (total_required: number) => {
    return parseInt(
      (total_required - cumulative_sum - total_md_price_gst)?.toString()
    );
  };

  return (
    <div className={"mt-3  p-4 rounded-3 " + styles.card_shadow}>
      <div className="th-fontSize-20 th-fontWeight-500">
        Apply Coupon {total_md_price_gst}
      </div>
      {coupon_code_after_apply?.length > 0 ? (
        <div className="mt-3 border rounded-1 p-2 px-3 medium-green-color-bg green-color-border">
          <div className="row">
            <div className="col">
              <div className="th-fontWeight-600 whatsapp-green-color-text ">
                You Save : Rs.{coupon_discount}
              </div>
              <div className="bright-yellow-brown-color-text">
                Coupon Applied :{" "}
                <span className="th-fontWeight-600">{coupon_code}</span>
              </div>
            </div>
            <div className="col-2 align-items-center justify-content-end d-flex">
              {" "}
              <div
                onClick={() => {
                  setCouponCode("");
                  setCouponId(null);
                  setCouponCodeAfterApply("");
                  setCouponCodeId(null);
                  setCouponDiscount(0);
                }}
                className="btn"
              >
                Remove
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {" "}
          <div className="d-flex mt-2 row gap-0">
            <div className="col">
              <input
                className="p-2 border border-secondary rounded w-100 "
                placeholder="Enter coupon code"
                value={coupon_code}
                onChange={(e) => {
                  setCouponCode(e.target.value?.toUpperCase());
                  setCouponId(coupon_id);
                }}
              />
            </div>
            <div
              className={
                "col-3 btn " +
                (coupon_code_after_apply?.length > 0
                  ? "primary-color-background white-color-text"
                  : " border border-dark")
              }
              onClick={() => {
                if (coupon_code?.length > 0) {
                  setCouponCodeAfterApply(coupon_code);
                  setCouponCodeId(coupon_id);
                }
              }}
            >
              Apply
            </div>
          </div>
          {discount_error && (
            <div className="brick-red-color-text mt-2">Invalid Coupon</div>
          )}
        </>
      )}
      <div className="mt-2 green-color-text">
        User current cumulative amount :{" "}
        <span className="th-fontWeight-600">₹{cumulative_sum}</span>
      </div>

      <div
        className={
          (coupon_code_after_apply?.length > 0 && " opacity-50 pe-none ") +
          " py-3"
        }
      >
        {coupon_list.filter((coupon) => {
          return cumulativeCouponAvailable(coupon?.cumulative_sum_above)
            ?.available;
        })?.length > 0 && (
          <>
            {" "}
            <div className="th-fontSize-18 mb-2 th-fontWeight-500">
              Applicable Coupons
            </div>
            {coupon_list
              .filter((coupon) => {
                return cumulativeCouponAvailable(coupon?.cumulative_sum_above)
                  ?.available;
              })
              .map((coupon, index) => {
                return (
                  <div
                    onClick={() => {
                      setCouponCode(coupon?.coupon_code?.toUpperCase());
                      setCouponId(coupon?.id);
                    }}
                    className="cursor-pointer d-flex"
                  >
                    <div className="form-check">
                      <input
                        className="form-check-input cursor-pointer"
                        type="radio"
                        name="coupon"
                        id={"coupon" + index}
                        checked={coupon_code == coupon?.coupon_code}
                        onChange={(e) => {}}
                      />
                      <label
                        className="form-check-label w-100 cursor-pointer"
                        htmlFor={"coupon" + index}
                      >
                        <div className="th-fontSize-16 th-fontWeight-500">
                          {coupon?.coupon_code}{" "}
                        </div>

                        <div className="th-fontSize-14 th-fontWeight-400">
                          Get{" "}
                          <span
                            className={
                              "th-fontSize-16 th-fontWeight-600 " +
                              styles?.gradient_text
                            }
                          >
                            {coupon?.coupon_value}
                            {coupon?.type == "percentage" && "%"} OFF{" "}
                          </span>
                          on Total Cumulative order value of Rs.{" "}
                          {coupon?.cumulative_sum_above} and above
                        </div>
                        <div
                          className={
                            "th-fontSize-12 th-fontWeight-400 blue-color-text "
                          }
                        >
                          View T & C
                        </div>
                      </label>
                    </div>
                    <div></div>
                  </div>
                );
              })}
          </>
        )}
        <br />
        {coupon_list.filter((coupon) => {
          return !cumulativeCouponAvailable(coupon?.cumulative_sum_above)
            ?.available;
        })?.length > 0 && (
          <>
            {" "}
            <div className="th-fontSize-18 mb-2 th-fontWeight-500">
              Non Applicable Coupons
            </div>
            {coupon_list
              .filter((coupon) => {
                return !cumulativeCouponAvailable(coupon?.cumulative_sum_above)
                  ?.available;
              })
              .map((coupon, index) => {
                return (
                  <div
                    onClick={() => {
                      // setCouponCode(coupon?.coupon_code?.toUpperCase());
                      // window.scrollTo({ top: 250, behavior: "smooth" });
                    }}
                    className="cursor-pointer my-2"
                  >
                    <div className="form-check">
                      <input
                        className="form-check-input cursor-pointer"
                        type="radio"
                        name="coupon"
                        id={"coupon" + index}
                        disabled={true}
                        checked={coupon_code == coupon?.coupon_code}
                        onChange={(e) => {}}
                      />
                      <label
                        className="form-check-label w-100 cursor-pointer"
                        htmlFor={"coupon" + index}
                      >
                        <div className="th-fontSize-16 th-fontWeight-500">
                          {coupon?.coupon_code}{" "}
                        </div>

                        <div className="th-fontSize-14 th-fontWeight-400">
                          Get{" "}
                          <span
                            className={
                              "th-fontSize-16 th-fontWeight-600 " +
                              styles?.gradient_text
                            }
                          >
                            {coupon?.coupon_value}
                            {coupon?.type == "percentage" && "%"} OFF{" "}
                          </span>
                          on Total Cumulative order value of Rs.{" "}
                          {coupon?.cumulative_sum_above} and above
                        </div>
                        <div
                          className={
                            "th-fontSize-12 th-fontWeight-400 blue-color-text "
                          }
                        >
                          View T & C
                        </div>
                      </label>
                    </div>

                    <div className="px-4 th-fontSize-14 bright-yellow-brown-color-text">
                      Shop for ₹
                      {getRemainingAmountNeededTOApplyOffer(
                        coupon?.cumulative_sum_above
                      )}{" "}
                      more to apply offer
                    </div>
                  </div>
                );
              })}
          </>
        )}
      </div>
    </div>
  );
};

export default PFICouponSection;
