import { Button, Table } from "antd";
import { Client } from "../../../../interfaces/client.interface";
import { getFormattedDateAndTime } from "../../../../helpers/date_helper";
import ClientAlternateContactTable from "../../client_alternate_contact_table";
import { useState } from "react";
import { usePermissions } from "../../../../custom_hooks/permission/permission_hook";

export interface BasicClientDetailsProps {
  client_data: Client | null;
  user_id: any;
  showClientEditDrawer: Function;
}

const BasicClientDetails = ({
  client_data,
  showClientEditDrawer,
  user_id,
}: BasicClientDetailsProps) => {
  const { hasPermission } = usePermissions();

  const [
    client_alternate_contact_drawer_open,
    setClientAlternateContactDrawerOpen,
  ] = useState(false);

  const onClientAlternateContactDrawerClose = () => {
    setClientAlternateContactDrawerOpen(false);
  };
  const showClientAlternateContactDrawer = () => {
    setClientAlternateContactDrawerOpen(true);
  };
  return (
    <div className=" p-3 py-2 row mb-2">
      <div className="d-flex justify-content-between align-items-center pb-3">
        <div className="align-middle th-font-size-18 th-font-weight-600  primary-color-text">
          Basic Details
        </div>
        {hasPermission("client.update") && (
          <div className="gap-2 d-flex">
            <Button
              size="middle"
              className="fw-bold"
              type="primary"
              onClick={() => {
                showClientAlternateContactDrawer();
              }}
            >
              Add alternate contact
            </Button>
            <Button
              size="middle"
              className="fw-bold"
              type="primary"
              onClick={() => {
                showClientEditDrawer();
              }}
            >
              Edit details
            </Button>
          </div>
        )}
      </div>
      <div className="col">
        {client_data?.f_name && (
          <div className="row">
            <div className="col-4 th-font-weight-500">Name </div>
            <div className="col-7">
              <div className="d-flex">
                <div className="pe-1">:</div>
                <div>{client_data?.f_name}</div>
              </div>
            </div>
          </div>
        )}
        {client_data?.email && (
          <div className="row">
            <div className="col-4 th-font-weight-500">Email </div>
            <div className="col-7">
              <div className="d-flex">
                <div className="pe-1">:</div>
                <div>{client_data?.email}</div>
              </div>
            </div>
          </div>
        )}
        {client_data?.contact && (
          <div className="row">
            <div className="col-4 th-font-weight-500">Phone </div>
            <div className="col-7">
              <div className="d-flex">
                <div className="pe-1">:</div>
                <div>{client_data?.contact}</div>
              </div>
            </div>
          </div>
        )}
        {client_data?.user_source && (
          <div className="row">
            <div className="col-4 th-font-weight-500">User source </div>
            <div className="col-7">
              <div className="d-flex">
                <div className="pe-1">:</div>
                <div>{client_data?.user_source}</div>
              </div>
            </div>
          </div>
        )}
        {client_data?.city && (
          <div className="row">
            <div className="col-4 th-font-weight-500">City </div>
            <div className="col-7">
              <div className="d-flex">
                <div className="pe-1">:</div>
                <div>{client_data?.city}</div>
              </div>
            </div>
          </div>
        )}
        {client_data?.company_name && (
          <div className="row">
            <div className="col-4 th-font-weight-500">Company Name </div>
            <div className="col-7">
              <div className="d-flex">
                <div className="pe-1">:</div>
                <div>{client_data?.company_name}</div>
              </div>
            </div>
          </div>
        )}
        {client_data?.whatsapp_group_link && (
          <div className="row">
            <div className="col-4 th-font-weight-500">Whatsapp group link </div>
            <div className="col-7">
              <div className="d-flex">
                <div className="pe-1">:</div>
                <div>{client_data?.whatsapp_group_link}</div>
              </div>
            </div>
          </div>
        )}
        {client_data?.gst_number && (
          <div className="row">
            <div className="col-4 th-font-weight-500">GST No. </div>
            <div className="col-7">
              <div className="d-flex">
                <div className="pe-1">:</div>
                <div>{client_data?.gst_number}</div>
              </div>
            </div>
          </div>
        )}
        {client_data?.rupifi_activation_url && (
          <div className="row">
            <div className="col-4 th-font-weight-500">Rupifi link </div>
            <div className="col-7">
              <div className="d-flex">
                <div className="pe-1">:</div>
                <div>{client_data?.rupifi_activation_url}</div>
              </div>
            </div>
          </div>
        )}
        {client_data?.created_at && (
          <div className="row">
            <div className="col-4 th-font-weight-500">Created at </div>
            <div className="col-7">
              <div className="d-flex">
                <div className="pe-1">:</div>
                <div>{getFormattedDateAndTime(client_data?.created_at)}</div>
              </div>
            </div>
          </div>
        )}

        <ClientAlternateContactTable
          user_id={user_id}
          client_alternate_contact_drawer_open={
            client_alternate_contact_drawer_open
          }
          setClientAlternateContactDrawerOpen={
            setClientAlternateContactDrawerOpen
          }
        />
      </div>
    </div>
  );
};

export default BasicClientDetails;
