import { useCallback, useEffect, useState } from "react";
import {
  IDeliveryItem,
  fetchProcurementDeliveryData,
} from "../../apis/procurement.api";
import Table, { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";

export default function ProcurementDelivery() {
  const [data, setData] = useState<any>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const getData = useCallback(async (id?: string) => {
    const response = await fetchProcurementDeliveryData(id);
    setLoading(false);
    if (response !== null) {
      setData(response);
    } else {
      setError(true);
    }
  }, []);

  useEffect(() => {
    getData("221027a3-db00-4498-a6cc-db29a83af73a");
  }, [getData]);

  const columns: ColumnsType<IDeliveryItem> = [
    {
      key: "po_number",
      dataIndex: "po_number",
      title: "PO Number",
      width: 200,
    },
    {
      key: "lead_id",
      dataIndex: "lead_id",
      title: "Lead ID",
      width: 200,
    },
    {
      key: "estimate_no",
      dataIndex: "estimate_no",
      title: "Estimate No.",
      width: 200,
      render: (data, record) => (
        <Link to={`/pro-forma-invoice-details/${record.estimate_id}`}>
          {data}
        </Link>
      ),
    },
    {
      key: "type_of_order",
      dataIndex: "type_of_order",
      title: "Type of Order",
      render: (data) => (data as string).toUpperCase(),
      width: 150,
    },
    {
      key: "delivery_date",
      dataIndex: "delivery_date",
      title: "Delivery Date",
      width: 200,
    },
    {
      key: "total_po_raised_amount",
      dataIndex: "total_po_raised_amount",
      title: "Total PO Raised",
      width: 200,
    },
    {
      key: "address_description",
      dataIndex: "address_description",
      title: "Address",
      width: 400,
    },
  ];

  return (
    <main className="mx-3 my-3 px-3 py-2 white-color-bg">
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        scroll={{ x: "100%" }}
      />
    </main>
  );
}
